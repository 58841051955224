import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'typeFormats'
})
export class TypeFormatsPipe implements PipeTransform {
   
    constructor() { }

    transform(value: string, args: string): any {
        if (!value) return;

        if(args.indexOf('cnpj',0)>=0){
            return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(5, 3)}/${value.substr(8, 4)}-${value.substr(12, 2)}`;
        }

        return value;        
    }
}