import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserModel } from 'src/app/model/userModel';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MyAccount } from 'src/app/model/myaccount';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  saveUser(user: UserModel):Observable<any> {
    return this.http.post<UserModel>(this.urlAdminBe + "/user", user)
    .pipe(map(data => data));
  }

  updateUser(user: UserModel):Observable<any> {
    return this.http.put<UserModel>(this.urlAdminBe + "/user", user)
    .pipe(map(data => data));
  }

  saveStatusUser(id: number, status: boolean):Observable<any> {
    return this.http.put(this.urlAdminBe + "/user/status?id="+id+"&status="+status, null)
    .pipe(map(data => data));
  }

  getAllUsers():Observable<any> {
    return this.http.get<any>(this.urlAdminBe + '/user')
    .pipe(map(data => data));
  }

  getAllUsersByFilters(value: string, field: string):Observable<any> {
    return this.http.get<any>(this.urlAdminBe + '/user?field='+field+'&value='+value)
    .pipe(map(data => data));
  }

  getUserByUsername():Observable<any>{
    return this.http.get<number>(this.urlAdminBe + '/user/username')
    .pipe(map(data => data));
  }

  saveAccountData(account:MyAccount):Observable<any>{
    return this.http.post<MyAccount>(this.urlAdminBe + "/user/saveAccount", account)
    .pipe(map(data => data));
  }

  getLgpdDataUser(email:string):Observable<any>{
    return this.http.get<any>(this.urlAdminBe + "/user/lgpd?email=" + email.trim())
    .pipe(map(data => data));
  }

  removeLgpdDataUser(email:string):Observable<any>{
    return this.http.delete<boolean>(this.urlAdminBe + "/user/lgpd?email=" + email.trim())
    .pipe(map(data => data));
  }
}
