import { Component, OnInit, ViewChild } from '@angular/core';
import { Institution } from 'src/app/model/institution.model';
import { InstitutionService } from 'src/services';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-institution-list',
  templateUrl: './institution-list.component.html',
  styleUrls: ['./institution-list.component.css']
})
export class InstitutionListComponent implements OnInit {

  @ViewChild(Table,{static:false}) dt: Table;

  first: number = 0;
  pageNumber:number = 1;

  institutions: Institution[];
  selectedInstitution: Institution;

  cols: any[];

  constructor(private institutionService:InstitutionService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        if(params['pg']){
          this.pageNumber = params['pg']; 
        }
    });

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'token', header: 'Token' },
      { field: 'name', header: 'Nome' }
    ];

    this.institutions = [{id:0,name:'',tradingName:'',token:'CARREGANDO...', legalIdentifier: '', website: '', version: 0, address: undefined}]
    this.fillInstitutions();
  }

  fillInstitutions(){
    this.institutionService.getAllInstitutions().subscribe(data => {
      this.institutions=data
      
      if(this.pageNumber){
        this.setCurrentPage(this.pageNumber);
      }
    });
  }

  onRowSelect(event) {
    if(event.data.id>0){
      this.router.navigate(['institutionRegister/'+event.data.id, {pg:this.pageNumber}]);
    }
  }

  setCurrentPage(n: number) {
    this.dt.first = ((n - 1) * this.dt.rows);
  }

  paginate(event) {
    let pageIndex = event.first/event.rows + 1;
    this.pageNumber = pageIndex;
  }
}
