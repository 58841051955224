import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../common/locale';
import { AdminBeService } from 'src/services';
import { Role } from 'src/app/model/Role';
import { MonitoringService } from 'src/services/monitoring.service';
import { Table } from 'primeng/table';
import { FilterMap } from 'src/app/model/filterMap';
import { ContractType } from 'src/app/model/contractType';
import { IntegrationStatus } from 'src/app/model/integrationStatus';
import { ValidationResult } from 'src/app/model/validationResult';
import { EnumEx } from 'src/app/model/EnumEx';
import { SelectItem } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.css'],
  encapsulation: ViewEncapsulation.None  
})
export class MonitoringComponent implements OnInit {

  @ViewChild('dataTable', {static:false}) dataTable: Table;
  @ViewChild('dateFilter', undefined) private dateFilter: any;

  PAGE_HEIGHT_SIZE:number = 800;
  DEFAULT_PAGE_SIZE:number = 13;
  LIMITED_PAGE_SIZE:number = 10;
  TEXT_LIMIT:number = 35;

  role: Role;
  credencial: any;

  first: number = 0;
  cols: any[];
  colsAlert :any[];
  integrationLog: Array<any> = [];

  totalRecords = 0;
  page = 1;
  pageSize = this.DEFAULT_PAGE_SIZE;

  fieldSearch: string;
  value: string;

  rangeDates: Date[];
  loading: boolean;

  mapFilters:Array<FilterMap>;

  contracts:SelectItem[]=[];
  integrationsStatus:SelectItem[]=[];
  validationsResults:SelectItem[]=[];

  totals:any[]=[];
  msgIntegrated:string="0";
  msgProcessed:string="0";

  innerWidth: any;

  constructor(private monitoringService:MonitoringService, private route: ActivatedRoute, 
    private adminBeService: AdminBeService, private translate: TranslateService, 
    private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.credencial= this.adminBeService.getUser().credencial; 
    this.mapFilters=[];

    this.contracts = this.getContractTypes();
    this.integrationsStatus = this.getIntegrationStatus();
    this.validationsResults = this.getValidationResult();
    
    let end = new Date();
    let initial = new Date();
    initial.setMonth(initial.getMonth() - 3);
      
    this.rangeDates = [];
      
    this.rangeDates.push(initial);
    this.rangeDates.push(end);

    this.translate.onLangChanged.subscribe(() => {
      this.prepareColumns();
    });
    this.prepareColumns();

    this.route.params.subscribe(
      params => {
        this.role = params['role']; 
        
        this.search();

        if(!this.isCompany()){
          this.getTotalIntegrationLogs();
        }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this.detectScreenSize();
  }

  detectScreenSize() {
      const height = window.innerHeight;
      const width = window.innerWidth;

      this.pageSize=this.DEFAULT_PAGE_SIZE;
      if(height<=this.PAGE_HEIGHT_SIZE){
          this.pageSize=this.LIMITED_PAGE_SIZE;
          this.search();
      }
  }

  prepareColumns() {
    this.cols = [
      { field: 'institution', header: this.translate.instant('Instituição'), width: "15%"},
      { field: 'requestId', header: this.translate.instant('RequestId'), width: "10%" },
      { field: 'contract', header: this.translate.instant('Contrato'), width: "9%" },
      { field: 'status', header: this.translate.instant('Status'), width: "12%" },
      { field: 'validation', header: this.translate.instant('Validação'), width: "9%" },
      { field: 'dtIntegration', header: this.translate.instant('Data de Integração'), width: "10%" }      
    ];

    this.colsAlert = [
      { field: 'validation', header: this.translate.instant('Validação'), width: "10%" },
      { field: 'field', header: this.translate.instant('Campo'), width: "20%" },
      { field: 'message', header: this.translate.instant('Mensagem'), width: "40%" }
    ];
  }

  checkCompanyRole(header){

    if(header.toString()=="contract"){
      if(this.role.toString()===Role.company.toString()){      
        return true;
      }
    }

    return false;
  }

  getIntegrationLogs(){
    this.loadIntegrationLogData(this.page - 1, this.pageSize, this.generateFilters(this.mapFilters));
  }

  loadIntegrationLogData(pageNumber: number, size: number, filters:string){
    this.spinner.show();
    this.monitoringService.getAllIntegrationLog(pageNumber, size, filters).subscribe(integrations=>{
      this.loadData(integrations);      
    })
  }

  getTotalIntegrationLogs(){
    this.loadTotalIntegrationLogData();
  }

  loadTotalIntegrationLogData(){
    this.monitoringService.getTotalsIntegrationLog().subscribe(totals=>{
      this.loadTotalData(totals);
    })
  }

  loadTotalData(data:any){
    this.totals = data;

    this.totals.forEach(item=>{      
      this.msgProcessed = item.PROCESSED;
      this.msgIntegrated = item.RECEIVED;
    })
  }

  loadData(data: any) {
    let integrationLogArray = [];
    data.content.forEach(function(item:any) {

      let integrationLog = {
          id: item.id,
          institution:item.institution.name,
          requestId:item.requestId,
          contract:item.contractType,
          status:item.integrationStatus,
          validation:item.validationResult,
          dtIntegration:item.receivedAt,
          dtStatusUpdate:item.statusUpdateAt,
          alerts:item.alerts,          
          payLoad:item.payLoad
      };

      integrationLogArray.push(integrationLog);
    });
    
    this.spinner.hide();

    this.integrationLog = integrationLogArray;
    this.totalRecords = data['totalElements'];
    this.setCurrentPage(this.page);  
    this.loading = false;
  }

  setCurrentPage(n: number) {
    if(this.dataTable){
      this.dataTable.first = ((n - 1) * this.dataTable.rows);
    }
  }

  paginate(event: any) {
    this.page = event.first/event.rows + 1;;
    this.getIntegrationLogs();
  }

  isCompany(){
    return this.role.toString()===Role.company.toString();
  }

  getClassIntegrationStatus(item){

    switch(item){
      case IntegrationStatus.REJECTED:
        return 'badge-danger';
      case IntegrationStatus.RECEIVED:
        return 'badge-info';
      case IntegrationStatus.PROCESSING:
        return 'badge-primary'; 
      case IntegrationStatus.PROCESSED:
        return 'badge-success';
      default:
        return 'badge-light';    
    }
  }

  getClassValidationStatus(item){

    switch(item){
      case ValidationResult.OK:
        return 'badge-success';
      case ValidationResult.ERROR:
        return 'badge-danger';
      case ValidationResult.WARNING:
        return 'badge-warning';       
      default:
        return 'badge-light';    
    }
  }

  convertDateIntegrationLocalTime(time){
    let localTime = new Date(Date.now());
    return new Date(time - localTime.getTimezoneOffset()); 
  }

  checkTimeExecutionStatus(status, time){

    if(status==IntegrationStatus.PROCESSING || status==IntegrationStatus.RECEIVED){

        let msg:string=this.translate.instant(status) + this.translate.instant(' há ');

        let localTime = new Date(Date.now());
        //Adjusted time from server plus timezone localtime to keep the dates in same timezone to calculate
        let msgTime = new Date(time - localTime.getTimezoneOffset()); 

        let diffMs = (Date.now()-msgTime.getTime()); 
        let diffDays = Math.floor(diffMs / 86400000); 
        let diffHrs = Math.floor((diffMs % 86400000) / 3600000); 
        let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

        if(diffDays>0){
          msg+=diffDays + this.translate.instant(' dias');
        }else if(diffHrs>0){
          msg+=diffHrs + this.translate.instant(' horas');
        }else if(diffMins>0){
          msg+=diffMins + this.translate.instant(' minutos');
        }

        return msg;
    }

    return this.translate.instant(status);
  }

  getContractTypes():SelectItem[]{
    return EnumEx.getEnumToSelectItem(ContractType);
  }

  getIntegrationStatus():SelectItem[]{
    return EnumEx.getEnumToSelectItem(IntegrationStatus);
  }

  getValidationResult():SelectItem[]{
    return EnumEx.getEnumToSelectItem(ValidationResult);
  }

  checkFilter(event:any){
    let addItem = new FilterMap(event.currentTarget.id,event.currentTarget.value);

    if(this.mapFilters.length>0){
      let found=this.mapFilters.find(item=> item.key==event.currentTarget.id);
      if(found==undefined){          
          this.mapFilters.push(addItem);
      }else{
        found.value = event.currentTarget.value;        
      }
    }else{
      this.mapFilters.push(addItem);
    }
  }

  generateFilters(map:Array<FilterMap>){
    var filters="";
    if(map.length>0){
      let obj = {};
      map.forEach(item=>{
        if(item.value.trim().length>0){
          obj[item.key] = item.value
        }
      });

      filters = Object.keys(obj).map(function(key) { 
        return key + '=' + obj[key]; 
      }).join('&'); 

      filters = btoa(filters);
    }

    return filters;
  }

  search() {
    this.page = 1;
    this.getIntegrationLogs();
  }

  searchByText(event:any){
    this.checkFilter(event);
    this.search();
  }

  searchByType(type:string,event:any){
    this.checkFilter({currentTarget:{"id":type,"value":event.value}});
    this.search();
  }

  searchByDate(type:string,event:any){
    if (this.rangeDates[1] === null) {
      return;
    }

    this.dateFilter.hideOverlay();
    let date:Date[] = this.rangeDates;
    this.checkFilter({currentTarget:{"id":'receivedAtInicial',"value":date[0].toISOString()}});
    this.checkFilter({currentTarget:{"id":'receivedAtEnd',"value":date[1].toISOString()}});
    this.search();
  }
}
