import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AutorizationUser } from 'src/model/autorization.model';

@Injectable()
export class AuthenticationService {

  private autorization:AutorizationUser;
  private currentUser:any;
  public token: string;
  private url = environment.urlBaseBe + '/login';

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };  

  constructor(private http: HttpClient) {    
    this.autorization = JSON.parse(localStorage.getItem('authorizationUser'));
    this.token = this.autorization && this.autorization.token;

  }

  login(_usuario: string, pass: string): Observable<any> {
    return this.http.post<any>(this.url, { username: _usuario, password: pass },{observe:'response'})
      .pipe(
        map(user => {
          
          // login bem-sucedido se houver um token jwt na resposta
          if (user && user.headers.get("Authorization")) {
            
            // armazenar detalhes do usuário e token jwt no localStorage para manter o usuário logado entre as atualizações da página
            let authResponse:AutorizationUser = new AutorizationUser();
            authResponse.response = user;
            
            authResponse.token = user.headers.get("Authorization");
            authResponse.credencial = this.getUserRole(authResponse);
            
            localStorage.setItem('authorizationUser', JSON.stringify(authResponse));
            this.autorization = authResponse;
          }

          return this.autorization;
        })
      );
  }

  getUserRole(data) {
    let jwtData = data.token.split('.')[1]
    let decodedJwtJsonData = window.atob(jwtData)
    let decodedJwtData = JSON.parse(decodedJwtJsonData)
    
    //console.log('jwtData: ' + jwtData)
    //console.log('decodedJwtJsonData: ' + decodedJwtJsonData)
    console.log('Is admin: ' + decodedJwtData.role);
    return decodedJwtData;
  }

  logout(): void {
    // Limpa o token removendo o usuário do local store para efetuar o logout
    this.token = null;
    this.autorization = null;
    localStorage.removeItem('authorizationUser');
  }

  getUser():AutorizationUser{
      return this.autorization;
  }

  getToken():any{
    if (this.autorization !== null) {
      return this.autorization.token;
    }
  }
}