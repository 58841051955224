import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SessionCheckService } from 'src/services/session-check.service';

@Component({
  selector: 'app-session-checker',
  templateUrl: './session-checker.component.html',
  styleUrls: ['./session-checker.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class SessionCheckerComponent implements OnInit, OnDestroy {

  constructor(public sessionMonitor:SessionCheckService) {     
    this.sessionMonitor.startCheckSessionUser();    
  }

  ngOnInit() {
  }

  closeModal(ev){
    this.sessionMonitor.closeModal(ev);
  }

  ngOnDestroy(){
    this.sessionMonitor.finishTimer();
  }

}
