import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AdminBeService, InstitutionService } from 'src/services';
import { TranslateService } from '../../common/locale';
import { Institution } from 'src/app/model/institution.model';
import { Role } from 'src/app/model/Role';
import { ExamTypesService } from 'src/services/exam-types.service';
import { ExamType } from 'src/app/model/examType.model';
import { ExamTypeInstitution } from 'src/app/model/examTypeInstitution.model';
import { ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'app-exam-types',
  templateUrl: './exam-types.component.html',
  styleUrls: ['./exam-types.component.css']
})
export class ExamTypesComponent implements OnInit {

  private autocomplete: AutoComplete;

  @ViewChild('autocomplete',{static:false}) set content(content: AutoComplete) {
     this.autocomplete = content;
  }
 
  isShowing:boolean = false;
  institutions: Array<any>;
  institutionSelected: any;
  disabledInstitutionField:boolean;  

  examTypeList:ExamType[];
  examTypeListAutoCompleted:ExamType[];
  examTypeSelected:ExamType;
  examTypeInstitutionList:ExamTypeInstitution[];
  clonedExamTypeInstitution:ExamTypeInstitution[];

  role: Role;

  language:string;
  credencial: any;

  isEditingRow:boolean = false;

  constructor(private adminBeService: AdminBeService, private _translate: TranslateService, private institutionService: InstitutionService,
    private examTypeService: ExamTypesService, private route: ActivatedRoute, private toastr: ToastrManager, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.examTypeList = [];
    this.examTypeInstitutionList = [];
    this.clonedExamTypeInstitution = [];
    this.credencial= this.adminBeService.getUser().credencial;    
    this.disabledInstitutionField = true;

    this._translate.onLangChanged.subscribe((lang) => {
      this.language=lang.toString();
    });

    this.route.params.subscribe(
      params => {
        this.role = params['role']; 
        
        this.checkRoleUser(this.role);
    });
    
    this.loadExamTypes();
  }

  getTranslation(item: string): string {
    return this._translate.instant(item);
  }

  loadExamTypes(){
    this.examTypeService.getAllExamType().subscribe(data=>{
      this.examTypeList = data;
      this.examTypeList.sort(this.sortExamType);
    });
  }

  sortExamType(examta:ExamType,examtb:ExamType){
    if(examta.name > examtb.name) return 1;
    return -1;
  }

  search(event) {
    this.institutionService.getInstitutionsByName(event.query).subscribe(data => {
        this.prepareDataForAutoComplete(data);
    });
  }

  prepareDataForAutoComplete (data: Array<Institution>) {
    this.institutions = [];
    for(let i=0; i < data.length; i++) {
      this.institutions.push({name: data[i].name, code: data[i].id})
    }
  }

  onSelect(event){
    let id=event.code;
    this.loadInstitutionDataAndExamTypes(id);
  }

  loadInstitutionDataAndExamTypes(id:number){
    this.institutionService.getInstitutionData(id).subscribe(inst=>{
      this.institutionSelected = inst;
      this.loadExamTypeInstitution(this.institutionSelected.id);
    })
  }

  loadExamTypeInstitution(id:number){
    this.institutionService.getExamTypes(id).subscribe(data=>{
      this.examTypeInstitutionList = data;
    });
  }

  checkRoleUser(role:Role){
    if([Role.manager, Role.support].indexOf(role) >= 0){ //Check if the actual user role, is one of them
      this.disabledInstitutionField = false;
    }

    if(Role.company == role){
      this.loadInstitutionDataAndExamTypes(this.credencial.inst);
    }
  }

  //Functions List
  
  searchExamType(event){
    let search:string=event.query;
    this.examTypeListAutoCompleted = this.examTypeList.filter(examType=>examType.name.indexOf(search.toUpperCase(),0)>=0);
  }

  onSelectExamType(event:ExamType){
    let id=event.code;
    this.examTypeSelected=new ExamType();
    this.examTypeSelected.code = event.code;    
    this.examTypeSelected.id = event.id;
    this.examTypeSelected.name = event.name;
    this.examTypeSelected.version = event.version;
  }

  onFocusOpen(autocomplete:any){
    this.examTypeListAutoCompleted = this.examTypeList;
    autocomplete.handleDropdownClick();    
  }

  onRowEditInit(examTypeInstitution: ExamTypeInstitution) {
    this.clonedExamTypeInstitution[examTypeInstitution.id] = {...examTypeInstitution};
    this.examTypeSelected = undefined;
    this.isEditingRow = true;   

    this.changeDetectorRef.detectChanges();
    
    setTimeout(()=>{
      this.autocomplete.focusInput();
    },200);

    this.isShowing = true;
  }

  onRowEditSave(examTypeInstitution: ExamTypeInstitution) {
    if(this.examTypeSelected instanceof ExamType){
      examTypeInstitution.examType = this.examTypeSelected;
      this.saveExamTypeInstitution(examTypeInstitution);
    }else{
      this.isEditingRow=false;
      this.toastr.warningToastr(this.getTranslation("Erro: O tipo do exame não foi salvo"), this.getTranslation("Error"));
    }

    this.isShowing = false;
  }
  
  onRowEditCancel(examTypeInstitution: ExamTypeInstitution, index: number) {
      this.isEditingRow=false;
      this.examTypeInstitutionList[index] = this.clonedExamTypeInstitution[examTypeInstitution.id];
      delete this.clonedExamTypeInstitution[examTypeInstitution.id];    
      
      this.isShowing = false;
  }

  saveExamTypeInstitution(examTypeInstitution:ExamTypeInstitution){
    let institutionId:number = this.institutionSelected.id;
    this.institutionService.postExamTypeInstitution(institutionId,examTypeInstitution).subscribe(
      data => {
        //this.group.id = data.id;
        this.toastr.successToastr(this.getTranslation("O tipo do exame foi salvo e vinculado"), this.getTranslation("Sucesso!"));        
        this.examTypeSelected = undefined;
      },
      error => {
        this.toastr.warningToastr(this.getTranslation("Erro: O tipo do exame não foi salvo"), this.getTranslation("Error"));          
        this.examTypeSelected = undefined;        
      });
  }
}
