import { Component, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from 'src/app/fe/common/locale';
import { Term } from 'src/app/model/term.model';
import { TermSearch } from 'src/app/model/termSearch.model';
import { TermsService } from 'src/services/terms.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  private termType = "PP";
  private userType = "ALL";

  term: Term = new Term();
  termSearch = new TermSearch(this.termType, this.userType);

  displayDialogCancel: boolean;

  constructor(private termsService: TermsService, private _translate: TranslateService, private toastr: ToastrManager) { }

  ngOnInit() {
    this.getLatestPrivacyPolicy();
  }

  showDialogCancel(){
    this.displayDialogCancel = true;
  }

  cancelDialog(){
    this.displayDialogCancel = false;
  }

  getLatestPrivacyPolicy() {
    this.termsService.getLatestTerm(this.termSearch).subscribe(data => {
      if (data) {
        this.term = data;
      }
    });
  }

  savePrivacyPolicy() {
    if (!this.term.id) {
      this.term.termType = this.termType;
      this.term.userType = this.userType;
    }

    this.termsService.saveTerm(this.term).subscribe(data => {
      this.toastr.successToastr(this._translate.instant("Política de privacidade atualizada com sucesso!"), this._translate.instant("Sucesso!"));
      this.cancelDialog();
    },
    error => { 
      this.toastr.warningToastr(this._translate.instant("Erro: Não foi possível salvar a nova política de privacidade."), this._translate.instant("Erro"));
      this.cancelDialog();
    });
  }

}
