import { Country } from './country.model';

export class State {
    id:number;
    name:string;
    abbreviation:string;
    country: Country;
    version:number;

    constructor() {
        this.country = new Country();
    }
}