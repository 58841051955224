import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {

  constructor() { }
  
  isEmailValid(email:string){
    var EMAIL_REGEXP = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    if (!EMAIL_REGEXP.test(email)) {
      return false;
    }

    return true;
  }
 
}