import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../common/locale';
import { ActivatedRoute, Router } from '@angular/router';
import { Group } from 'src/app/model/group.model';
import { GroupUser } from 'src/app/model/groupUser.model';
import { GroupService } from 'src/services/group.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserModel } from 'src/app/model/userModel';
import { ValidatorsService } from 'src/services/validators.service';

@Component({
  selector: 'app-users-group-list',
  templateUrl: './users-group-list.component.html',
  styleUrls: ['./users-group-list.component.css']
})
export class UsersGroupListComponent implements OnInit {

  invalidEmail:boolean = false;
  finishSaveUserGroup:boolean=true;
  displayDialog: boolean;
  displayDialogRemove: boolean;

  emailUser:string;
  groupUser:GroupUser;
  groupId:number;
  group:Group;
  cols: any[];
  usersGroup:GroupUser[]=[];

  pg:number=0;

  constructor(private _translate: TranslateService, private route: ActivatedRoute,
          private groupService:GroupService, private toastr: ToastrManager, private router: Router,
          private validator:ValidatorsService) { }

  ngOnInit() {

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'username', header: this.getTranslation('Usuário Email') },
      { header: '' }
    ];

    this.route.params.subscribe(
      params => {
        this.groupId = params['id'];
        if(params['pg']){
          this.pg=params['pg'];
        }
    });

    if (this.groupId !== undefined && this.groupId !== null) {
      this.groupService.getGroupData(this.groupId).subscribe(data=> {
        this.group=data;

        this.fillUsers(this.group.id);
      })
    }
  }

  getTranslation(item: string): string {
    return this._translate.instant(item);
  }

  fillUsers(groupId:number){
    this.groupService.getAllUsersByGroup(groupId).subscribe(data=>{
      this.usersGroup=data;
    });
  }

  backListGroups(){
    let page = this.pg;
    this.router.navigate(['groupList', {pg:page}]);
  }

  saveUserGroup(){

    if (this.emailUser==undefined || this.emailUser.length<=0) {
      this.toastr.warningToastr(this.getTranslation("Necessário informar o email do usuário!"), this.getTranslation("Error"));
      return false;
    }

    //Check on list if contains the email
    let usrGrp:GroupUser[] = this.usersGroup.filter(usergrp=>usergrp.systemUser.username==this.emailUser);
    if(usrGrp.length>0){
      this.toastr.warningToastr(this.getTranslation("Email já está vinculado no grupo!"), this.getTranslation("Error"));
      return false;
    }

    this.finishSaveUserGroup=false;
    this.groupUser = new GroupUser();
    this.groupUser.group = this.group;
    this.groupUser.systemUser = new UserModel();
    this.groupUser.systemUser.username = this.emailUser;

    this.groupService.saveUserGroup(this.groupUser).subscribe(data=>{
        this.groupUser=data;
        this.emailUser='';
        this.toastr.successToastr(this.getTranslation('Usuário adicionado ao grupo com sucesso!'),this.getTranslation('Adicionar Usuário'));
        this.fillUsers(this.group.id);
        this.displayDialog=false;
        this.finishSaveUserGroup=true;
    },error=>{
      this.toastr.warningToastr(this.getTranslation('Erro: Email do usuário deve ser válido e necessário ser Empresa ou Médico!'),this.getTranslation('Erro Inclusão'));
      this.finishSaveUserGroup=true;
    });
  }

  removeUserGroup(groupUserId:number){
     this.groupService.removeUserGroup(groupUserId).subscribe(data=>{
          if(data){
            this.toastr.successToastr(this.getTranslation('Usuário removido do grupo com sucesso!'),this.getTranslation('Deletar Usuário'));
            this.displayDialogRemove = false;
            this.fillUsers(this.group.id);
          }
      });
  }

  //Functions of Dialog
  showDialogToAdd() {
    this.groupUser = new GroupUser();
    this.displayDialog = true;
  }

  showDialogToRemove(groupUser:GroupUser) {
    this.groupUser = groupUser;
    this.displayDialogRemove = true;
  }

  cancelDialog(){
    this.displayDialog=false;
    this.displayDialogRemove=false;
  }

  emailValidator(event) {
    this.invalidEmail = false;
    if(event.target.value.trim().length>0){
      if (!this.validator.isEmailValid(event.target.value)) {
        this.invalidEmail = true;
      }
    }
  }
}
