import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IntegrationLog } from 'src/app/model/integrationLog.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  getAllIntegrationLog(pageNumber:number, size: number, filters:string):Observable<any> {
    return this.http.get<IntegrationLog[]>(this.urlAdminBe + '/integration/list?page='+pageNumber+'&size='+size+'&filters='+filters)
    .pipe(map(data => data));
  }

  getTotalsIntegrationLog():Observable<any> {
    return this.http.get<any[]>(this.urlAdminBe + '/integration/totals')
    .pipe(map(data => data));
  }
}
