import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '../../common/locale';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Role } from 'src/app/model/Role';
import { Invite } from 'src/app/model/invite.model';
import { Physician } from 'src/app/model/physician.model';
import { InstitutionService, AdminBeService } from 'src/services';
import { Institution } from 'src/app/model/institution.model';
import { SelectItem } from 'primeng/api';
import { AddressService } from 'src/services/address.service';
import { State } from 'src/app/model/state.model';
import { ValidatorsService } from 'src/services/validators.service';
import { InviteService } from 'src/services/invite.service';
import { StatusInvite } from 'src/app/model/statusInvite';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css']
})
export class InviteComponent implements OnInit {

  @ViewChild('frmInvite',{static:false}) frmInvite;

  invalidEmail:boolean = false;
  institutions: Array<any>;
  institutionSelected: any;
  disabledInstitutionField:boolean;
  physician:Physician;
  invites: Invite[];
  cols: any[];
  role: Role;

  selectedState: State;
  states: SelectItem[];

  language:string;
  credencial: any;

  fieldFilterList: string;
  pageFromList: string;
  valueFilterList: string;
  rangeDate1: Date;
  rangeDate2: Date;
  showBackButton: boolean = false;

  createUserConsentValue: boolean;

  constructor(private _translate: TranslateService, private route: ActivatedRoute,
    private toastr: ToastrManager, private institutionService: InstitutionService, private adminBeService: AdminBeService,
    private addressService: AddressService, private validator:ValidatorsService, private inviteService:InviteService, private router: Router, private spinner: NgxSpinnerService) {
      this.showBackButton = false;
     }

  ngOnInit() {

    this.credencial= this.adminBeService.getUser().credencial;

    this.invites = [];
    this.physician = new Physician();
    this.states = new Array();
    this.institutions = [];
    this.institutionSelected = undefined;
    this.disabledInstitutionField = true;

    this.cols = [
      { field: 'institution', header: this.getTranslation('Instituição') },
      { field: 'name', header: this.getTranslation('Nome') },
      { field: 'crm', header: this.getTranslation('CRM') },
      { field: 'name', header: this.getTranslation('Estado') },
      { field: 'email', header: this.getTranslation('Email') },
      { field: 'status', header: this.getTranslation('Status') },
      { header: '' }
    ];

    this._translate.onLangChanged.subscribe((lang) => {
      this.language=lang.toString();
    });

    this.route.params.subscribe(
      params => {
        this.role = params['role'];
        if(params['pg']){
          this.showBackButton = true;
          this.pageFromList=params['pg'];
          this.valueFilterList=params['value'];
          this.fieldFilterList=params['fieldSearch'];
          this.rangeDate1=params['intial'];
          this.rangeDate2=params['end'];
        }
        if (params['pg'] !== undefined) {
          this.loadData(params['invites'])
        }

        this.checkRoleUser(this.role);
    });


  }

  loadData(invites: any) {
    this.language = this._translate.currentLang
    let json = JSON.parse(invites);
    let inv = [];
    const credencial = this.credencial;
    const language = this.language;
    json.list.forEach(function(item:any) {
      let phy = new Physician();
      phy.name  = item.name;
      phy.email = typeof item.email === 'string' ? item.email.trim() : item.email;
      phy.crm = item.crm
      phy.state = new State();
      phy.state.id = item.stateId;
      phy.state.abbreviation = item.state;
      let inst = new Institution();
      inst.id = item.institutionId;
      inst.name = item.institutionName;

      let invite = new Invite();
      invite.institution = inst;
      invite.physician = {...phy}
      invite.systemUser.username = credencial.sub;
      invite.systemUser.userType = credencial.role;
      invite.language = language;

      inv.push(invite);
    });
    this.invites = inv;
  }

  checkRoleUser(role:Role){
    if([Role.manager, Role.support].indexOf(role) >= 0){ //Check if the actual user role, is one of them
      this.disabledInstitutionField = false;
    }

    if(Role.company == role){
      this.institutionService.getInstitutionData(this.credencial.inst).subscribe(inst=>{
        this.institutionSelected = inst;
        this.getStates(this.institutionSelected.address.city.state.country.id);
      })
    }
  }

  search(event) {
    this.institutionService.getInstitutionsByName(event.query).subscribe(data => {
        this.prepareDataForAutoComplete(data);
    });
  }

  prepareDataForAutoComplete (data: Array<Institution>) {
    this.institutions = [];
    for(let i=0; i < data.length; i++) {
      this.institutions.push({name: data[i].name, code: data[i].id})
    }
  }

  onSelect(event){
    let id=event.code;
    this.institutionService.getInstitutionData(id).subscribe(inst=>{
      this.institutionSelected = inst;
      this.getStates(this.institutionSelected.address.city.state.country.id);
    })
  }

  getTranslation(item: string): string {
    return this._translate.instant(item);
  }

  removeInvite(invite:Invite){
    let index = this.invites.indexOf(invite);
    this.invites.splice(index, 1);
  }

  onInsertInvite(){

    //Check if there are some row processed and remove on the list, keep just not finished
    this.removeInvitesSended();

    if(this.checkFieldsInvalid()){

      this.physician.state = this.selectedState;

      let invite = new Invite();
      invite.institution = {...this.institutionSelected}
      invite.physician = {...this.physician}
      invite.systemUser.username = this.credencial.sub;
      invite.systemUser.userType = this.credencial.role;
      invite.language = this.language;

      this.physician = {...new Physician()}

      this.invites.push(invite);

      this.frmInvite.reset();
    }
  }

  sendInvites(){

    this.spinner.show();

    //Check if there are some row processed and remove on the list, keep just not finished
    this.removeInvitesSended();

    //Send all invites
    this.inviteService.sendAllInvites(this.invites).subscribe((invites)=>{
      this.invites = invites;
      this.invites.forEach((item)=>{
        item.status = StatusInvite[item.status.toString()];
      });

      this.spinner.hide();
      this.toastr.successToastr(this._translate.instant("Convites processados, veja coluna status para mais detalhes"), this._translate.instant("Sucesso!"));
    },
    error => {
      this.spinner.hide();
      this.toastr.warningToastr(this._translate.instant("Erro: Os convites não foram processados"), this._translate.instant("Erro"));
    });
  }

  removeInvitesSended(){
    this.invites = this.invites.filter(invite=>invite.status!=StatusInvite.SENT);
  }

  checkFieldsInvalid():boolean{

    let valid:boolean = true;

    if(this.physician.email.length<=0){
      this.invalidEmail = true;
      valid = false;
    }

    if(this.selectedState==undefined){
      valid = false;
    }

    //Check if there are same email on list | CRM and state
    this.invites.forEach(element => {
      if(element.physician.email==this.physician.email){
        this.toastr.warningToastr(this.getTranslation("Email já adicionado!"), this.getTranslation("Error"));
        valid = false;
      }

      if(element.physician.crm==this.physician.crm && element.physician.state.id==this.selectedState.id){
        this.toastr.warningToastr(this.getTranslation("CRM e estado já adicionado!"), this.getTranslation("Error"));
        valid = false;
      }
    });

    return valid;
  }

  getStatusInvite(status:number){
    return StatusInvite[status];
  }

  getStates(countryId: any) {
    this.states = new Array();
    this.addressService.getAllCountryState(countryId).subscribe(data => {
      for (let i=0; i < data.length; i++) {
        this.states.push({label: data[i].name, value: data[i]});
      }
    });
  }

  onSelectState(event: any) {
   this.selectedState = event.value;
  }

  emailValidator(event) {
    this.invalidEmail = false;
    if(event.target.value.trim().length>0){
      if (!this.validator.isEmailValid(event.target.value)) {
        this.invalidEmail = true;
      }
    }
  }

  backListInvites() {
    this.router.navigate(['searchInvites', {pg:this.pageFromList, field: this.fieldFilterList, value: this.valueFilterList, initial: this.rangeDate1, end: this.rangeDate2}]);
  }
}
