import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Institution } from 'src/app/model/institution.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country } from 'src/app/model/country.model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }
  
  getAllCountries():Observable<any> {
    return this.http.get<Country[]>(this.urlAdminBe + "/country")
    .pipe(map(data => data));
  }

  getAllCountryState(countryId: any):Observable<any> {
    return this.http.get<Country[]>(this.urlAdminBe + "/state/" + countryId)
    .pipe(map(data => data));
  }

  getAllStateCity(stateId: any):Observable<any> {
    return this.http.get<Country[]>(this.urlAdminBe + "/city/" + stateId)
    .pipe(map(data => data));
  }
 
}