export class Setting {
    id:number;
    name:string;
    typeData: string;
    type:string;
    description:string;
    showOnConfigPage:boolean;
    shortDescription:string;
    version:number;

    constructor() {
        this.id = 0;
        this.description="";
    }
}