import { Address } from './address.model';

export class Institution {
    id:number;
    token:string;
    name:string;
    tradingName:string;
    legalIdentifier:string;
    website:string;
    address:Address;
    version:number;

    constructor() {
        this.address = new Address();
    }
}