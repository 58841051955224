import { Component, OnInit } from '@angular/core';
import { InstitutionService, AdminBeService } from 'src/services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from '../common/locale';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingService } from 'src/services/setting.service';
import { InstitutionSetting } from 'src/app/model/institutionSetting.model';
import { Institution } from 'src/app/model/institution.model';
import { Role } from 'src/app/model/Role';
import { Setting } from 'src/app/model/setting.model';
import { SelectItem } from 'primeng/api';
import { FrequencyService } from 'src/services/frequency.service';

@Component({
  selector: 'app-notifyemail',
  templateUrl: './notifyemail.component.html',
  styleUrls: ['./notifyemail.component.css']
})
export class NotifyEmailComponent implements OnInit {

  institutions: Array<any>;
  institutionSelected: any;
  disabledInstitutionField:boolean;

  settings: Setting[];
  selectedSetting: number;

  role: Role;
  credencial: any;

  rowIndex:number;
  isEditingRow: boolean;
  colsSetting: any[];
  institutionSetting: InstitutionSetting;
  institutionSettingsItems: InstitutionSetting[];
  cloneInstitutionSetting: { [s: string]: InstitutionSetting; } = {};
  institutionSettingsItemsNotify:InstitutionSetting[];
  dropDownSetting: SelectItem[]=[];
  dropDownFrequency: SelectItem[]=[];
  validSetting:boolean = false;

  displayDialogRemove: boolean;

  frequencyBeforeEdit:string;

  typeDataBoolean: SelectItem[]=[
    {label: this._translate.instant('Sim'), value: 'true'},
    {label: this._translate.instant('Não'), value: 'false'}
  ];

  constructor(private institutionService: InstitutionService, private toastr: ToastrManager,
    private route: ActivatedRoute, private adminBeService: AdminBeService, private _translate: TranslateService, private router: Router,
    private settingsService: SettingService, private frequencyService: FrequencyService) { }

  ngOnInit() {
    this.settings = [];    
    this.institutionSetting = new InstitutionSetting();
    this.institutionSettingsItems = [];
    this.institutionSettingsItemsNotify = [];
    this.disabledInstitutionField = true;
    this.validSetting = false;
    this.selectedSetting = 0;
    this.credencial= this.adminBeService.getUser().credencial; 

    this.colsSetting = [
      { field: 'id', header: this._translate.instant('ID'), style:'width:10%' },
      { field: 'name', header: this._translate.instant('Descrição') },
      { field: 'alias', header: this._translate.instant('Nome') },
      { field: 'active', header: this._translate.instant('Valor')},
      { header: '' }
    ];

    this.route.params.subscribe(
      params => {
        this.role = params['role']; 
        
        this.checkRoleUser(this.role);
    });

    this.loadSettings();
    this.loadFrequency();
  }

  checkRoleUser(role:Role){
    if([Role.manager, Role.support].indexOf(role) >= 0){ //Check if the actual user role, is one of them
      this.disabledInstitutionField = false;
    }

    if(Role.company == role){
      this.loadInstitutionDataAndSettings(this.credencial.inst);
    }
  }

  onSelect(event){
    let id=event.code;
    this.loadInstitutionDataAndSettings(id);
  }

  loadInstitutionDataAndSettings(id:number){
    this.institutionService.getInstitutionData(id).subscribe(inst=>{
      this.institutionSelected = inst;
      this.getInstitutionSettings(this.institutionSelected.id);
    })
  }

  search(event) {
    this.institutionService.getInstitutionsByName(event.query).subscribe(data => {
        this.prepareDataForAutoComplete(data);
    });
  }

  prepareDataForAutoComplete (data: Array<Institution>) {
    this.institutions = [];
    for(let i=0; i < data.length; i++) {
      this.institutions.push({name: data[i].name, code: data[i].id})
    }
  }
  
  getInstitutionSettings(id:number){
    this.institutionService.getSettings(id).subscribe(data => {
      if(data){
        this.institutionSettingsItems = data;
        this.institutionSettingsItemsNotify = this.institutionSettingsItems.filter(item=>item.setting.showOnConfigPage==true);
      }
    });
  }
  
  checkIfContainsSetting(id){
    //Check if that settings contains in the list
    this.selectedSetting=id;
    let filterSetting = this.institutionSettingsItems.find(x => x.setting.id == id);
    if(filterSetting){ //imageServiceResolver could be more than one to institution
      this.toastr.warningToastr(this._translate.instant("Erro: Essa notificação já está vinculada na instituição"), this._translate.instant("Erro"));
      this.validSetting = false;
      this.selectedSetting=0;
      return false;
    }

    this.validSetting = true;
  }

  onRowEditInit(instService: InstitutionSetting,ri) {
    this.rowIndex=ri;
    this.cloneInstitutionSetting[instService.id] = {...instService};    
    this.isEditingRow = true;
    this.frequencyBeforeEdit=instService.value;
  }

  onRowEditSave(institutionSetting: InstitutionSetting) {
    institutionSetting.institution = this.institutionSelected;
    this.saveSettingInstitution(institutionSetting);
  }
  
  onRowEditCancel(instService: InstitutionSetting, index: number) {
      let id = instService.id;
      
      this.isEditingRow=false;
      this.institutionSettingsItems[index] = this.cloneInstitutionSetting[instService.id];
      delete this.cloneInstitutionSetting[instService.id];    

      if(id==0 || (+instService.value.toString()!=+this.frequencyBeforeEdit.toString())){ //new setting
        this.getInstitutionSettings(this.institutionSelected.id);
      }
  }

  showDialogToRemove(setting:InstitutionSetting) {
    this.institutionSetting = setting;
    this.displayDialogRemove = true;
  }

  cancelDialog(){
    this.displayDialogRemove=false;
  }

  removeSettingInstitution(settingId:number){
    this.institutionService.removeSetting(settingId).subscribe(data=>{
         if(data){
           this.toastr.successToastr(this._translate.instant('Notificação removida da instituição!'),this._translate.instant('Remover Notificação'));
           this.displayDialogRemove = false;
           this.getInstitutionSettings(this.institutionSelected.id);
         }
     });
  }

  saveSettingInstitution(instSetting:InstitutionSetting){
        
    if(+instSetting.value.toString()<=0){
      this.toastr.warningToastr(this._translate.instant('Erro: Necessário informar o valor da notificação'), this._translate.instant("Erro"));
      this.onRowEditCancel(instSetting,this.rowIndex);
      this.onRowEditInit(instSetting,this.rowIndex);
    }else{
      this.institutionService.postSettings(instSetting).subscribe(
        data => {
          this.institutionSetting = new InstitutionSetting();
          this.selectedSetting = 0;
          this.toastr.successToastr(this._translate.instant("A notificação foi salva"), this._translate.instant("Sucesso!"));
          this.getInstitutionSettings(this.institutionSelected.id);            
        },
        error => {
          this.toastr.warningToastr(this._translate.instant("Erro: Notificação não foi salva, verifique os campos"), this._translate.instant("Erro"));
        }); 
    }
  }

  AddSetting(){

    if(this.selectedSetting>0){
      
        let setting:Setting = this.settings.find(st => st.id==this.selectedSetting);
        let instSetting:InstitutionSetting = new InstitutionSetting();
        instSetting.id=0;
        instSetting.institution=this.institutionSelected;
        instSetting.active=true;
        instSetting.value="";
        instSetting.setting = setting; 

        return instSetting;
    }
  }

  loadSettings(){
    this.settingsService.getAllSetting().subscribe(data => {
      if(data){
        this.settings = data;

        this.settings.map(item=>{
          if(item.showOnConfigPage){
            this.dropDownSetting.push({label: item.shortDescription, value: item.id});
          }
        });
      }
    });
  }

  loadFrequency(){
    this.frequencyService.getAllFrequency().subscribe(data => {
      if(data){
        
        data.map(item=>{
            this.dropDownFrequency.push({label: item.description, value: item.id});
        });
      }
    });
  }

  getFrequency(id:number){
    let item:SelectItem = this.dropDownFrequency.find(item=>+item.value==id);
    return item.label;
  }
}
