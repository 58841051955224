import { Component, OnInit, ViewChild } from '@angular/core';
import { GroupService } from 'src/services/group.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Group } from 'src/app/model/group.model';
import { Institution } from 'src/app/model/institution.model';
import { AdminBeService, InstitutionService } from 'src/services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SelectItem } from 'primeng/api';
import { TableModule, Table } from 'primeng/table';
import { TranslateService } from '../../common/locale';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css']
})
export class GroupListComponent implements OnInit {

  @ViewChild(Table,{static:false}) dt: Table;

  editing: boolean;
  ctx = {editing: ''}

  first: number = 0;
  pageNumber:number = 1;
  rowIndex:number;
  codeBeforeEditting:string;
  activeBeforeEditting:boolean;

  displayDialog: boolean;
  newGroup: boolean;
  
  displayDialogRemove: boolean;
  remGroup: boolean;

  group:Group;
  institution:Institution=new Institution();
  groups: Group[];
  selectedGroup: Group;

  isEditingRow:boolean = false;

  cols: any[];
  active: SelectItem[]=[
    {label: this.getTranslation('Sim'), value: true},
    {label: this.getTranslation('Não'), value: false}
  ];

  activeFilter: SelectItem[];

  clonedGroups: { [s: string]: Group; } = {};

  constructor(private _translate: TranslateService, private groupService:GroupService, 
    private institutionService:InstitutionService, private router: Router,private adminBeService: AdminBeService, 
    private toastr: ToastrManager, private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.params.subscribe(
      params => {
        if(params['pg']){
          this.pageNumber = params['pg']; 
        }
    });

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'name', header: this.getTranslation('Código') },
      { field: 'alias', header: this.getTranslation('Descrição') },
      { field: 'active', header: this.getTranslation('Ativo')},
      { header: '' }
    ];

    this.activeFilter=this.active.slice();
    this.activeFilter.push({label: 'Todos', value: null});

    let credencial= this.adminBeService.getUser().credencial;
    this.groups = [{id:0,name:'CARREGANDO...',alias:'', institution:undefined, active:true,version:1}];
    
    //Load institution Data
    this.institutionService.getInstitutionData(credencial.inst).subscribe(data => this.institution=data);

    this.group = new Group();    
    this.fillGroups(credencial.inst);
  }

  fillGroups(id:number){
    this.groupService.getAllGroups(id).subscribe(data =>{
      this.groups=data

      if(this.pageNumber){
        this.setCurrentPage(this.pageNumber);
      }
    });
  }

  getTranslation(item: string): string {
    return this._translate.instant(item);
  }

  //Functions of groupList
  onRowEditInit(group: Group) {
    this.codeBeforeEditting=group.name;    
    this.activeBeforeEditting=group.active;
    this.clonedGroups[group.id] = {...group};    
    this.isEditingRow = true;
  }

  onRowEditSave(group: Group) {

    if(this.codeBeforeEditting!=group.name){
      //check if there are exams related
      let related:boolean=false;
      this.groupService.isGroupRelatedInExams(group.id, group.institution.id).subscribe((data)=>{
        related=data;

        if(related){
          this.saveEditting(group);
        }        
      },error=>{
        group.name=this.codeBeforeEditting;
        this.toastr.warningToastr(this.getTranslation("Erro: Não é possível alterar. O Grupo está vinculado a pelo menos um exame!"), this.getTranslation("Error"));        
        this.isEditingRow=false;
      });
    }else{
      this.saveEditting(group);
    }
  }
  
  onRowEditCancel(group: Group, index: number) {
      this.isEditingRow=false;
      this.groups[index] = this.clonedGroups[group.id];
      delete this.clonedGroups[group.id];      
  }

  onRowSelect(event) {
    if(event.data.id>0){
      let isBtnAction = event.originalEvent.srcElement.classList.contains('btn');
      if(!isBtnAction && !this.isEditingRow){
        this.router.navigate(['usersGroupList/'+event.data.id, {pg:this.pageNumber}]);
      }
    }
  }

  onChange(group:Group,ri,event) {   
    this.rowIndex=ri;

    if(event.value==false){
      this.showDialogToRemove(group);
    }
  }

  setCurrentPage(n: number) {
    this.dt.first = ((n - 1) * this.dt.rows);
    console.log(this.pageNumber);
  }

  paginate(event) {
    //event.first: Index of first record being displayed 
    //event.rows: Number of rows to display in new page 
    //event.page: Index of the new page 
    //event.pageCount: Total number of pages 
    let pageIndex = event.first/event.rows + 1 // Index of the new page if event.page not defined.
    this.pageNumber = pageIndex;
    console.log(this.pageNumber);
  }

  //Functions of Dialog
  showDialogToAdd() {
    this.rowIndex = undefined;
    this.newGroup = true;
    this.group = new Group();
    this.displayDialog = true;
  }

  showDialogToRemove(group:Group) {
    this.remGroup = true;
    this.group = group;
    this.displayDialogRemove = true;
  }

  cancelDialog(group:Group){
    if(this.rowIndex>=0){     
      group.active=true; 
      this.onRowEditCancel(group,this.rowIndex);      
      this.onRowEditInit(group);
    }

    this.displayDialog=false;
    this.displayDialogRemove=false;
  }

  removeGroup(group:Group){
    this.displayDialogRemove=false;
  }

  saveEditting(group:Group){
    if(!this.save(group)){
      this.editing=true;
    }
  }

  //Make sure when user trying to put blank space then keep empty field
  checkFieldSpace(event){
    if(event.target.value.trim().length<=0){
      event.target.value = '';
      event.target.classList.add('ng-invalid');
    }else{
      event.target.classList.remove('ng-invalid');
    }
  }

  checkFields(group:Group){
    if (group.name==undefined || group.alias==undefined || group.name.trim().length<=0 || group.alias.trim().length<=0 ) {
      this.toastr.warningToastr(this.getTranslation("Todos os campos são obrigatórios"), this.getTranslation("Error"));
      this.isEditingRow=true;
      return false;
    }

    return true;
  }

  addNewGroup(group:Group){
    group.institution=this.institution;
    group.active=true;
    this.save(group);
  }

  save(group:Group) {
    if(this.checkFields(group)){
      this.isEditingRow=false;
      this.groupService.saveGroup(group).subscribe(
        data => {
          this.group.id = data.id;
          this.toastr.successToastr(this.getTranslation("O Grupo foi salvo"), this.getTranslation("Sucesso!"));
          
          if(this.newGroup){
            this.groups.push(data);
            this.displayDialog = false;
          }

          this.fillGroups(this.institution.id);
        },
        error => {

          if(error.status==424){
            this.toastr.warningToastr(this.getTranslation("Erro: O Grupo não foi salvo, já existe um código com este nome."), this.getTranslation("Error"));          
            this.fillGroups(this.institution.id);
          }
          else{
            this.toastr.warningToastr(this.getTranslation("Erro: O Grupo não foi salvo"), this.getTranslation("Error"));          
          }
        });
      }else{
        return false;
      }
  }
  
}
