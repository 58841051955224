import { State } from './state.model';

export class Physician {
    name:string;
    crm:string;
    state: State;
    email:string;

    constructor() {
        this.state = new State();
    }
}