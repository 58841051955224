import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../locale';
import { AdminBeService } from 'src/services';
import { faSignOutAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public translatedText: string;
  public supportedLanguages: any[];
  defaultLanguageFlag: string;
  defaultLanguage: string;
  title = 'Clickvita Administration';
  userActive: any;

  faSignOutAlt = faSignOutAlt;
  faCog = faCog;

  constructor(private _translate: TranslateService, private adminBeService: AdminBeService, private router: Router) {

  }


  ngOnInit() {
    // standing data
    this.supportedLanguages = [
      { display: 'Portugues', value: 'pt' },
      { display: 'English', value: 'en' },
      { display: 'Español', value: 'es' }

    ];
    // set current langage
    this.selectLang('pt-br');
    this.defaultLanguage = this._translate.currentLang;
    if (this.adminBeService.getUser())
      this.userActive = this.adminBeService.getUser().credencial;
    else
      this.userActive = undefined;
  }


  isCurrentLang(lang: string) {
    // check if the selected lang is current lang
    this.selectLang(this._translate.currentLang);
    return lang === this._translate.currentLang;
  }

  selectLang(lang: string) {
    // set current lang;
    this._translate.use(lang);

    switch (lang) {
      case 'es':
        this.defaultLanguageFlag = 'assets/flag-es.png';
        break;
      default:
        this.defaultLanguageFlag = 'assets/flag-pt-br.png';
        break;

    }
    return false;
  }

  emptyMethod() {
    return false;
  }

  logout() {
    this.userActive = undefined;
    this.adminBeService.logout();
  }

  openAccount(e){
    e.stopPropagation();
    e.preventDefault();

    this.router.navigate(['/account', {role:this.userActive.role.toString()}]);
    
  }
}
