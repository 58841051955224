import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Institution } from 'src/app/model/institution.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomLoginPage } from 'src/app/model/customLoginPage.model';
import { Setting } from 'src/app/model/setting.model';
import { InstitutionSetting } from 'src/app/model/institutionSetting.model';
import { InstitutionUnit } from 'src/app/model/institutionUnit.model';
import { ExamTypeInstitution } from 'src/app/model/examTypeInstitution.model';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  //Institutions
  getAllInstitutions():Observable<any> {
    return this.http.get<Institution[]>(this.urlAdminBe + "/institution")
    .pipe(map(data => data));
  }

  getInstitutionData(id: any):Observable<Institution> {
    return this.http.get<Institution>(this.urlAdminBe + "/institution/" + id)
    .pipe(map(data => data));
  }

  getInstitutionsByName(name: string):Observable<Institution[]> {
    return this.http.get<Institution[]>(this.urlAdminBe + "/institution/descricao?name=" + name)
    .pipe(map(data => data));
  }

  postInstitution(institution: Institution):Observable<any> {
    return this.http.post<Institution>(this.urlAdminBe + "/institution", institution)
    .pipe(map(data => data));
  }

  //Custom Page Institution
  postCustomLoginPage(customLoginPage: CustomLoginPage):Observable<any> {
    return this.http.post<CustomLoginPage>(this.urlAdminBe + "/institution/" + customLoginPage.institution.id + "/customLoginPage", customLoginPage)
    .pipe(map(data => data));
  }

  getCustomLoginPageData(id: any):Observable<CustomLoginPage> {
    return this.http.get<CustomLoginPage>(this.urlAdminBe + "/institution/" + id + "/customLoginPage")
    .pipe(map(data => data));
  }

  //Settings Institution
  postSettings(settings: InstitutionSetting):Observable<any> {
    let institutionId = settings.institution.id;

    return this.http.post<Setting>(this.urlAdminBe + "/institution/" + institutionId + "/settings", settings)
    .pipe(map(data => data));
  }

  getSettings(id: any):Observable<InstitutionSetting[]> {
    return this.http.get<InstitutionSetting[]>(this.urlAdminBe + "/institution/" + id + "/settings")
    .pipe(map(data => data));
  }

  removeSetting(settingId:number){
    return this.http.delete<boolean>(this.urlAdminBe + "/institution/settings/" + settingId)
    .pipe(map(data => data));
  }

  //Units
  getUnits(id:any){
    return this.http.get<InstitutionUnit[]>(this.urlAdminBe + "/institution/" + id + "/units")
    .pipe(map(data => data));
  }

  postUnit(unit: InstitutionUnit):Observable<any> {
    let institutionId = unit.institution.id;

    return this.http.post<InstitutionUnit>(this.urlAdminBe + "/institution/" + institutionId + "/units", unit)
    .pipe(map(data => data));
  }

  getExamTypes(id: any):Observable<ExamTypeInstitution[]> {
    return this.http.get<ExamTypeInstitution[]>(this.urlAdminBe + "/institution/" + id + "/examTypes")
    .pipe(map(data => data));
  }

  postExamTypeInstitution(instId:number, examT: ExamTypeInstitution):Observable<any> {
    return this.http.post<InstitutionUnit>(this.urlAdminBe + "/institution/" + instId + "/examTypes", examT)
    .pipe(map(data => data));
  }

  getLgpdPortabilityDataUser(id:number):Observable<any[]>{
    return this.http.get<any[]>(this.urlAdminBe + "/institution/lgpd/portability?id=" + id)
    .pipe(map(data => data));
  }
}
