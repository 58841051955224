import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MyAccount } from 'src/app/model/myaccount';
import { Role } from 'src/app/model/Role';
import { AdminBeService } from 'src/services';
import { UserService } from 'src/services/user.service';
import { TranslateService } from '../common/locale';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  myaccount:NgForm;

  systemUser:MyAccount;

  language:string;
  credencial: any;

  isEnabledPassword:boolean;
  isEnabledEmail:boolean;

  role: Role;

  constructor(private toastr: ToastrManager, private _translate: TranslateService, 
    private adminBeService: AdminBeService, private router: Router, 
    private route: ActivatedRoute, private userService:UserService) {}

  ngOnInit() {

    this.systemUser = new MyAccount();
    this.systemUser.changeEmail.changeNewEmail=false;
    this.systemUser.changePassword.resetPassword=false;
    
    this.isEnabledEmail=false;
    this.isEnabledPassword=false;

    this.credencial= this.adminBeService.getUser().credencial;

    this.systemUser.email = this.credencial.sub;

    this._translate.onLangChanged.subscribe((lang) => {
      this.language=lang.toString();
    });
    
    this.route.params.subscribe(
      params => {
        this.role = params['role'];   
    });

    let idUser=this.userService.getUserByUsername().subscribe(data=>{
      this.systemUser.id=data;
    });
  }

  saveAccount(event:Event){

    event.preventDefault();

    this.systemUser.language = this.language;

    if(this.systemUser.changeEmail.changeNewEmail){
      this.systemUser.name = this.systemUser.changeEmail.confirmNewEmail.toString();
    }
    
    this.userService.saveAccountData(this.systemUser).subscribe(data=>{
      this.toastr.successToastr(this._translate.instant('Os dados foram salvos com sucesso.'), this._translate.instant("Sucesso"));      

      if(this.systemUser.changeEmail.changeNewEmail){
        this.toastr.warningToastr(this._translate.instant('Verifique sua caixa de email') +  ' [' + this.systemUser.changeEmail.confirmNewEmail + '] ' +  this._translate.instant('para confirmar a alteração'), this._translate.instant("Atenção"));      
      }

      this.systemUser.changeEmail.changeNewEmail=false;
      this.systemUser.changePassword.resetPassword=false;
      this.isEnabledPassword=false;
      this.isEnabledEmail=false;
    },err=>{
      if(err.status==500){
        this.toastr.errorToastr(this._translate.instant('Erro ao salvar dados.'), this._translate.instant("Erro"));      
      }else{
        this.toastr.warningToastr(this._translate.instant(err.error), this._translate.instant("Atenção"));      
      }      
    });
  }

  checkFields(formData:NgForm){
    formData.controls['emailNewCheck'].markAsTouched()
    formData.controls['confirmNewPassword'].markAsTouched()

    if(this.systemUser.changeEmail){
      if(formData.controls['emailNew'].value!==formData.controls['emailNewCheck'].value){
        formData.controls['emailNewCheck'].setErrors({'incorrect': true});
      }
    }

    if(this.systemUser.changePassword){
      if(formData.controls['newPassword'].value!==formData.controls['confirmNewPassword'].value){
        formData.controls['confirmNewPassword'].setErrors({'incorrect': true});
      }
    }    
  }

  enablePassword(event){
    this.isEnabledPassword = this.systemUser.changePassword.resetPassword;    
  }

  enableEmail(event){
    this.isEnabledEmail = this.systemUser.changeEmail.changeNewEmail; 
  }
}
