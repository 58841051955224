import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Invite } from 'src/app/model/invite.model';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }
  
  sendAllInvites(invites:Invite[]):Observable<Invite[]> {
    return this.http.post<Invite[]>(this.urlAdminBe + "/invite/sendAll", invites)
    .pipe(map(data => data));
  } 

  getAllInvites(range: Date[]):Observable<Invite[]> {
    return this.http.get<Invite[]>(this.urlAdminBe + '/invite/list?initial='+range[0]+'&end='+range[1])
    .pipe(map(data => data));
  }

  getAllInvitesByFilter(range: Date[], field: string, value: string):Observable<Invite[]> {
    return this.http.get<Invite[]>(this.urlAdminBe + '/invite/list?initial='+range[0]+'&end='+range[1]+'&field='+field+'&value='+value)
    .pipe(map(data => data));
  }

  generateLinkUrlPhysician(token){
    return environment.urlBasePixeon +  environment.urlServiceAuth + "/physician/activate?language=pt-BR&token=" + token;
  }
}