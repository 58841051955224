import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslatePipe, TranslateService } from '../locale';
import { AdminBeService } from 'src/services/admin-be.service';
import { Role } from 'src/app/model/Role';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {


    items: MenuItem[];
    menuCompany: MenuItem[];

    constructor(private _translate: TranslateService, private adminBeService: AdminBeService,
        private router: Router) {
    }

    ngOnInit() {
        this._translate.onLangChanged.subscribe(() => {
            this.loadMenuItems();
        });
        this.loadMenuItems();
    }

    loadMenuItems() {
        let user = this.adminBeService.getUser();
        if (user && user.credencial) {
            if (user.credencial.role === Role.manager) {
                this.items = this.contructMenuManager();
            }
            if (user.credencial.role === Role.company) {
                this.items = this.contructMenuCompany(user.credencial);
            }
            if (user.credencial.role === Role.financial) {
                this.items = this.contructMenuFinancial();
            }
            if (user.credencial.role === Role.support) {
                this.items = this.contructMenuSupport();
            }
        }
    }

    getTranslation(item: string): string {
        return this._translate.instant(item);
    }
  

    contructMenuManager() {
        return [
            {
                label: this.getTranslation('Instituições'),
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Procurar'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showInstitutionList(); }
                    },
                    { 
                        label: this.getTranslation('Novo'), 
                        icon: 'pi pi-fw pi-plus',
                        command: (event) => { this.showInstitutionRegister(undefined); } 
                    },
                    { 
                        label: this.getTranslation('Vincular Tipo de Exames'), 
                        icon: 'pi pi-fw pi-plus',
                        command: (event) => { this.showLinkExamType(Role.manager); } 
                    },
                    { 
                        label: this.getTranslation('Notificações Por Email'), 
                        icon: 'pi pi-fw pi-cog',
                        command: (event) => { this.configNotifyEmail(Role.manager); }
                    }
                ]
            },
            {
                label: this.getTranslation('Paciente'),
                visible: false,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Novo'), 
                        icon: 'pi pi-fw pi-plus' 
                    },
                    { 
                        label: this.getTranslation('Editar'), 
                        icon: 'pi pi-fw pi-pencil' 
                    },
                    { 
                        label: this.getTranslation('Deletar'), 
                        icon: 'pi pi-fw pi-trash' 
                    }
                ]
            },
            {
                label: this.getTranslation('Médico'),
                visible: false,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Novo'), 
                        icon: 'pi pi-fw pi-plus' 
                    },
                    { 
                        label: this.getTranslation('Editar'), 
                        icon: 'pi pi-fw pi-pencil' 
                    },
                    { 
                        label: this.getTranslation('Deletar'), 
                        icon: 'pi pi-fw pi-trash' 
                    }
                ]
            },            
            {
                label: this.getTranslation('Protocolos'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Cancelar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.configCancelProtocol(Role.manager); }
                    }
                ]
            },
            {
                label: this.getTranslation('Convites'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Procurar'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showInviteList(); }
                    },
                    { 
                        label: this.getTranslation('Enviar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.createInvites(Role.manager); }
                    }
                ]
            },
            {
                label: this.getTranslation('Usuário'),
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Procurar'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showUsersList(); }
                    },
                    { 
                        label: this.getTranslation('Criar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.showUserForm(undefined); } 
                    }
                ]
            },
            {
                label: this.getTranslation('Monitoramento'),
                visible: false,
                icon: 'pi pi-fw pi-cog',
                command: (event) => { this.showMonitoring(Role.manager); }
            },
            {
                label: this.getTranslation('Relatórios'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                command: (event) => { this.showReports(Role.manager); }
            },
            {
                label: this.getTranslation('LGPD'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Direito a Exclusão'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showLGPDDel(Role.manager); }
                    },
                    { 
                        label: this.getTranslation('Direito a Portabilidade'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showLGPDPortability(Role.manager); }
                    }
                ]
            }
        ];
    }

    contructMenuCompany(credencial: any) {
        return [
            {
                label: this.getTranslation('Instituição'),
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Editar'), 
                        icon: 'pi pi-fw pi-pencil',
                        command: (event) => { this.showInstitutionRegister(credencial); } 
                    },
                    { 
                        label: this.getTranslation('Vincular Tipo de Exames'), 
                        icon: 'pi pi-fw pi-plus',
                        command: (event) => { this.showLinkExamType(Role.company); } 
                    },
                    { 
                        label: this.getTranslation('Notificações Por Email'), 
                        icon: 'pi pi-fw pi-cog',
                        command: (event) => { this.configNotifyEmail(Role.company); }
                    }
                ]
            },            
            {
                label: this.getTranslation('Protocolos'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Cancelar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.configCancelProtocol(Role.company); }
                    }
                ]
            },
            {
                label: this.getTranslation('Grupos'),
                icon: 'pi pi-fw pi-cog',
                command: (event) => { this.showGroupList(); }
            },
            {
                label: this.getTranslation('Convites'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Procurar'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showInviteList(); }
                    },
                    { 
                        label: this.getTranslation('Enviar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.createInvites(Role.company); }
                    }
                ]
            },
            {
                label: this.getTranslation('Bilhetagem'),
                visible: false,
                icon: 'pi pi-fw pi-cog'
            },
            {
                label: this.getTranslation('Usuário'),
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Criar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.showUserForm(undefined); } 
                    }
                ]
            },
            {
                label: this.getTranslation('Monitoramento'),
                visible: false,
                icon: 'pi pi-fw pi-cog',
                command: (event) => { this.showMonitoring(Role.company); }
            },
            {
                label: this.getTranslation('Relatórios'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                command: (event) => { this.showReports(Role.company); }
            }
        ];
    }

    contructMenuFinancial() {
        return [
            {
                label: this.getTranslation('Bilhetagem'),
                icon: 'pi pi-fw pi-cog'
            }
        ];
    }

    contructMenuSupport() {
        return [
            {
                label: this.getTranslation('Instituições'),
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Procurar'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showInstitutionList(); }
                    },
                    { 
                        label: this.getTranslation('Novo'), 
                        icon: 'pi pi-fw pi-plus',
                        command: (event) => { this.showInstitutionRegister(undefined); }  
                    },
                    { 
                        label: this.getTranslation('Vincular Tipo de Exames'), 
                        icon: 'pi pi-fw pi-plus',
                        command: (event) => { this.showLinkExamType(Role.support); } 
                    },
                    { 
                        label: this.getTranslation('Notificações Por Email'), 
                        icon: 'pi pi-fw pi-cog',
                        command: (event) => { this.configNotifyEmail(Role.support); }
                    }
                ]
            },
            {
                label: this.getTranslation('Paciente'),
                visible: false,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Novo'), 
                        icon: 'pi pi-fw pi-plus' 
                    },
                    { 
                        label: this.getTranslation('Editar'), 
                        icon: 'pi pi-fw pi-pencil' 
                    },
                    { 
                        label: this.getTranslation('Deletar'), 
                        icon: 'pi pi-fw pi-trash' 
                    }
                ]
            },
            {
                label: this.getTranslation('Médico'),
                visible: false,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Novo'), 
                        icon: 'pi pi-fw pi-plus' 
                    },
                    { 
                        label: this.getTranslation('Editar'), 
                        icon: 'pi pi-fw pi-pencil' 
                    },
                    { 
                        label: this.getTranslation('Deletar'), 
                        icon: 'pi pi-fw pi-trash' 
                    }
                ]
            },            
            {
                label: this.getTranslation('Protocolos'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Cancelar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.configCancelProtocol(Role.support); }
                    }
                ]
            },
            {
                label: this.getTranslation('Convites'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Procurar'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showInviteList(); }
                    },
                    { 
                        label: this.getTranslation('Enviar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.createInvites(Role.support); }
                    }
                ]
            },
            {
                label: this.getTranslation('Usuário'),
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Procurar'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showUsersList(); }
                    },
                    { 
                        label: this.getTranslation('Criar'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: (event) => { this.showUserForm(undefined); } 
                    }
                ]
            },
            {
                label: this.getTranslation('Monitoramento'),
                visible: false,
                icon: 'pi pi-fw pi-cog',
                command: (event) => { this.showMonitoring(Role.support); }
            },
            {
                label: this.getTranslation('Relatórios'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                command: (event) => { this.showReports(Role.support); }
            },
            {
                label: this.getTranslation('LGPD'),
                visible: true,
                icon: 'pi pi-fw pi-cog',                
                items: [
                    { 
                        label: this.getTranslation('Direito a Exclusão'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showLGPDDel(Role.support); }
                    },
                    { 
                        label: this.getTranslation('Direito a Portabilidade'), 
                        icon: 'pi pi-fw pi-search',
                        command: (event) => { this.showLGPDPortability(Role.support); }
                    }
                ]                
            },
            {
                label: this.getTranslation('Termos'),
                visible: true,
                icon: 'pi pi-fw pi-cog',
                items: [
                    { 
                        label: this.getTranslation('Termos de Serviço'), 
                        icon: 'pi pi-fw pi-search',
                        command: () => { this.showTermsOfService(Role.support); }
                    },
                    { 
                        label: this.getTranslation('Política de Privacidade'), 
                        icon: 'pi pi-fw pi-external-link',
                        command: () => { this.showPrivacyPolicy(Role.support); } 
                    }
                ]
            },
            {
                label: this.getTranslation('Acessar nova interface'),
                visible: true,
                icon: 'pi pi-fw pi-external-link',
                command: () => this.redirectToNewInterface(Role.support),
                styleClass: 'blue-item'
            }
        ];
    }

    configCancelProtocol(role:Role){
        this.router.navigate(['cancelprotocols',{role:role.toString()}]);
    }

    configNotifyEmail(role:Role){
        this.router.navigate(['notifyemails',{role:role.toString()}]);
    }

    createInvites(role:Role){
        this.router.navigate(['invite',{role:role.toString()}]);
    }

    showMonitoring(role:Role){
        this.router.navigate(['monitoring',{role:role.toString()}]);
    }
    
    showReports(role:Role){
        this.router.navigate(['reports',{role:role.toString()}]);
    }

    showLGPDDel(role:Role){
        this.router.navigate(['lgpd-delete',{role:role.toString()}]);
    }    

    showLGPDPortability(role:Role){
        this.router.navigate(['lgpd-portability',{role:role.toString()}]);
    }

    showLinkExamType(role:Role){
        this.router.navigate(['examTypes',{role:role.toString()}]);
    }

    showInstitutionList(){
        this.router.navigate(['home']);
    }
    showInviteList() {
        this.router.navigate(['searchInvites']);
    }

    showUsersList(){
        this.router.navigate(['searchUsers']);
    }

    showInstitutionRegister(credencial: any){
        if (credencial && credencial.inst)
            this.router.navigate(['institutionRegister/' + credencial.inst]);    
        else {    
            this.router.navigate(['institutionRegister']);    
        }
    }

    showGroupList(){
        this.router.navigate(['groupList']);
    }

    showUserForm(credencial:any){
        this.router.navigate(['userForm']);
    }

    showTermsOfService(role: Role) {
        this.router.navigate(['termsOfService', {role: role.toString()}]);
    }

    showPrivacyPolicy(role: Role) {
        this.router.navigate(['privacyPolicy', {role: role.toString()}]);
    }

    redirectToNewInterface(role: Role) {
        let externalLink: string;
        if (role === Role.support) {
        externalLink = environment.urlBaseFeV2;
        }

        if (externalLink) {
            window.open(externalLink, '_blank')
        }
    }
}
