import { City } from './city.model';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';

export class Address {
    id:number;
    address:string;
    addressDetails:string;
    addressNumber:string;
    neighborhood:string;
    zipPostCode:string;
    version:number;
    city: City;

    constructor() {
        this.city = new City();
    }
}