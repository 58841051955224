export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
    'Entrar': 'Entrar',
    'Sair': 'Salir',
    'Minha_conta': 'Mi Cuenta',
    'Usuario': 'Usuario',
    'Senha': 'contraseña',
    'Acessar':'Acessar',
    'Informe o usuário': 'Informar al usuario',
    'Bem vindo ao novo Clickvita Admin!': 'Bienvenido al nuevo administrador de Clickvita!',

    //Menu Items
    'Gerenciamento de Instituições': 'Gerencia Institucional',
    'Novo':'Nuevo',
    'Filtro':'Filtro',
    'Filtro Geral':'Filtro general',
    'Abrir':'Open',
    'Resultados':'Resultados',
    'Deletar':'Deletar',
    'Atualizar':'Atualizar',
    'Cadastro':'Cadastro',
    'Instituição':'Institución',
    'Instituições': 'Instituciones',
    'Médico':'Médico',
    'Paciente':'Paciente',
    'Buscar':'Buscar',
    'Relatórios':'Relatórios',
    'Editar':'Editar',
    'Salvar':'Salvar',
    'Outros':'Outros',
    'Apagar':'Apagar',

    'Relatorio': 'Informe',
    'Grupos'   : 'Grupos',

    'suporte': 'No sé',
    'ID': 'ID',

    //institution register
    'Cadastro da Instituição': 'Registro de instituciones',
    'Nome': 'Nome',
    'Token': 'Token',
    'Razão Social': 'Razón social',
    'CNPJ': 'CNPJ',
    'Website': 'Website',
    'Rua': 'Calle',
    'Complemento': 'Complemento',
    'Número': 'Número',
    'CEP': 'Código Postal',
    'Bairro': 'Bairro',
    'Cidade': 'Ciudad',
    'Latitude': 'Latitud',
    'Longitude': 'Longitud',
    'Nome da instituição é obrigatório': 'Se requiere el nombre de la institución',
    'Token é obrigatório': 'Se requiere Token',
    'Razão é obrigatório': 'Se requiere el nombre de la compañía',
    'CNPJ é obrigatório': 'Se requiere CNPJ',
    'Selecione um País': 'Selecciona un pais',
    'Selecione o Estado': 'Seleciona un Estado',
    'Selecione a Cidade': 'Seleciona un ciudad',
    'Rua é obrigatório': 'Se requiere calle',
    'Número é obrigatório': 'Numero es requerido',
    'CEP é obrigatório': 'Se requiere código postal',
    'Bairro é obrigatório':'Se requiere vecindario',
    'CNPJ tem 14 digitos': 'CNPJ tiene 14 digitos',
    'A instituição foi gravada': 'La institución fue grabada',
    'Sucesso!': 'Exito!',
    'Erro: A instituição não foi gravada': 'Error: institución no grabado',
    'Erro': 'Error',
    'Erro: CNPJ inválido': 'Error: CNPJ no válido',
    'Erro: País, Estado e Cidade são obrigatórios': 'Erro: Se requiere país, estado y ciudad',

    //Grupo register
    'Código': 'Código',
    'Descrição': 'Descripción',
    'Ativo':'Activo',
    'Cancelar': 'Cancelar',
    'Não': 'No',
    'Sim': 'Si',
    'Tem certeza que deseja desativar o grupo': '¿Está seguro de que desea deshabilitar el grupo?',
    'Erro: Não é possível alterar. O Grupo está vinculado a pelo menos um exame!':'Erro: no se puede cambiar. El Grupo está obligado por al menos un examen.!',
    'Todos os campos são obrigatórios':'Todos los campos son obligatorios',
    'O Grupo foi salvo':'El grupo ha sido guardado',
    'Erro: O Grupo não foi salvo':'Erro: el grupo no se guardó',
    'Erro: O Grupo não foi salvo, já existe um código com este nome.':'Erro: el grupo no se ha guardado, ya existe un código con este nombre.',

    //Group Users
    'Usuários do Grupo':'Usuarios grupales',
    'Usuário Email':'Email del usuario',
    'Necessário informar o email do usuário!':'¡Necesito ingresar el correo electrónico del usuario!',
    'Usuário adicionado ao grupo com sucesso!':'¡Usuario agregado con éxito al grupo!',
    'Erro: Email do usuário deve ser válido e necessário ser Empresa ou Médico!':'Erro: ¡El correo electrónico del usuario debe ser válido y debe ser de la empresa o médico!',
    'Erro Inclusão':'Inclusión de error',
    'Usuário removido do grupo com sucesso!':'¡Usuario eliminado del grupo con éxito!',
    'Deletar Usuário':'Eliminar usuario',
    'Remover Usuário Grupo':'Eliminar grupo de usuarios',
    'Adicionar Usuário':'Agregar usuario',
    'Email já está vinculado no grupo!':'¡El correo electrónico ya está vinculado en el grupo!',
    'Nenhum usuário adicionado ao grupo!':'¡Ningún usuario agregado al grupo!',
    'Incluir Usuário Grupo':'Agregar grupo de usuarios',
    'Tem certeza que deseja remover o usuário do grupo':'¿Estás seguro de que deseas eliminar al usuario del grupo',

    //Register User
    'Usuário Externo':'Usuário Externo',
    'Financeiro':'Financiera',
    'Clínica': 'Clínica',
    'Suporte': 'Soporte',
    'Administrador': 'Administrador',
    'Tipo do usuário necessário!':'Tipo de usuario requerido!',
    'Usuário criado com sucesso!':'Usuario creado con éxito!',
    'Usuário alterado com sucesso!': 'El usuario ha cambiado correctamente!',
    'Erro: Email do usuário já está cadastrado.':'Error: el correo electrónico del usuario ya está registrado.',
    'Erro: Usuário não pode ser salvo, verifique os campos!':'Error: no se pudo guardar el usuario, ¡compruebe los campos!',
    'Nome Empresa':'Nombre de la compañia',
    'Nome da empresa é obrigatório':'Nombre de la empresa es obligatorio',
    'Email':'Correo electrónico',
    'Email da empresa é obrigatório':'Se requiere correo electrónico de la empresa',
    'Informar a Instituição é obrigatório!': 'Se requiere informar a la institución!',
    'Tipo Usuário':'Tipo de usuario',
    'Tipo Usuário é obrigatório':'Tipo Usuario requerido',
    'Nome da Instituição': 'Nombre de la institución',
    'Desativado': 'Apagado',
    'Situação do usuário alterado!': 'La situación del usuario cambiado',
    'A alteração da situação do usuário falhou!': 'Cambio de situación del usuario fallido!',

    //User list
    'COMPANY': 'Clínica',
    'MANAGER': 'Gerente',
    'FINANCIAL': 'Financiera',
    'EXTERNAL': 'Externo',
    'SUPPORT': 'Soporte',

    //CustomLoginPage Institution
    'Código é obrigatório':'El código es obligatorio',
    'Endereço da Logo':'Dirección del logotipo',
    'Email para Suporte':'Soporte por correo electrónico',
    'Telefone para Suporte':'Teléfono de soporte',
    'WhatsApp para Suporte':'WhatsApp para soporte',
    'Tipo de Página':'Tipo de Pagina',
    'Com logo e fundo branco':'Con logotipo y fondo blanco',
    'Sem logo e fundo padrão':'Sin logotipo y fondo predeterminado',
    'A página personalizada foi gravada':'Se ha guardado la página personalizada.',
    'Erro: A página personalizada não foi gravada':'Error: página personalizada no guardada',

    //Settings Institution
    'Tipo':'Tipo',
    'Valor':'Valor',
    'Unit Id Instituição':'Unidad ID Institución',
    'Remover Configuração':'Eliminar configuración',
    'Tem certeza que deseja remover a configuração da instituição':'¿Está seguro de que desea eliminar la configuración de la institución',
    'Configuração removida da instituição!':'¡Configuración eliminada de la institución!',
    'Erro: Necessário selecionar o tipo de configuração':'Error: es necesario seleccionar el tipo de configuración',
    'Erro: Necessário informar o valor da configuração':'Error: es necesario ingresar el valor de configuración',
    'Erro: Essa configuração já está vinculada na instituição':'Error: esta configuración ya está vinculada en la institución',
    'A configuração foi salva':'La configuración ha sido guardada',
    'Erro: Configuração não foi salva, verifique os campos':'Error: configuración no guardada, verificar campos',
    'Nenhuma configuração para a instituição!':'¡Sin configuración para la institución!',

    'Somente imagens são suportadas':'Solo se admiten imágenes.',
    'Selecione uma logo':'Seleccione un logo',
    'Carregar a Logo': 'Sube el logo',
    'A imagem foi salva com sucesso': 'La imagen se ha guardado correctamente',
    'Erro ao tentar salvar a imagem': 'Error al intentar guardar la imagen',
    'Selecionar Arquivo': 'Seleccionar archivo',
    'Ativado': 'Activado',
    'Selecione': 'Seleccione',
    'Selecione a versão': 'Seleccionar versión',
    'Selecione o valor': 'Seleccionar valor',
    'Selecione o sistema': 'Seleccionar sistema',

    //Session Checker
    'Sessão':'Sesión',
    'Sessão encerrada devido a inatividade.': 'Sesión finalizada debido a la inactividad.',
    'Sua sessão irá expirar em ':'Su sesión expirará el',
    'minuto(s). Clique aqui para renovar.':'minuto (s) Haga clic aquí para renovar.',
    'Sua sessão foi renovada':'Su sesión ha sido renovada',
    'Renovar':'Renovar',

    //Invite
    'NOT_SENT':'NO ENVIADO',
    'SENT':'ENVIADO',
    'INVALID_EMAIL':'CORREO ELECTRÓNICO NO VÁLIDO',
    'INVALID_CRM':'CRM NO VÁLIDO',
    'INVALID_TOKEN':'TOKEN NO VÁLIDO',
    'EMAIL_REGISTERED':'CORREO ELECTRÓNICO YA REGISTRADO',

    'CREATED':'Aceptado',
    'INVITE_SEND':'Enviado por un Administrador',
    'AUTO_INVITE':'Enviado automáticamente',
    'ON_CREATION':'Procesando',
    'INVALID': 'Inválido',

    'Reenviar convites': 'Reenviar convites',
    'Período:': 'Período:',
    'Convites': 'Convites',
    'CRM': 'CRM',
    'Data': 'Fecha',
    'Validade': 'Validez',
    'Situação': 'Situación',
    'Enviando Convites':'Envío de invitaciones',
    'Erro: Os convites não foram processados':'Error: no se procesaron las invitaciones',
    'Convites processados, veja coluna status para mais detalhes':'Invitaciones procesadas, ver columna de estado para más detalles',
    'PHYSICIAN_INVITE_ALERT': 'Es importante recordar siempre su responsabilidad al invitar a nuevos usuarios.\nDe acuerdo con la Ley General de Protección de Datos, solo las personas autorizadas y estrictamente necesarias deben tener acceso a los datos personales y datos personales sensibles almacenados en la plataforma, para cumplir el propósito previsto entre las partes (usted y Pixeon) y los pacientes.\nAsegúrese de que el usuario sea parte de su institución y pueda acceder a la plataforma.',
    'PHYSICIAN_INVITE_CONSENT': 'Leí, acepto y deseo continuar con el envío de la invitación al usuario.',

    //Units
    'ID Unidade':'ID Unidad',
    'Endereço':'Dirección',
    'País':'Pais',
    'Estado':'Estado',
    'Nenhuma unidade para a instituição!':'¡No hay unidades para la institución!',
    'Os dados da unidade foram salvos!':'¡Se han guardado los datos de la unidad!',
    'Erro: Unidade não foi salva, verifique os campos':'Error: unidad no guardada, verifique los campos',

    //ExamTypeInstitution association
    'O tipo do exame foi salvo e vinculado':'Tipo de examen guardado y vinculado',
    'Erro: O tipo do exame não foi salvo':'Error: Tipo de examen no guardado',
    'Vincular Tipos de Exames':'Tipos de examen de enlaces',
    'Código Instituição':'Código de institución',
    'Descrição Instituição':'Descripción de la institución',
    'Código Clickvita':'Código Clickvita',
    'Descrição Clickvita':'Descripción Clickvita',
    'Nenhum tipo de exame para a instituição':'No hay exámenes para la institución.',

    //NotifyEmails
    'Nenhuma notificação de email para a instituição!':'No hay notificaciones por correo electrónico para la institución',
    'Tem certeza que deseja remover a notificação da instituição ':'¿Está seguro de que desea eliminar la notificación de la institución? ',
    'Tem certeza que deseja remover a ':'¿Está seguro de que desea eliminar la ',
    ' da instituição?':' de la institución?',
    'Configuração de Notificações Email':'Configuración de notificaciones por correo electrónico',
    'Erro: Essa notificação já está vinculada na instituição':'Error: esta notificación ya está vinculada en la institución',
    'Notificação removida da instituição!':'Notificación eliminada de la institución!',
    'Erro: Necessário informar o valor da notificação':'Error: es necesario ingresar el valor de notificación',
    'A notificação foi salva':'Se ha guardado la notificación.',
    'Erro: Notificação não foi salva, verifique os campos':'Error: notificación no guardada, verificar campos',
    'Remover Notificação':'Eliminar notificación',
    'Notificações Por Email':'Notificaciones por correo electrónico',

    //Monitoring
    'RECEIVED':'RECIBIDO',
    'REJECTED':'RECHAZADO',
    'PROCESSING':'PROCESAMIENTO',
    'PROCESSED':'PROCESADO',
    'OK':'OK',
    'ERROR':'ERROR',
    'WARNING':'ALERTA',
    ' há ':' hace ',
    ' minutos':' minutos',
    ' dias':' dias',
    ' horas': ' horas',
    'Monitoramento de Mensagens':'Monitoreo de mensajes',
    'Mensagens Integradas: ':'Mensajes integrados: ',
    'Mensagens Processadas: ':'Mensajes procesados: ',
    ' mensagens/hora':' mensajes/hora',
    'Selecione o Contrato':'Seleccione el contrato',
    'Selecione o Status':'Seleccionar estado',
    'Selecione o Tipo':'Seleccione tipo',
    'Protocolo':'Protocolo',
    'Contrato':'Contrato',
    'Status':'Estado',
    'Validação':'Validación',
    'Data de Integração':'Fecha de integración',
    'Monitoramento':'Monitoreo',
    'Buscando Integrações':'Buscando integraciones',
    'Nenhuma integração encontrada!':'¡No se encontró integración!',
    'Nenhum alerta foi gerado para a mensagem':'No se generaron alertas para el mensaje.',
    'EXPECTED_TYPE_NUMBER':'Número de tipo esperado',
    'EXPECTED_MIN_LENGHT':'Tamaño mínimo esperado',
    'EXPECTED_TYPE_STRING':'Tipo de texto esperado',
    'DOES_NOT_MATCH_PATTERN':'El tipo enviado no se ajusta al formato',
    'REQUIRED_KEY':'Campo requerido',
    'IS_NOT_VALID_TYPE':'Tipo inválido',
    'EXPECTED_TYPE_OBJECT':'Tipo de objeto esperado',

    'Protocolos':'Protocolos',
    'Cancelar Protocolo':'Cancelar Protocolo',
    'Protocolo é obrigatório':'El protocolo es obligatorio',
    'Pesquisar':'Buscar',
    'Cancelar Protocolos':'Cancelar Protocolos',
    'Ativar Protocolos':'Habilitar protocolos',
    'Data Entrega':'Fecha de entrega',
    'Solicitante':'Solicitante',
    'Visualizado?':'¿Visto?',
    'Ativo?':'¿Activo?',
    'Nenhum exame vinculado ao protocolo!':'¡Ningún examen vinculado al protocolo!',
    'Nenhum protocolo a ser processado!':'¡Ningún protocolo para ser procesado!',
    'Tem certeza que deseja cancelar todos os protocolos e exames selecionados?':'¿Está seguro de que desea cancelar todos los protocolos y exámenes seleccionados?',
    'Tem certeza que deseja ativar todos os protocolos e exames selecionados?':'¿Está seguro de que desea activar todos los protocolos y exámenes seleccionados?',
    'Tem certeza que deseja apagar todos os protocolos e exames selecionados?':'¿Está seguro de que desea eliminar todos los protocolos y exámenes seleccionados?',
    'Esta ação não poderá ser desfeita!':'¡Esta acción no se puede deshacer!',
    'Processando Protocolos/Exames selecionados.':'Procesamiento de protocolos / exámenes seleccionados.',
    'Protocolos/Exames processados com sucesso!':'¡Protocolos / exámenes procesados ​​correctamente!',
    'Protocolo já está inserido na lista':'El protocolo ya está insertado en la lista',
    'Protocolo não encontrado':'Protocolo no encontrado',
    'Procurando Protocolo':'Protocolo de búsqueda',
    'Deletando Protocolos/Exames selecionados.':'Eliminación de protocolos / exámenes seleccionados.',
    'Error ao apagar dados selecionados!':'¡Error al borrar los datos seleccionados!',
    'Última Atualização':'Última actualización',

    //Reports
    'Os relatórios do ClickVita 2 são gerados a partir do metabase. Para solicitar seu acesso entre em contato com o suporte da Pixeon.':'Los informes de ClickVita 2 se generan a partir de la metabase. Para solicitar su acceso, comuníquese con el soporte de Pixeon.',
    'Assim que você receber o seu acesso entre com as credenciais em ':'Tan pronto como reciba su acceso, ingrese sus credenciales en ',

    //Account Data
    'clickvitaApp.actual_password_not_informed':'Contraseña no informada',
    'clickvitaApp.actual_password_incorrect':'Contraseña actual incorrecta',
    'clickvitaApp.new_email_is_being_used':'El nuevo correo electrónico ya está en uso',
    'Email é obrigatório':'correo electronico es requerido',
    'Informe a senha atual':'Ingrese la contraseña actual',
    'Senha atual é obrigatória':'Se requiere contraseña actual',
    'Novo Email':'Nuevo Email',
    'Novo Email é obrigatório':'Se requiere un nuevo correo electrónico',
    'Repita Novo Email':'Repetir nuevo correo electrónico',
    'Novo Email deve ser igual':'El nuevo correo electrónico debe ser el mismo',
    'Senha Atual':'Contraseña actual',
    'Nova Senha':'Nueva contraseña',
    'Nova Senha é obrigatória':'Se requiere nueva contraseña',
    'Repita a Nova Senha':'Repite la nueva contraseña',
    'Nova Senha deve ser igual':'La nueva contraseña debe ser la misma',
    'Os dados foram salvos com sucesso.':'Los datos se han guardado correctamente.',
    'Verifique sua caixa de email':'Revisa tu correo',
    'para confirmar a alteração':'para confirmar el cambio',
    'Atenção':'Aviso',
    'Erro ao salvar dados.':'Error al guardar los datos.',
    'USER_CREATE_ALERT': 'Es importante recordar siempre su responsabilidad al crear nuevos usuarios.\nDe acuerdo con la Ley General de Protección de Datos, solo las personas autorizadas y estrictamente necesarias deben tener acceso a los datos personales y datos personales sensibles almacenados en la plataforma, para cumplir el propósito previsto entre las partes (usted y Pixeon) y los pacientes.\nAsegúrese de que el usuario sea parte de su institución y pueda acceder a la plataforma.',
    'USER_CREATE_CONSENT': 'Leí, acepto y deseo continuar con la creación de usuarios.',

    //Termos
    'Termos': 'Condiciones',
    'Termos de Serviço': 'Condiciones de Servicio',
    'Termos de serviço atualizados com sucesso!': '¡Términos de servicio actualizados con éxito!',
    'Erro: Não foi possível salvar os novos termos de serviço.': 'Error: no se pueden guardar los nuevos términos de servicio.',
    'Mensagem de confirmação dos termos de serviço 1': '¿Está seguro de que desea actualizar las condiciones del servicio?',
    'Mensagem de confirmação dos termos de serviço 2': 'Todos los usuarios deben aceptar las condiciones en el próximo inicio de sesión.',
    'Política de Privacidade': 'Política de Privacidad',
    'Política de privacidade atualizada com sucesso!': '¡Política de privacidad actualizada con éxito!',
    'Erro: Não foi possível salvar a nova política de privacidade.': 'Error: no se puede guardar la nueva política de privacidad.',
    'Mensagem de confirmação da política de privacidade 1': '¿Está seguro de que desea actualizar la política de privacidad?',
    'Mensagem de confirmação da política de privacidade 2': 'Todos los usuarios deben aceptar la nueva política en el próximo inicio de sesión.',

    //Permission Delete LGPD
    'Dados removidos com sucesso!':'¡Datos eliminados correctamente!',
    'Dados Copiados!':'¡Datos copiados!',
    'Copiar AccessCodes':'Copiar AccessCodes',
    'Remover dados usuários':'Eliminar datos de usuario',
    'Dados não encontrados':'Datos no encontrados',
    'Procurando Todos os vínculos do usuário':'Búsqueda de todos los enlaces de usuario',
    'Direito a Exclusão':'Derecho a la exclusión',
    'Email de Cadastro é obrigatório':'El correo electrónico de registro es obligatorio',
    'Email Cadastro':'Registro de correo electrónico',
    'Apagar todos os dados do usuário':'Eliminar todos los datos del usuario',
    'Confirma a exclusão de todos os dados deste usuário?':'¿Confirmar la eliminación de todos los datos de este usuario?',
    'Lembre de copiar todos os accessCode para enviar ao time do PACS afim de remover as imagens também.':'Recuerde copiar todo el código de acceso para enviar al equipo de PACS para eliminar las imágenes también.',
    'Removendo todos os dados do usuário...':'Eliminando todos los datos del usuario ...'
}
