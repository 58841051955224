import { Email } from "./emailInterface";
import { Password } from "./passwordInterface";
import { UserModel } from "./userModel";

export class MyAccount extends UserModel{
    email:string;
    
    changePassword: Password = {resetPassword:false,confirmNewPassword:"",currentpassword:"",newPassword:""};
    changeEmail: Email = {changeNewEmail:false, confirmNewEmail:"", currentPassword:"", newEmail:""};

    language:String;    

    constructor() {
        super();

        this.changeEmail.changeNewEmail = false;        
        this.changePassword.resetPassword = false;
    }
}