import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { AdminBeService } from 'src/services';
import { AddressService } from 'src/services/address.service';
import { TranslateService } from '../../common/locale';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-lgdp',
  templateUrl: './lgpd-delete.component.html',
  styleUrls: ['./lgpd-delete.component.css']
})
export class LgpdDeleteComponent implements OnInit {

  msgSpinnerProcess:string = '';
  emailUser:string;
  responseData: any=null;
  displayDialogDelete:boolean=false;

  constructor(private _translate: TranslateService, private route: ActivatedRoute, private toastr: ToastrManager, 
    private adminBeService: AdminBeService, private addressService: AddressService, 
    private router: Router, private userService: UserService, private spinner: NgxSpinnerService){

    }

  ngOnInit() {
  }

  getDataUser(){
    

    this.msgSpinnerProcess = 'Procurando Todos os vínculos do usuário';
    this.spinner.show();

    this.userService.getLgpdDataUser(this.emailUser).subscribe(data => {      

      this.responseData = data;

      this.spinner.hide();
    },error =>{

      if(error.status==404){
        this.toastr.warningToastr(this._translate.instant("Dados não encontrados"), this._translate.instant("Erro"));
      }else{
        this.toastr.errorToastr(error.message, this._translate.instant("Erro"));
      }

      this.spinner.hide();

    });

  }

  copyAccessCodes(){
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.responseData.accessCodes));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.successToastr(this._translate.instant("Dados Copiados!"), this._translate.instant("Copiar AccessCodes"));
  }

  showDialogDelete(){
    this.displayDialogDelete = true;
  }

  cancelDialog(){   
    this.displayDialogDelete = false;
  }

  confirmRemove(){

    this.cancelDialog();

    if(this.responseData){
      this.msgSpinnerProcess="Removendo todos os dados do usuário...";
      this.spinner.show();

      this.userService.removeLgpdDataUser(this.emailUser).subscribe(data => {      
        this.toastr.successToastr(this._translate.instant("Dados removidos com sucesso!"), this._translate.instant("Remover dados usuários"));

        this.spinner.hide();
        this.responseData=null;
        this.emailUser='';
        
      },error =>{
  
        if(error.status==404){
          this.toastr.warningToastr(this._translate.instant("Dados não encontrados"), this._translate.instant("Erro"));
        }else{
          this.toastr.errorToastr(error.message, this._translate.instant("Erro"));
        }
  
        this.spinner.hide();
  
      });
    }
  }

  getMsgSpinner(){
    return this.msgSpinnerProcess;
  }

}
