import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LgpdService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  getDataUser(number:string):Observable<any> {
    return this.http.get<Request>(this.urlAdminBe + '/lgpd/'+number)
    .pipe(map(data => data));
  }

}
