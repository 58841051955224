import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserModel } from 'src/app/model/userModel';
import { SelectItem } from 'primeng/api';
import { InviteService } from 'src/services/invite.service';
import { Invite } from 'src/app/model/invite.model';
import { Table } from 'primeng/table';
import { TranslateService } from '../../common/locale';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-invite-list',
  templateUrl: './invite-list.component.html',
  styleUrls: ['./invite-list.component.css']
})
export class InviteListComponent implements OnInit {

  @ViewChild('dataTable', {static:false}) dataTable: Table;

  invites: Array<any>;
  selectedInvites: Array<any>;

  name: string;
  email: string;

  first: number = 0;

  cols: any[];
  cols2: any[];
  totalRecords = 0;
  page = 1;
  pageSize = 15;

  fieldSearch: string;
  value: string;

  rangeDates: Date[];
  loading: boolean;


  constructor(private inviteService: InviteService, private translate: TranslateService, private router: Router,
    private activatedRoute: ActivatedRoute, private toastr: ToastrManager) {
    this.loading = false;
   }

  ngOnInit() {
    this.translate.onLangChanged.subscribe(() => {
      this.prepareColumns();
    });
    this.prepareColumns();
    this.value = '';

    let end = new Date();
    let initial = new Date();
    initial.setMonth(initial.getMonth() - 3);

    this.rangeDates = [];

    this.rangeDates.push(initial);
    this.rangeDates.push(end);

    this.activatedRoute.params.subscribe(
      params => {
        if (params['pg']) {
          this.page = params['pg'];

          if (params['value'] && params['value'] !== "") {
            this.value = params['value'];
          }
          if (params['field']) {
            this.fieldSearch = params['field'];
            this.setFieldSearchPreviusValue();
          }
          if (params['initial']) {
            this.rangeDates = [];
            this.rangeDates.push(new Date(params['initial']));
            this.rangeDates.push(new Date(params['end']));
          }
        }

         this.getInvites();

    });


  }

  prepareColumns() {
    this.cols = [
      { field: 'id', header: this.translate.instant('ID'), width: "5%" },
      { field: 'name', header: this.translate.instant('Médico'), width: "23%" },
      { field: 'email', header: this.translate.instant('E-mail'), width: "30%" },
      { field: 'crm', header: this.translate.instant('CRM'), width: "10%" },
      { field: 'createdAt', header: this.translate.instant('Data'), width: "15%" },
      { field: 'timeExpire', header: this.translate.instant('Validade'), width: "7%" },
      { field: 'type', header: this.translate.instant('Situação'), width: "15%" },
      { field: 'link', header: this.translate.instant('Link Convite'), width: "7%" }

    ];
  }

  setFieldSearchPreviusValue() {
    if (this.fieldSearch === 'name') {
      this.name = this.value;
    }
    if (this.fieldSearch === 'email') {
      this.email = this.value.trim();
    }
  }

  cleanFilterFields() {
    this.name = '';
    this.email = '';
  }

  getInvites() {
    if (this.rangeDates[1] === null) {
      return;
    }

    if (this.value === '') {
      this.loading = true;
      this.inviteService.getAllInvites(this.rangeDates).subscribe(data => {
        this.loadData(data);
      });
    } else {
      this.loading = true;
      this.inviteService.getAllInvitesByFilter(this.rangeDates, this.fieldSearch, this.value.trim() ).subscribe(data => {
        this.loadData(data);
      });
    }

  }

  loadData(data: any) {
    let invitesArray = [];
    data.forEach(function(item:any) {
      let invite = {
          id: item.id,
          name: item.physician.name,
          email: typeof item.physician.email === 'string' ? item.physician.email.trim() : item.physician.email,
          crm: item.physician.crm,
          state: item.physician.state.abbreviation,
          stateId: item.physician.state.id,
          token: item.token,
          createdAt: item.generatedDate,
          timeExpire: item.timeToExpire,
          active: item.isEnable,
          type: item.inviteType,
          institutionId: item.institution.id,
          institutionName: item.institution.name
        };
        invitesArray.push(invite);
      });
    this.invites = invitesArray;
    this.totalRecords = data['totalElements'];
    this.setCurrentPage(this.page);
    this.loading = false;
  }

  setCurrentPage(n: number) {
    this.dataTable.first = ((n - 1) * this.dataTable.rows);
   }

  paginate(event: any) {
    this.page = event.first/event.rows + 1;;
    this.getInvites();
  }

  search(event: any) {
    this.selectedInvites = [];
    this.fieldSearch = event.currentTarget.id;
    this.value = event.currentTarget.value;
    this.cleanFilterFields();
    this.setFieldSearchPreviusValue();
    this.page = 1;
    this.getInvites();
  }

  checkInviteExpirationAndTypeValid(invite){
    let invitesValids:Array<string> = ['AUTO_INVITE','ON_CREATION','INVITE_SEND'];

    if(invitesValids.includes(invite.type) && !(invite.timeExpire==='0')){
      return true;
    }

    return false;
  }

  copyLinkUrl(invite){

    let urlInvite:string = this.inviteService.generateLinkUrlPhysician(invite.token);

    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (urlInvite));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.toastr.successToastr(this.translate.instant("Link Copiado!"), this.translate.instant("Copiar Link"));
  }

  sendSelectedInvites() {

   let inv= JSON.stringify((
      {list: this.selectedInvites}
    ))
    this.router.navigate(['invite', {
      pg: this.page,
      fieldSearch: this.fieldSearch,
      value: this.value,
      intial: this.rangeDates[0],
      end: this.rangeDates[1],
      invites: inv
    }]);
  }

  isCheckBoxDisabled(selection: any) {
    if (selection !== undefined) {
       for (let i = selection.length - 1; i >= 0; i--) {
        let data = selection[i];
        if (data.type === "CREATED") {
          selection.splice(i, 1);
        }
      }
    }
  }

}
