import { Injectable } from '@angular/core';
import { AuthenticationService } from './authenticationservice.service';
import { AutorizationUser } from 'src/model/autorization.model';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable({
  providedIn: 'root'
})
export class AdminBeService {

  autorization:AutorizationUser;
  
  constructor(private authentication:AuthenticationService, private router: Router,  private toastr: ToastrManager) {
  }

  async loginMigrator(username:string,password:string, isModalLogin:boolean){
    
    let loginSuccess:boolean=false;

    await this.authentication.login(username,password).toPromise().then(reponse=>{
        this.autorization = reponse;
        if (this.autorization !== undefined && this.autorization.response.status === 200 && !isModalLogin) {
          this.router.navigateByUrl("/home");
        }

        loginSuccess = true;
    }).catch(error => {
        this.toastr.errorToastr("Usuário ou senha inválidos!", "Aviso");
        console.log("Usuário ou senha inválido")  

        loginSuccess = false;
    });

    return loginSuccess;
  }

  getUser():AutorizationUser{
    if (this.authentication.getUser() !== null) {
      this.autorization = JSON.parse(localStorage.getItem('authorizationUser'));
    }
    return this.autorization;
  }
  getToken():string{
    return this.authentication.getToken();
  }

  logout() {
    this.autorization = undefined;
    this.authentication.logout();
    this.router.navigateByUrl("/login");
  }
}
