import { State } from './state.model';

export class City {
    id:number;
    name:string;
    state: State;
    version:number;

    constructor() {
        this.state = new State();
    }
}