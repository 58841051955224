import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { Role } from './model/Role';
import { RegistrationFormComponent } from './fe/institution/form/registration-form/registration-form.component';
import { GroupListComponent } from './fe/group/group-list/group-list.component';
import { UsersGroupListComponent } from './fe/group/users-group-list/users-group-list.component';
import { RegistrationUserFormComponent } from './fe/user/form/registration-user-form/registration-user-form.component';
import { LoginHomeComponent } from './fe/auth/login-home/login-home.component';
import { UserListComponent } from './fe/user/user-list/user-list.component';
import { InviteComponent } from './fe/physician/invite/invite.component';
import { InviteListComponent } from './fe/physician/invite-list/invite-list.component';
import { ExamTypesComponent } from './fe/institution/exam-types/exam-types.component';
import { NotifyEmailComponent } from './fe/notifyemail/notifyemail.component';
import { MonitoringComponent } from './fe/monitoring/monitoring.component';
import { ProtocolsComponent } from './fe/protocols/protocols.component';
import { ReportsComponent } from './fe/reports/reports.component';
import { AccountComponent } from './fe/account/account.component';
import { TosComponent } from './terms/tos/tos.component';
import { PrivacyPolicyComponent } from './terms/privacy-policy/privacy-policy.component';
import { LgpdDeleteComponent } from './fe/lgpd/delete-data/lgpd-delete.component';
import { LgpdPortabilityComponent } from './fe/lgpd/portability-data/lgpd-portability.component';


const routes: Routes = [
  { path: 'login', component: LoginHomeComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], data: { roles: [Role.manager, Role.financial, Role.support, Role.company] }},
  { path: 'institutionRegister', component: RegistrationFormComponent, canActivate: [AuthGuardService], data: { roles: [Role.manager, Role.support, Role.company] }},
  { path: 'institutionRegister/:id', component: RegistrationFormComponent, canActivate: [AuthGuardService], data: { roles: [Role.manager, Role.support, Role.company] }},
  { path: 'groupList', component: GroupListComponent, canActivate: [AuthGuardService], data: { roles: [Role.company] }},
  { path: 'usersGroupList/:id', component: UsersGroupListComponent, canActivate: [AuthGuardService], data: { roles: [Role.company] }},
  { path: 'searchUsers',  component: UserListComponent, canActivate: [AuthGuardService], data: { roles: [Role.support, Role.manager] }},
  { path: 'userForm', component: RegistrationUserFormComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'invite', component: InviteComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'searchInvites', component: InviteListComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'examTypes', component: ExamTypesComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'notifyemails', component: NotifyEmailComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'monitoring', component: MonitoringComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'cancelprotocols', component: ProtocolsComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'account', component: AccountComponent, canActivate: [AuthGuardService], data: { roles: [Role.company, Role.support, Role.manager] }},
  { path: 'termsOfService',  component: TosComponent, canActivate: [AuthGuardService], data: { roles: [Role.support, Role.manager] }},
  { path: 'privacyPolicy',  component: PrivacyPolicyComponent, canActivate: [AuthGuardService], data: { roles: [Role.support, Role.manager] }},
  { path: 'lgpd-delete', component: LgpdDeleteComponent, canActivate: [AuthGuardService], data: { roles: [Role.support, Role.manager] }},
  { path: 'lgpd-portability', component: LgpdPortabilityComponent, canActivate: [AuthGuardService], data: { roles: [Role.support, Role.manager] }},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
