import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { element } from 'protractor';
import { dataProcessCancel } from 'src/app/model/dataProcessCancel.model';
import { Request } from 'src/app/model/request.model';
import { Role } from 'src/app/model/Role';
import { ViewByType } from 'src/app/model/viewByType';
import { AdminBeService } from 'src/services';
import { AddressService } from 'src/services/address.service';
import { ProtocolService } from 'src/services/protocol.service';
import { ValidatorsService } from 'src/services/validators.service';
import { TranslateService } from '../common/locale';

@Component({
  selector: 'app-protocols',
  templateUrl: './protocols.component.html',
  styleUrls: ['./protocols.component.css']
})
export class ProtocolsComponent implements OnInit {

  msgSpinnerProcess:string = '';
  protocols: Request[];
  protocolNumber:string;
  selectedProtocolsList: Array<any>;
  selectedExamsList: Array<any>;

  selectedItemsLists: Array<any>;
  
  selectedInactiveDataList: Array<any>;

  displayDialogCancel: boolean;
  displayDialogActive: boolean;
  displayDialogDelete: boolean;
  enableButtons: boolean;
  enableButtonDelete: boolean;

  clearList:boolean;

  role: Role;

  language:string;
  credencial: any;

  constructor(private _translate: TranslateService, private route: ActivatedRoute, private toastr: ToastrManager, 
    private protocolService: ProtocolService, private adminBeService: AdminBeService,
    private addressService: AddressService, private validator:ValidatorsService, private router: Router, 
    private spinner: NgxSpinnerService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.credencial= this.adminBeService.getUser().credencial;

    this.selectedProtocolsList = [];
    this.selectedExamsList = [];
    this.selectedInactiveDataList = [];
    this.protocolNumber = '';
    this.protocols = [];
    this.clearList = true;
    this.enableButtonDelete=false;

    this._translate.onLangChanged.subscribe((lang) => {
      this.language=lang.toString();
    });

    this.route.params.subscribe(
      params => {
        this.role = params['role']; 
    });
  }

  checkRoleUser(){
    let role:Role = this.role;
    if([Role.manager, Role.support].indexOf(role) >= 0){ //Check if the actual user role, is one of them
      return true;
    }

    return false;
  }

  isInactiveProtocol(selection: any) {
    let protocols = this.selectedProtocolsList.filter(item=>item.active==false);
    let exams = this.selectedExamsList.filter(item=>item.active==false);
    
    if(protocols.length==0 && exams.length==0){
      this.enableButtonDelete = false;
      return;
    }
    
    this.enableButtonDelete=true;
  }

  isCheckBoxDisabled(selection: any) {
    
    
    this.isInactiveProtocol(selection);

    if(this.selectedProtocolsList.length==0 && this.selectedExamsList.length==0){
      this.enableButtons=false;
      return;
    }

    this.enableButtons = true;    
  }

  showDialogCancel(){
    this.displayDialogCancel = true;
  }

  showDialogActive(){
    this.displayDialogActive = true;
  }

  showDialogDelete(){
    this.displayDialogDelete = true;
  }

  cancelDialog(){
    this.displayDialogActive = false;
    this.displayDialogCancel = false;
    this.displayDialogDelete = false;
  }

  getProtocolData(){
    
    if(this.clearList){
      this.clearList = false;
      this.clear();
    }
    

    let found:Request[] = this.protocols.filter(el=>el.protocol==this.protocolNumber);

    if(found!=undefined && found.length==0){
      this.msgSpinnerProcess = 'Procurando Protocolo';
      this.spinner.show();

      this.protocolService.getProtocol(this.protocolNumber).subscribe(data => {      

        this.addProtocolList(data);

        this.spinner.hide();
      },error =>{

        if(error.status==404){
          this.toastr.warningToastr(this._translate.instant("Protocolo não encontrado"), this._translate.instant("Erro"));
        }else{
          this.toastr.errorToastr(error.message, this._translate.instant("Erro"));
        }

        this.spinner.hide();

      });
    }else{

      this.toastr.warningToastr(this._translate.instant("Protocolo já está inserido na lista"), this._translate.instant("Erro"));

    }
  }

  addProtocolList(data){

    let requestList:Request[] = data;    
    requestList.forEach(item=>this.protocols.push(item));    
  }

  checkExamViewedByHour(profileTypes:Array<Record<keyof ViewByType,Date>>, showDate:boolean){

    if(profileTypes==undefined || profileTypes.length==0) return ViewByType.NO;

    let printValue:string = '';
    let viewed:Array<string> = [];
    profileTypes.forEach(element => {
      
      for (let key of Object.keys(element)) {
        let lastDate = element[key];

        let formatedValue:string = (key==ViewByType.CONTRACT?'PROTOCOLO':key==ViewByType.CUSTOMER?'PACIENTE':key==ViewByType.PHYSICIAN?'MÉDICO':ViewByType.NO);

        if(showDate){
          formatedValue += ' : ' +  this.datePipe.transform(new Date(lastDate),"dd/MM/yyyy HH:mm:ss");
        }

        printValue += '<div>' + formatedValue + '</div>';
        viewed.push(formatedValue);
      }

    });

    return printValue;
  }

  getMsgSpinner(){

    return this.msgSpinnerProcess;

  }

  processExams(active:boolean){

    this.displayDialogCancel = false;
    this.displayDialogActive = false;
    
    this.msgSpinnerProcess = this._translate.instant("Processando Protocolos/Exames selecionados.");
    this.spinner.show();

    let dataBody: dataProcessCancel[] = [];
    
    this.selectedProtocolsList.forEach(selectedProtocol => {
      let data = new dataProcessCancel();
      data.requestId = selectedProtocol.id;

      dataBody.push(data);
    });

    this.selectedExamsList.forEach(selectedExam => {
      let data = new dataProcessCancel();
      data.examId = selectedExam.id;

      dataBody.push(data);
    });

    this.protocolService.activeProtocols(active, dataBody).subscribe(data => {      
      this.toastr.successToastr(this._translate.instant("Protocolos/Exames processados com sucesso!"), this._translate.instant("Sucesso"));
      
      this.updateItemsWithNewStatus(dataBody, active);

      this.spinner.hide();
      this.clearList = true;
    });

  }

  processRemoveExams(active:boolean){

    this.cancelDialog();
    
    this.msgSpinnerProcess = this._translate.instant("Deletando Protocolos/Exames selecionados.");
    this.spinner.show();

    let dataBody:dataProcessCancel[] = [];

    let protocols = this.selectedProtocolsList.filter(r=>r.active==false);
    let exams = this.selectedExamsList.filter(r=>r.active==false);

    this.selectedInactiveDataList = [];

    protocols.forEach(item=>{
      this.selectedInactiveDataList.push(item);
    });

    exams.forEach(exam=>{
      let prot = protocols.filter(p=>exam.requestId==p.id);
      if(prot.length==0){
        this.selectedInactiveDataList.push(exam);
      }
    });
    
    this.selectedInactiveDataList.forEach(element => {

      let data:dataProcessCancel = new dataProcessCancel();
      if(element.requestKey){
        data.protocol = String(element.protocol);
        data.requestId = Number(element.id);
      }else{
        data.examId = element.id;
      }

      dataBody.push(data);
    });

    this.protocolService.removeProtocols(dataBody).subscribe(data => {      
      this.toastr.successToastr(this._translate.instant("Protocolos/Exames processados com sucesso!"), this._translate.instant("Sucesso"));

      this.spinner.hide();
      this.clear();
    },error=>{
      this.toastr.errorToastr(this._translate.instant("Error ao apagar dados selecionados!"), this._translate.instant("Error"));
      this.spinner.hide();
    });

  }

  clear(){
    this.selectedProtocolsList = [];
    this.selectedExamsList = [];
    this.selectedInactiveDataList = [];
    this.protocols = [];
    this.enableButtons=false;
    this.enableButtonDelete=false;
  }

  updateItemsWithNewStatus(dataBody, status){
    this.selectedProtocolsList.forEach(element => {
      let item = dataBody.filter(el => el.requestId === element.id);
      if (item.length>0) {
        element.active = status;
      }
    });

    this.selectedExamsList.forEach(element => {
      let item = dataBody.filter(el => el.examId === element.id);
      if (item.length>0) {
        element.active = status;
      }
    });

    if(status){ //When update with false, we need to active the Remove button
      this.enableButtonDelete = false;
    }else{
      this.enableButtonDelete = true;
    }
  }

}
