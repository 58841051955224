export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
    'Entrar': 'Enter',
    'Sair': 'Exit',
    'Minha_conta': 'My Profile',
    'Usuario': 'User',
    'Senha': 'Password',
    'Acessar':'Login',
    'Informe o usuário': 'User is required',
    'Bem vindo ao novo Clickvita Admin!': 'Welcome to the new Clickvita Admin!',

    //Menu Items
    'Gerenciamento de Instituições': 'Institution Management',
    'Novo':'New',
    'Filtro':'Filter',
    'Filtro Geral':'General Filter',
    'Abrir':'Open',
    'Resultados':'Results',
    'Deletar':'Remove',
    'Atualizar':'Refresh',
    'Cadastro':'Register',
    'Instituição':'Institution',
    'Médico':'Doctor',
    'Paciente':'Patient',
    'Buscar':'Find',
    'Relatórios':'Reports',
    'Editar':'Edit',
    'Salvar':'Save',
    'Outros':'Others',
    'Apagar':'Delete',

    'Relatorio': 'Report',
    'Grupos'   : 'Groups',

    'suporte': 'Don\'t know',
    'ID': 'ID',

    //institution register
    'Cadastro da Instituição': 'Institution Registration',
    'Nome': 'Name',
    'Token': 'Token',
    'Razão Social': 'Company Name',
    'CNPJ': 'CNPJ',
    'Website': 'Website',
    'Rua': 'Address',
    'Complemento': 'Addressdetails',
    'Número': 'Number',
    'CEP': 'Zip Code',
    'Bairro': 'Neighborhood',
    'Cidade': 'City',
    'Latitude': 'Latitude',
    'Longitude': 'Longitude',
    'Nome da instituição é obrigatório': 'Institution tranding name is required',
    'Token é obrigatório': 'Token is required',
    'Razão é obrigatório': 'Company Name is required',
    'CNPJ é obrigatório': 'CNPJ is required',
    'Selecione um País': 'Country is required',
    'Selecione o Estado': 'select the state',
    'Selecione a Cidade': 'Select the city',
    'Rua é obrigatório': 'Street is required',
    'Número é obrigatório': 'Number is required',
    'CEP é obrigatório': 'Zip code is required',
    'Bairro é obrigatório': 'Neighborhood is required',
    'CNPJ tem 14 digitos': 'CNPJ has 14 digits',
    'A instituição foi gravada': 'The Institution was saved',
    'Sucesso!': 'Success',
    'Erro: A instituição não foi gravada': 'Error: Institution not saved',
    'Erro': 'Error',
    'Erro: CNPJ inválido': 'Error: CNPJ is invalid',
    'Erro: Pais, Estado e Cidade são obrigatórios': 'Erro: Country, state and City are required',

    //Grupo register
    'Código': 'Code',
    'Descrição': 'Description',
    'Ativo':'Active',
    'Cancelar': 'Cancel',
    'Não': 'No',
    'Sim': 'Yes',
    'Tem certeza que deseja desativar o grupo': 'Are you sure you want to disable the group',
    'Erro: Não é possível alterar. O Grupo está vinculado a pelo menos um exame!':'Error: Unable to change. The Group is related by at least one exam.!',
    'Todos os campos são obrigatórios':'All fields are mandatory',
    'O Grupo foi salvo':'The group has been saved',
    'Error: O Grupo não foi salvo':'Error: Group was not saved',
    'Error: O Grupo não foi salvo, já existe um código com este nome.':'Error: The Group has not been saved, a code with this name already exists.',

    //Group Users
    'Usuários do Grupo':'Group Users',
    'Usuário Email':'User Email',
    'Necessário informar o email do usuário!':'Need to enter the users email!',
    'Usuário adicionado ao grupo com sucesso!':'User successfully added to group!',
    'Erro: Email do usuário deve ser válido e necessário ser Empresa ou Médico!':'Error: User Email must be valid and must be Company or Medical!',
    'Erro Inclusão':'Error Inclusion',
    'Usuário removido do grupo com sucesso!':'User removed from group successfully!',
    'Deletar Usuário':'Delete User',
    'Remover Usuário Grupo':'Remove User Group',
    'Adicionar Usuário':'Add User',
    'Email já está vinculado no grupo!':'Email is already linked in the group!',
    'Nenhum usuário adicionado ao grupo!':'No users added to the group!',
    'Incluir Usuário Grupo':'Add User Group',
    'Tem certeza que deseja remover o usuário do grupo':'Are you sure you want to remove user from group',

    //Register User
    'Usuário Externo':'External User',
    'Financeiro':'Financial',
    'Clínica': 'Clinic',
    'Suporte': 'Support',
    'Administrador': 'Administrator',
    'Tipo do usuário necessário!':'User type required!',
    'Usuário criado com sucesso!':'User successfully created!',
    'Usuário alterado com sucesso!': 'User successfully changed!',
    'Erro: Email do usuário já está cadastrado.':'Error: User email is already registered.',
    'Erro: Usuário não pode ser salvo, verifique os campos!':'Error: User could not be saved, check the fields!',
    'Nome Empresa':'Company name',
    'Nome da empresa é obrigatório':'Company Name is required',
    'Email':'Email',
    'Email da empresa é obrigatório':'Company Email is required',
    'Informar a Instituição é obrigatório!': 'Fulfil the institution field is requiredfulf!',
    'Tipo Usuário':'User Type',
    'Tipo Usuário é obrigatório':'Type User is required',
    'Nome da Instituição': 'Institution Name',
    'Desativado': 'Off',
    'Situação do usuário alterado!': 'The user state was updated',
    'A alteração da situação do usuário falhou!': 'The update of user state failed!',

    //User list
    'COMPANY': 'Clinic',
    'MANAGER': 'Manager',
    'FINANCIAL': 'Financial',
    'EXTERNAL': 'External',
    'SUPPORT': 'Support',

    //CustomLoginPage Institution
    'Código é obrigatório':'Code is required',
    'Endereço da Logo':'Logo Address',
    'Email para Suporte':'Email Support',
    'Telefone para Suporte':'Support Phone',
    'WhatsApp para Suporte':'WhatsApp for Support',
    'Tipo de Página':'Page Type',
    'Com logo e fundo branco':'With logo and white background',
    'Sem logo e fundo padrão':'Without logo and default background',
    'A página personalizada foi gravada':'Custom page has been saved',
    'Erro: A página personalizada não foi gravada':'Error: Custom Page Not Saved',

    //Settings Institution
    'Tipo':'Type',
    'Valor':'Value',
    'Unit Id Instituição':'Unit Id Institution',
    'Remover Configuração':'Remove Configuration',
    'Tem certeza que deseja remover a configuração da instituição':'Are you sure you want to remove institution setting',
    'Configuração removida da instituição!':'Configuration removed from institution!',
    'Erro: Necessário selecionar o tipo de configuração':'Error: Need to select setting type',
    'Erro: Necessário informar o valor da configuração':'Error: Need to enter setting value',
    'Erro: Essa configuração já está vinculada na instituição':'Error: This setting is already linked in institution',
    'A configuração foi salva':'The setting has been saved',
    'Erro: Configuração não foi salva, verifique os campos':'Error: Configuration not saved, check fields',
    'Nenhuma configuração para a instituição!':'No setup for the institution!',
    'Ativado': 'On',
    'Selecione': 'Select',
    'Selecione a versão': 'Select version',
    'Selecione o valor': 'Select value',
    'Selecione o sistema': 'Select system',

    'Somente imagens são suportadas':'Only images are supported.',
    'Selecione uma logo':'Select one logo',
    'Carregar a Logo': 'Upload the Logo',
    'A imagem foi salva com sucesso': 'The image has been saved',
    'Erro ao tentar salvar a imagem': 'Error trying to save the image',
    'Selecionar Arquivo': 'Choose a File',

    //Session Checker
    'Sessão':'Session',
    'Sessão encerrada devido a inatividade.': 'Session terminated due to inactivity.',
    'Sua sessão irá expirar em ':'Your session will expire on',
    'minuto(s). Clique aqui para renovar.':'minute (s). Click here to renew.',
    'Sua sessão foi renovada':'Your session has been renewed',
    'Renovar':'Renew',

    //Invite
    'NOT_SENT':'NOT SENT',
    'SENT':'SENT',
    'INVALID_EMAIL':'INVALID EMAIL',
    'INVALID_CRM':'INVALID CRM',
    'INVALID_TOKEN':'INVALID TOKEN',
    'EMAIL_REGISTERED':'E-MAIL ALREADY REGISTERED',

    'CREATED':'Accepted',
    'INVITE_SEND':'Sent by administrator',
    'AUTO_INVITE':'Automatically sent',
    'ON_CREATION':'In progress',
    'INVALID': 'Invalid',

    'Reenviar convites': 'Resend invitation',
    'Período:': 'Period:',
    'Convites': 'Invitation',
    'Data': 'Date',
    'Validade': 'Valid',
    'Situação': 'Situation',
    'Enviando Convites':'Sending Invitations',
    'Erro: Os convites não foram processados':'Error: Invitations were not processed',
    'Convites processados, veja coluna status para mais detalhes':'Invitations processed, see status column for details',
    'PHYSICIAN_INVITE_ALERT': 'It is important to always remember your responsibility when inviting new users.\nAccording to the General Data Protection Law, only authorized and strictly necessary persons should have access to personal data and sensitive personal data stored on the platform , to meet the intended purpose between the parties (You and Pixeon) and the patients.\nMake sure the user is part of your institution and can access the platform.',
    'PHYSICIAN_INVITE_CONSENT': 'I read, I agree and I want to proceed with sending the invitation to the user.',

    //Units
    'ID Unidade':'ID Unit',
    'Endereço':'Address',
    'País':'Country',
    'Estado':'State',
    'Nenhuma unidade para a instituição!':'No units for the institution!',
    'Os dados da unidade foram salvos!':'Drive data has been saved!',
    'Erro: Unidade não foi salva, verifique os campos':'Error: Drive not saved, check fields',

    //ExamTypeInstitution association
    'O tipo do exame foi salvo e vinculado':'Exam Type Saved and Linked',
    'Erro: O tipo do exame não foi salvo':'Error: Exam Type Not Saved',
    'Vincular Tipos de Exames':'Link Exam Types',
    'Código Instituição':'Institution Code',
    'Descrição Instituição':'Institution Description',
    'Código Clickvita':'Clickvita Code',
    'Descrição Clickvita':'Description Clickvita',
    'Nenhum tipo de exame para a instituição':'No exams for institution',

    //NotifyEmails
    'Nenhuma notificação de email para a instituição!':'No email notifications for institution',
    'Tem certeza que deseja remover a notificação da instituição ':'Are you sure you want to remove institution notification ',
    'Tem certeza que deseja remover a ':'Are you sure you want to remove ',
    ' da instituição?':' from institution?',
    'Configuração de Notificações Email':'Email Notifications Configuration',
    'Erro: Essa notificação já está vinculada na instituição':'Error: This notification is already linked in institution',
    'Notificação removida da instituição!':'Notification removed from institution!',
    'Erro: Necessário informar o valor da notificação':'Error: Need to enter notification value',
    'A notificação foi salva':'Notification has been saved',
    'Erro: Notificação não foi salva, verifique os campos':'Error: Notification unsaved, check fields',
    'Remover Notificação':'Remove Notification',
    'Notificações Por Email':'Email Notifications',

    //Monitoring
    'RECEIVED':'RECEIVED',
    'REJECTED':'REJECTED',
    'PROCESSING':'PROCESSING',
    'PROCESSED':'PROCESSED',
    'OK':'OK',
    'ERROR':'ERROR',
    'WARNING':'WARNING',
    ' há ':'',
    ' minutos':' minutes ago',
    ' dias':' days ago',
    ' horas': ' hours ago',
    'Monitoramento de Mensagens':'Message Monitoring',
    'Mensagens Integradas: ':'Integrated Messages: ',
    'Mensagens Processadas: ':'Processed Messages: ',
    ' mensagens/hora':' messages/time',
    'Selecione o Contrato':'Select the Contract',
    'Selecione o Status':'Select Status',
    'Selecione o Tipo':'Select Type',
    'Protocolo':'Protocol',
    'Contrato':'Contract',
    'Status':'Status',
    'Validação':'Validation',
    'Data de Integração':'Integration Date',
    'Monitoramento':'Monitoring',
    'Buscando Integrações':'Seeking integrations',
    'Nenhuma integração encontrada!':'No integration found',
    'Nenhum alerta foi gerado para a mensagem':'No alerts were generated for the message',
    'EXPECTED_TYPE_NUMBER':'Expected type number',
    'EXPECTED_MIN_LENGHT':'Expected minimum size',
    'EXPECTED_TYPE_STRING':'Expected text type',
    'DOES_NOT_MATCH_PATTERN':'Type sent does not conform to the format',
    'REQUIRED_KEY':'Required field',
    'IS_NOT_VALID_TYPE':'Invalid type',
    'EXPECTED_TYPE_OBJECT':'Expected object type',

    'Protocolos':'Protocols',
    'Cancelar Protocolo':'Cancel Protocol',
    'Protocolo é obrigatório':'Protocol is mandatory',
    'Pesquisar':'Search',
    'Cancelar Protocolos':'Cancel Protocols',
    'Ativar Protocolos':'Enable Protocols',
    'Data Entrega':'Delivery date',
    'Solicitante':'Requester',
    'Visualizado?':'Viewed?',
    'Ativo?':'Active?',
    'Nenhum exame vinculado ao protocolo!':'No exam linked to the protocol!',
    'Nenhum protocolo a ser processado!':'No protocol to be processed!',
    'Tem certeza que deseja cancelar todos os protocolos e exames selecionados?':'Are you sure you want to cancel all selected protocols and exams?',
    'Tem certeza que deseja ativar todos os protocolos e exames selecionados?':'Are you sure you want to activate all selected protocols and exams?',
    'Tem certeza que deseja apagar todos os protocolos e exames selecionados?':'Are you sure you want to delete all selected protocols and exams?',
    'Esta ação não poderá ser desfeita!':'This action cannot be undone!',
    'Processando Protocolos/Exames selecionados.':'Processing selected Protocols / Exams.',
    'Protocolos/Exames processados com sucesso!':'Protocols / Exams processed successfully!',
    'Protocolo já está inserido na lista':'Protocol is already inserted in the list',
    'Protocolo não encontrado':'Protocol not found',
    'Procurando Protocolo':'Searching Protocol',
    'Deletando Protocolos/Exames selecionados.':'Deleting selected Protocols / Exams.',
    'Error ao apagar dados selecionados!':'Error when deleting selected data!',
    'Última Atualização':'Last update',

    //Reports
    'Os relatórios do ClickVita 2 são gerados a partir do metabase. Para solicitar seu acesso entre em contato com o suporte da Pixeon.':'ClickVita 2 reports are generated from the metabase. To request your access, contact Pixeon support.',
    'Assim que você receber o seu acesso entre com as credenciais em ':'As soon as you receive your access, enter your credentials at ',

    //Account Data
    'clickvitaApp.actual_password_not_informed':'Password not informed',
    'clickvitaApp.actual_password_incorrect':'Incorrect current password',
    'clickvitaApp.new_email_is_being_used':'New email is already in use',
    'Email é obrigatório':'Email is required',
    'Informe a senha atual':'Enter the current password',
    'Senha atual é obrigatória':'Current password is required',
    'Novo Email':'New email',
    'Novo Email é obrigatório':'New Email is required',
    'Repita Novo Email':'Repeat New Email',
    'Novo Email deve ser igual':'New Email must be the same',
    'Senha Atual':'Current password',
    'Nova Senha':'New password',
    'Nova Senha é obrigatória':'New Password is required',
    'Repita a Nova Senha':'Repeat the new password',
    'Nova Senha deve ser igual':'New Password must be the same',
    'Os dados foram salvos com sucesso.':'The data has been successfully saved.',
    'Verifique sua caixa de email':'Check your inbox',
    'para confirmar a alteração':'to confirm the change',
    'Atenção':'Attention!',
    'Erro ao salvar dados.':'Error saving data.',
    'USER_CREATE_ALERT': 'It is important to always remember your responsibility when creating new users.\nAccording to the General Data Protection Law, only authorized and strictly necessary persons should have access to personal data and sensitive personal data stored on the platform , to meet the intended purpose between the parties (You and Pixeon) and the patients.\nMake sure the user is part of your institution and can access the platform.',
    'USER_CREATE_CONSENT': 'I read, I agree and I want to proceed with user creation.',

    //Termos
    'Termos': 'Terms',
    'Termos de Serviço': 'Terms of Service',
    'Termos de serviço atualizados com sucesso!':'Terms of Service updated successfully!',
    'Erro: Não foi possível salvar os novos termos de serviço.': 'Error: It was not possible to save the new terms of service',
    'Mensagem de confirmação dos termos de serviço 1': 'Are you sure you want to update the terms of service?',
    'Mensagem de confirmação dos termos de serviço 2': 'All users should accept the new terms on their next login.',
    'Política de Privacidade': 'Privacy Polcy',
    'Política de privacidade atualizada com sucesso!': 'Privacy Policy updated successfully!',
    'Erro: Não foi possível salvar a nova política de privacidade.': 'Error: It was not possible to save the new privacy policy',
    'Mensagem de confirmação da política de privacidade 1': 'Are you sure you want to update the privacy policy?',
    'Mensagem de confirmação da política de privacidade 2': 'All users should accept the new privacy policy on their next login.',

    //Permission Delete LGPD
    'Dados removidos com sucesso!':'Data removed successfully!',
    'Dados Copiados!':'Copied Data!',
    'Copiar AccessCodes':'Copy AccessCodes',
    'Remover dados usuários':'Remove user data',
    'Dados não encontrados':'Data not found',
    'Procurando Todos os vínculos do usuário':'Searching All User Links',
    'Direito a Exclusão':'Right to Exclusion',
    'Email de Cadastro é obrigatório':'Registration email is mandatory',
    'Email Cadastro':'Email Registration',
    'Apagar todos os dados do usuário':'Delete all user data',
    'Confirma a exclusão de todos os dados deste usuário?':'Confirm the deletion of all data for this user?',
    'Lembre de copiar todos os accessCode para enviar ao time do PACS afim de remover as imagens também.':'Remember to copy all accessCode to send to PACS team in order to remove images too.',
    'Removendo todos os dados do usuário...':'Removing all user data...'
}
