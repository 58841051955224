import { Institution } from './institution.model';

export class CustomLoginPage {
   
    id:number;
    code:string;
    logoPath:string;
    supportEmail:string;
    supportPhoneNumber:string;
    supportWhatsAppNumber:string;
    institution:Institution;
    version:number;
    viewType:number;

    constructor() {
        this.id=0;
        this.code = "";
        this.institution = new Institution();
    }
}