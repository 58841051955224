import { Component, OnInit, ViewContainerRef, ViewEncapsulation, EventEmitter, Output, Input } from '@angular/core';
import { AdminBeService } from 'src/services/admin-be.service';
import { AutorizationUser } from 'src/model/autorization.model';
import { UserModel } from 'src/app/model/userModel';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  @Input() completedParam;

  authUser:AutorizationUser;
  user: UserModel;

  constructor(private authService:AdminBeService, private router: Router) { 
    this.user = new UserModel();
  }

  ngOnInit() {
      if(this.onComplete && (this.completedParam==true)){ //Check if was called by modal and load the username in field
        this.user.username = this.authService.autorization.credencial.sub;
      } else {
        this.router.navigateByUrl("/home");
      }
  }

  onSubmit(formData: any) {
    this.loginUser(this.user.username, this.user.password,this.completedParam);
  }

  async loginUser(username:string,password:string,isModalLogin:boolean){
    this.authService.loginMigrator(username,password,isModalLogin).then(value => {
      this.runOnCompleteCloseModal(value); //If user refresh the session using the login modal, close the modal 
    });
  }  

  closeModal(){
    this.runOnCompleteCloseModal(false);
  }

  runOnCompleteCloseModal(msg:boolean): void {
    if(this.authService.autorization.response.status==200){
      if(this.onComplete && this.completedParam==true){
          this.onComplete.emit(msg);
      }
    }
  }

}
