import { Institution } from './institution.model';
import { Setting } from './setting.model';

export class InstitutionSetting {
    id:number;
    institution:Institution;
    setting:Setting;
    active:boolean;
    value:string;
    version:number;
    institutionUnitId:number;

    constructor() {
        this.id = 0;
        this.institution = new Institution();
        this.setting = new Setting();
    }
}