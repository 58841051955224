import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'jsonFormat'
})
export class JsonFormatPipe implements PipeTransform {

    constructor(){}

    transform(value: any, ...args: any[]): any {

        if(value.indexOf("xml")>0){
            return value; 
        }

        let object:any = JSON.parse(value);        
        return this.syntaxHighlightJSON(JSON.stringify(object, undefined, 2));
    } 

    syntaxHighlightJSON(json) {
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }
}