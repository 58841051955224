import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BnNgIdleService } from 'bn-ng-idle';
import { AdminBeService } from './admin-be.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { TranslateService } from 'src/app/fe/common/locale';

@Injectable({
  providedIn: 'root'
})
export class SessionCheckService{

  private checkSessionSeconds:number = 60;
  private dividerToMinutes:number = 60;
  private minutesToShowWarning:number = 5;  

  displayLoginModal:boolean = false;

  constructor(private bnIdle: BnNgIdleService, private authentication: AdminBeService, private toastr: ToastrManager,
    private _translate: TranslateService) {

    toastr.onClickToast()
    .subscribe( toast => {            
        let action = toast.data.hasOwnProperty('action');
        
        if (action) {
          this.openDialogLogin();
        }
    });
  }

  startCheckSessionUser(){
    this.bnIdle.startWatching(this.checkSessionSeconds).subscribe((isTimedOut: boolean) => {

      if(this.authentication.getUser()){
        let expiration=this.authentication.getUser().credencial.exp;
        let difference = (expiration - (Date.now() / 1000));
        let minutes = (Math.trunc(difference  / this.dividerToMinutes)); //get minutes to be validated

        if (minutes<=0) {
          this.toastr.errorToastr(this._translate.instant('Sessão encerrada devido a inatividade.'), this._translate.instant('Sessão'), {toastTimeout:10000});

          setTimeout(()=>{
            this.authentication.logout();
          },800);
        }else{
          if(minutes<=this.minutesToShowWarning){
            this.toastr.warningToastr(this._translate.instant('Sua sessão irá expirar em ') + minutes + this._translate.instant(' minuto(s). Clique aqui para renovar.'), this._translate.instant('Sessão'), {toastTimeout:10000, enableHTML: true, tapToDismiss: false, data: {'action': true}});
          }
        }
      }
    });    
  }

  finishTimer(){
    this.bnIdle.stopTimer();
    this.bnIdle = new BnNgIdleService();
  }

  openDialogLogin(){
    this.displayLoginModal = true;
  }

  isDialogVisible(){
    return this.displayLoginModal;
  }

  closeModal(showMsg:boolean){
    this.displayLoginModal = false;
    
    if(showMsg)
      this.toastr.successToastr(this._translate.instant('Sua sessão foi renovada'), this._translate.instant('Sessão'), {toastTimeout:10000, tapToDismiss: true});
  }
}