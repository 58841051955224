import { Component, OnInit, ViewChild } from '@angular/core';
import { UserModel } from 'src/app/model/userModel';
import { UserService } from 'src/services/user.service';
import { TranslateService } from 'src/app/fe/common/locale';
import { AdminBeService } from 'src/services';
import { SelectItem } from 'primeng/api';
import { Role } from 'src/app/model/Role';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { TypeUserItem } from 'src/app/model/type.user.item';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']

})
export class UserListComponent implements OnInit {

  @ViewChild('dataTable', {static:false}) dataTable: Table;

  first: number = 0;
  users: Array<any>;
  selectedUser: UserModel;
  profilesUserType: SelectItem[];

  cols: any[];
  totalRecords = 0;
  page = 1;
  pageSize = 10;

  value: string;
  fieldSearch: string;
  userName: string;
  userType: String;
  adminInstitutionId: number;

  loading: boolean;

  constructor(private userService: UserService, private translate: TranslateService, private adminBeService: AdminBeService, private router: Router, private route: ActivatedRoute) {
    this.loading = false;
  }

  ngOnInit() {

    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'username', header: 'Usuário' },
      { field: 'userType', header: 'Type' },
      { field: 'adminInstitutionId', header: 'Inst.' }
    ];
    this.loadProfilesUserType();
    this.value= '';

    this.route.params.subscribe(
      params => {
        if (params['pg']) {
          this.page = params['pg'];
        }
        if (params['value'] && params['value'] !== "") {
          this.value = params['value'];
        }
        if (params['field']) {
          this.fieldSearch = params['field'];
          this.setFieldSearchPreviusValue();
        }
    });

    this.users = [{id:0, username:'', password: '', name:'',userType: 'CARREGANDO...', adminInstitutionId: 0}]
    this.getUsers();
  }

  setFieldSearchPreviusValue() {
    if (this.fieldSearch === 'username') {
      this.userName = this.value.trim();
    }
    if (this.fieldSearch === 'userType') {
      this.userType = this.value;
    }
    if (this.fieldSearch === 'adminInstitutionId') {
      this.adminInstitutionId = parseInt(this.value);
    }
  }



  getUsers() {

    let pageNumber = this.page - 1;

    if (this.value !== '') {
      this.loading = true;
      this.userService.getAllUsersByFilters(this.value.trim() , this.fieldSearch).subscribe(data => {
        this.loadData(data);
      });
    } else {
      this.loading = true;
      this.userService.getAllUsers().subscribe(data => {
        this.loadData(data);
      });
    }

  }

  loadData(data: any) {
    this.users = [];
    this.users = data;
    this.totalRecords = data['totalElements'];
    this.setCurrentPage(this.page);
    this.loading = false;
  }

  setCurrentPage(n: number) {
   this.dataTable.first = ((n - 1) * this.dataTable.rows);
  }

  paginate(event: any) {
    this.page = event.first/event.rows + 1;;
    this.getUsers();
  }

  search(event: any) {
    this.fieldSearch = event.currentTarget.id;
    this.value = event.currentTarget.value;
    this. cleanFilterFields();
    this.setFieldSearchPreviusValue();
    this.page = 0;
    this.getUsers();
  }

  cleanFilterFields() {
    this.userName = '';
    this.userType = '';
    this.adminInstitutionId = undefined;
  }

  onSelectUserTypeSearch(event: any) {
    this.fieldSearch = 'userType';
    this.value = event.value;
    this.cleanFilterFields();
    this.setFieldSearchPreviusValue();
    this.page = 1;
    this.getUsers();
  }

  loadProfilesUserType() {
    let user = this.adminBeService.getUser();
    if (user && user.credencial) {
        if (user.credencial.role === Role.manager) {
            this.profilesUserType = this.loadSearchComboToUserTypeManager();
        }

        if (user.credencial.role === Role.support) {
            this.profilesUserType = this.loadSearchComboToUserTypeSupport();
        }
    }
  }

  loadSearchComboToUserTypeManager() {
    let typeUser = new TypeUserItem(this.translate);
    let item = [];
    item.push(typeUser.COMPANY, typeUser.EXTERNAL, typeUser.FINANCIAL, typeUser.SUPPORT, typeUser.MANAGER, typeUser.PHYSICIAN, typeUser.CUSTOMER);
    return item;
  }

  loadSearchComboToUserTypeSupport() {
    let typeUser = new TypeUserItem(this.translate);
    let item = [];
    item.push(typeUser.COMPANY, typeUser.EXTERNAL, typeUser.FINANCIAL, typeUser.PHYSICIAN, typeUser.CUSTOMER);
    return item;
  }

  onRowSelect(event: any) {
    this.router.navigate(['userForm', {
        pg: this.page,
        fieldSearch: this.fieldSearch,
        value: this.value,
        id: this.selectedUser.id,
        name: this.selectedUser.name,
        userType: this.selectedUser.userType,
        username: this.selectedUser.username,
        adminInstitutionId: this.selectedUser.adminInstitutionId,
        isEnable: this.selectedUser.isEnable
      }]);
  }

}
