export const LANG_PT_NAME = 'pt-br';

export const LANG_PT_TRANS = {
    'Entrar': 'Entrar',
    'Sair': 'Sair',
    'Minha_conta': 'Minha Conta',
    'Usuario': 'Usuário',
    'Senha': 'Senha',
    'Acessar':'Acessar',
    'Informe o usuário': 'Informe o usuário',
    'Bem vindo ao novo Clickvita Admin!': 'Bem vindo ao novo Clickvita Admin!',

    //Menu Items
    'Gerenciamento de Instituições': 'Gerenciamento de Instituições',
    'Novo':'Novo',
    'Filtro':'Filtro',
    'Filtro Geral': 'Filtro Geral',
    'Abrir':'Abrir',
    'Resultados':'Resultados',
    'Deletar':'Deletar',
    'Atualizar':'Atualizar',
    'Cadastro':'Cadastro',
    'Instituição':'Instituição',
    'Instituições': 'Instituições',
    'Médico':'Médico',
    'Paciente':'Paciente',
    'Buscar':'Buscar',
    'Relatórios':'Relatórios',
    'Editar':'Editar',
    'Salvar':'Salvar',
    'Outros':'Outros',
    'Apagar':'Apagar',

    //menu company
    'Relatorio': 'Relatório',
    'Grupos'   : 'Grupos',


    'ID': 'ID',

    //institution register
    'Cadastro da Instituição': 'Cadastro da Instituição',
    'Nome': 'Nome',
    'Token': 'Token',
    'Razão Social': 'Razão Social',
    'CNPJ': 'CNPJ',
    'Website': 'Website',
    'Rua': 'Rua',
    'Complemento': 'Complemento',
    'Número': 'Número',
    'CEP': 'CEP',
    'Bairro': 'Bairro',
    'Cidade': 'Cidade',
    'Latitude': 'Latitude',
    'Longitude': 'Longitude',
    'Nome da instituição é obrigatório': 'Nome da instituição é obrigatório',
    'Token é obrigatório': 'Token é obrigatório',
    'Razão é obrigatório': 'Razão é obrigatório',
    'CNPJ é obrigatório': 'CNPJ é obrigatório',
    'Selecione um País': 'Selecione um País',
    'Selecione o Estado': 'Selecione o Estado',
    'Selecione a Cidade': 'Selecione a Cidade',
    'Rua é obrigatório': 'Rua é obrigatório',
    'Número é obrigatório': 'Número é obrigatório',
    'CEP é obrigatório': 'CEP é obrigatório',
    'Bairro é obrigatório':'Bairro é obrigatório',
    'CNPJ tem 14 digitos': 'CNPJ tem 14 digitos',
    'A instituição foi gravada': 'A instituição foi gravada',
    'Sucesso!': 'Sucesso!',
    'Erro: A instituição não foi gravada': 'Error: A instituição não foi gravada',
    'Erro': 'Error',
    'Erro: CNPJ inválido': 'Error: CNPJ inválido',
    'Erro: País, Estado e Cidade são obrigatórios': 'Erro: País, Estado e Cidade são obrigatórios',

    //Grupo register
    'Código': 'Código',
    'Descrição': 'Descrição',
    'Ativo':'Ativo',
    'Cancelar': 'Cancelar',
    'Não': 'Não',
    'Sim': 'Sim',
    'Tem certeza que deseja desativar o grupo': 'Tem certeza que deseja desativar o grupo',
    'Erro: Não é possível alterar. O Grupo está vinculado a pelo menos um exame!':'Erro: Não é possível alterar. O Grupo está vinculado a pelo menos um exame!',
    'Todos os campos são obrigatórios':'Todos os campos são obrigatórios',
    'O Grupo foi salvo':'O Grupo foi salvo',
    'Erro: O Grupo não foi salvo':'Erro: O Grupo não foi salvo',
    'Erro: O Grupo não foi salvo, já existe um código com este nome.':'Erro: O Grupo não foi salvo, já existe um código com este nome.',

    //Group Users
    'Usuários do Grupo':'Usuários do Grupo',
    'Usuário Email':'Usuário Email',
    'Necessário informar o email do usuário!':'Necessário informar o email do usuário!',
    'Usuário adicionado ao grupo com sucesso!':'Usuário adicionado ao grupo com sucesso!',
    'Erro: Email do usuário deve ser válido e necessário ser Empresa ou Médico!':'Erro: Email do usuário deve ser válido e necessário ser Empresa ou Médico!',
    'Erro Inclusão':'Erro Inclusão',
    'Usuário removido do grupo com sucesso!':'Usuário removido do grupo com sucesso!',
    'Deletar Usuário':'Deletar Usuário',
    'Remover Usuário Grupo':'Remover Usuário Grupo',
    'Adicionar Usuário':'Adicionar Usuário',
    'Email já está vinculado no grupo!':'Email já está vinculado no grupo!',
    'Nenhum usuário adicionado ao grupo!':'Nenhum usuário adicionado ao grupo!',
    'Incluir Usuário Grupo':'Incluir Usuário Grupo',
    'Tem certeza que deseja remover o usuário do grupo':'Tem certeza que deseja remover o usuário do grupo',

    //Register User
    'Usuário Externo':'Usuário Externo',
    'Financeiro':'Financeiro',
    'Clínica': 'Clínica',
    'Suporte': 'Suporte',
    'Administrador': 'Administrador',
    'Tipo do usuário necessário!':'Tipo do usuário necessário!',
    'Usuário criado com sucesso!':'Usuário criado com sucesso!',
    'Usuário alterado com sucesso!': 'Usuário alterado com sucesso!',
    'Erro: Email do usuário já está cadastrado.':'Erro: Email do usuário já está cadastrado.',
    'Erro: Usuário não pode ser salvo, verifique os campos!':'Erro: Usuário não pode ser salvo, verifique os campos!',
    'Nome Empresa':'Nome Empresa',
    'Nome da empresa é obrigatório':'Nome da empresa é obrigatório',
    'Email':'Email',
    'Email da empresa é obrigatório':'Email da empresa é obrigatório',
    'Informar a Instituição é obrigatório!': 'Informar a Instituição é obrigatório!',
    'Tipo Usuário':'Tipo Usuário',
    'Tipo Usuário é obrigatório':'Tipo Usuário é obrigatório',
    'Nome da Instituição': 'Nome da Instituição',
    'Desativado': 'Desativado',
    'Situação do usuário alterado!': 'Situação do usuário alterado!',
    'A alteração da situação do usuário falhou!': 'A alteração da situação do usuário falhou!',

    //User list
    'COMPANY': 'Clínica',
    'MANAGER': 'Gerente',
    'FINANCIAL': 'Financeiro',
    'EXTERNAL': 'Usuário Externo',
    'SUPPORT': 'Suporte',

    //CustomLoginPage Institution
    'Código é obrigatório':'Código é obrigatório',
    'Endereço da Logo':'Endereço da Logo',
    'Email para Suporte':'Email para Suporte',
    'Telefone para Suporte':'Telefone para Suporte',
    'WhatsApp para Suporte':'WhatsApp para Suporte',
    'Tipo de Página':'Tipo de Página',
    'Com logo e fundo branco':'Com logo e fundo branco',
    'Sem logo e fundo padrão':'Sem logo e fundo padrão',
    'A página personalizada foi gravada':'A página personalizada foi gravada',
    'Erro: A página personalizada não foi gravada':'Erro: A página personalizada não foi gravada',

    //Settings Institution
    'Tipo':'Tipo',
    'Valor':'Valor',
    'Unit Id Instituição':'Unit Id Instituição',
    'Remover Configuração':'Remover Configuração',
    'Tem certeza que deseja remover a configuração da instituição':'Tem certeza que deseja remover a configuração da instituição',
    'Configuração removida da instituição!':'Configuração removida da instituição!',
    'Erro: Necessário selecionar o tipo de configuração':'Erro: Necessário selecionar o tipo de configuração',
    'Erro: Necessário informar o valor da configuração':'Erro: Necessário informar o valor da configuração',
    'Erro: Essa configuração já está vinculada na instituição':'Erro: Essa configuração já está vinculada na instituição',
    'A configuração foi salva':'A configuração foi salva',
    'Erro: Configuração não foi salva, verifique os campos':'Erro: Configuração não foi salva, verifique os campos',
    'Nenhuma configuração para a instituição!':'Nenhuma configuração para a instituição!',
    'Ativado': 'Ativado',
    'Selecione': 'Selecione',
    'Selecione a versão': 'Selecione a versão',
    'Selecione o valor': 'Selecione o valor',
    'Selecione o sistema': 'Selecione o sistema',

    'Somente imagens são suportadas':'Somente imagens são suportadas',
    'Selecione uma logo':'Selecione uma logo',
    'Carregar a Logo': 'Carregar a Logo',
    'A imagem foi salva com sucesso': 'A imagem foi salva com sucesso',
    'Erro ao tentar salvar a imagem': 'Erro ao tentar salvar a imagem',
    'Selecionar Arquivo': 'Selecionar Arquivo',

    //Session Checker
    'Sessão':'Sessão',
    'Sessão encerrada devido a inatividade.': 'Sessão encerrada devido a inatividade.',
    'Sua sessão irá expirar em ':'Sua sessão irá expirar em ',
    'minuto(s). Clique aqui para renovar.':'minuto(s). Clique aqui para renovar.',
    'Sua sessão foi renovada':'Sua sessão foi renovada',
    'Renovar':'Renovar',

    //Invite
    'NOT_SENT':'NÃO ENVIADO',
    'SENT':'ENVIADO',
    'INVALID_EMAIL':'EMAIL INVÁLIDO',
    'INVALID_CRM':'CRM INVÁLIDO',
    'INVALID_TOKEN':'TOKEN INVÁLIDO',
    'EMAIL_REGISTERED':'EMAIL JÁ CADASTRADO',
    'Enviando Convites':'Enviando Convites',
    'Erro: Os convites não foram processados':'Erro: Os convites não foram processados',
    'Convites processados, veja coluna status para mais detalhes':'Convites processados, veja coluna status para mais detalhes',
    'PHYSICIAN_INVITE_ALERT': 'É importante sempre lembrar da sua responsabilidade quando do convite de novos usuários.\nDe acordo com a Lei Geral de Proteção de Dados, somente pessoas autorizadas e estritamente necessárias devem ter acesso aos dados pessoais e dados pessoais sensíveis armazenados na plataforma, para atendimento da finalidade pretendida entre as partes (Você e a Pixeon) e os pacientes.\nGaranta que o usuário faça parte da sua instituição e que pode acessar a plataforma.',
    'PHYSICIAN_INVITE_CONSENT': 'Li, estou de acordo e desejo prosseguir com o envio do convite ao usuário.',

    //List of Invite (status)
    'CREATED':'Aceito',
    'INVITE_SEND':'Enviado por Admin',
    'AUTO_INVITE':'Enviado automaticamente',
    'ON_CREATION':'Processando',
    'INVALID': 'Inválido',

    'Reenviar convites': 'Reenviar convites',
    'Período:': 'Período:',
    'Convites': 'Convites',
    'E-mail': 'E-mail',
    'CRM': 'CRM',
    'Data': 'Data',
    'Validade': 'Validade',
    'Situação': 'Situação',

    //Units
    'ID Unidade':'ID Unidade',
    'Endereço':'Endereço',
    'País':'País',
    'Estado':'Estado',
    'Nenhuma unidade para a instituição!':'Nenhuma unidade para a instituição!',
    'Os dados da unidade foram salvos!':'Os dados da unidade foram salvos!',
    'Erro: Unidade não foi salva, verifique os campos':'Erro: Unidade não foi salva, verifique os campos',

    //ExamTypeInstitution association
    'O tipo do exame foi salvo e vinculado':'O tipo do exame foi salvo e vinculado',
    'Erro: O tipo do exame não foi salvo':'Erro: O tipo do exame não foi salvo',
    'Vincular Tipos de Exames':'Vincular Tipos de Exames',
    'Código Instituição':'Código Instituição',
    'Descrição Instituição':'Descrição Instituição',
    'Código Clickvita':'Código Clickvita',
    'Descrição Clickvita':'Descrição Clickvita',
    'Nenhum tipo de exame para a instituição':'Nenhum tipo de exame para a instituição',

    //NotifyEmails
    'Nenhuma notificação de email para a instituição!':'Nenhuma notificação de email para a instituição',
    'Tem certeza que deseja remover a notificação da instituição ':'Tem certeza que deseja remover a notificação da instituição ',
    'Tem certeza que deseja remover a ':'Tem certeza que deseja remover a ',
    ' da instituição?':' da instituição?',
    'Configuração de Notificações Email':'Configuração de Notificações Email',
    'Erro: Essa notificação já está vinculada na instituição':'Erro: Essa notificação já está vinculada na instituição',
    'Notificação removida da instituição!':'Notificação removida da instituição!',
    'Erro: Necessário informar o valor da notificação':'Erro: Necessário informar o valor da notificação',
    'A notificação foi salva':'A notificação foi salva',
    'Erro: Notificação não foi salva, verifique os campos':'Erro: Notificação não foi salva, verifique os campos',
    'Remover Notificação':'Remover Notificação',
    'Notificações Por Email':'Notificações Por Email',

    //Monitoring
    'RECEIVED':'RECEBIDO',
    'REJECTED':'REJEITADO',
    'PROCESSING':'PROCESSANDO',
    'PROCESSED':'PROCESSADO',
    'OK':'OK',
    'ERROR':'ERRO',
    'WARNING':'ALERTA',
    ' há ':' há ',
    ' minutos':' minutos',
    ' dias':' dias',
    ' horas': ' horas',
    'Monitoramento de Mensagens':'Monitoramento de Mensagens',
    'Mensagens Integradas: ':'Mensagens Integradas: ',
    'Mensagens Processadas: ':'Mensagens Processadas: ',
    ' mensagens/hora':' mensagens/hora',
    'Selecione o Contrato':'Selecione o Contrato',
    'Selecione o Status':'Selecione o Status',
    'Selecione o Tipo':'Selecione o Tipo',
    'Protocolo':'Protocolo',
    'Contrato':'Contrato',
    'Status':'Status',
    'Validação':'Validação',
    'Data de Integração':'Data de Integração',
    'Monitoramento':'Monitoramento',
    'Buscando Integrações':'Buscando Integrações',
    'Nenhuma integração encontrada!':'Nenhuma integração encontrada!',
    'Nenhum alerta foi gerado para a mensagem':'Nenhum alerta foi gerado para a mensagem',
    'EXPECTED_TYPE_NUMBER':'Esperado tipo número',
    'EXPECTED_MIN_LENGHT':'Esperado tamanho mínimo',
    'EXPECTED_TYPE_STRING':'Esperado do tipo texto',
    'DOES_NOT_MATCH_PATTERN':'Tipo enviado não está de acordo com o formato',
    'REQUIRED_KEY':'Campo requerido',
    'IS_NOT_VALID_TYPE':'Tipo inválido',
    'EXPECTED_TYPE_OBJECT':'Esperado do tipo objeto',

    'Protocolos':'Protocolos',
    'Cancelar Protocolo':'Cancelar Protocolo',
    'Protocolo é obrigatório':'Protocolo é obrigatório',
    'Pesquisar':'Pesquisar',
    'Cancelar Protocolos':'Cancelar Protocolos',
    'Ativar Protocolos':'Ativar Protocolos',
    'Data Entrega':'Data Entrega',
    'Solicitante':'Solicitante',
    'Visualizado?':'Visualizado?',
    'Ativo?':'Ativo?',
    'Nenhum exame vinculado ao protocolo!':'Nenhum exame vinculado ao protocolo!',
    'Nenhum protocolo a ser processado!':'Nenhum protocolo a ser processado!',
    'Tem certeza que deseja cancelar todos os protocolos e exames selecionados?':'Tem certeza que deseja cancelar todos os protocolos e exames selecionados?',
    'Tem certeza que deseja ativar todos os protocolos e exames selecionados?':'Tem certeza que deseja ativar todos os protocolos e exames selecionados?',
    'Tem certeza que deseja apagar todos os protocolos e exames selecionados?':'Tem certeza que deseja apagar todos os protocolos e exames selecionados?',
    'Esta ação não poderá ser desfeita!':'Esta ação não poderá ser desfeita!',
    'Processando Protocolos/Exames selecionados.':'Processando Protocolos/Exames selecionados.',
    'Protocolos/Exames processados com sucesso!':'Protocolos/Exames processados com sucesso!',
    'Protocolo já está inserido na lista':'Protocolo já está inserido na lista',
    'Protocolo não encontrado':'Protocolo não encontrado',
    'Procurando Protocolo':'Procurando Protocolo',
    'Deletando Protocolos/Exames selecionados.':'Deletando Protocolos/Exames selecionados.',
    'Error ao apagar dados selecionados!':'Error ao apagar dados selecionados!',
    'Última Atualização':'Última Atualização',

    //Reports
    'Os relatórios do ClickVita 2 são gerados a partir do metabase. Para solicitar seu acesso entre em contato com o suporte da Pixeon.':'Os relatórios do ClickVita 2 são gerados a partir do metabase. Para solicitar seu acesso entre em contato com o suporte da Pixeon.',
    'Assim que você receber o seu acesso entre com as credenciais em ':'Assim que você receber o seu acesso entre com as credenciais em ',

    //Account Data
    'clickvitaApp.actual_password_not_informed':'Password não informado',
    'clickvitaApp.actual_password_incorrect':'Password atual incorreto',
    'clickvitaApp.new_email_is_being_used':'Novo email já está em uso',
    'Email é obrigatório':'Email é obrigatório',
    'Informe a senha atual':'Informe a senha atual',
    'Senha atual é obrigatória':'Senha atual é obrigatória',
    'Novo Email':'Novo Email',
    'Novo Email é obrigatório':'Novo Email é obrigatório',
    'Repita Novo Email':'Repita Novo Email',
    'Novo Email deve ser igual':'Novo Email deve ser igual',
    'Senha Atual':'Senha Atual',
    'Nova Senha':'Nova Senha',
    'Nova Senha é obrigatória':'Nova Senha é obrigatória',
    'Repita a Nova Senha':'Repita a Nova Senha',
    'Nova Senha deve ser igual':'Nova Senha deve ser igual',
    'Os dados foram salvos com sucesso.':'Os dados foram salvos com sucesso.',
    'Verifique sua caixa de email':'Verifique sua caixa de email',
    'para confirmar a alteração':'para confirmar a alteração',
    'Atenção':'Atenção',
    'Erro ao salvar dados.':'Erro ao salvar dados.',
    'USER_CREATE_ALERT': 'É importante sempre lembrar da sua responsabilidade quando da criação de novos usuários.\nDe acordo com a Lei Geral de Proteção de Dados, somente pessoas autorizadas e estritamente necessárias devem ter acesso aos dados pessoais e dados pessoais sensíveis armazenados na plataforma, para atendimento da finalidade pretendida entre as partes (Você e a Pixeon) e os pacientes.\nGaranta que o usuário faça parte da sua instituição e que pode acessar a plataforma.',
    'USER_CREATE_CONSENT': 'Li, estou de acordo e desejo prosseguir com a criação do usuário.',

    //Termos
    'Termos': 'Termos',
    'Termos de Serviço': 'Termos de Serviço',
    'Termos de serviço atualizados com sucesso!':'Termos de serviço atualizados com sucesso!',
    'Erro: Não foi possível salvar os novos termos de serviço.': 'Erro: Não foi possível salvar os novos termos de serviço.',
    'Mensagem de confirmação dos termos de serviço 1': 'Tem certeza que deseja atualizar os termos de serviço?',
    'Mensagem de confirmação dos termos de serviço 2': 'Todos os usuários deverão aceitar os termos no próximo login.',
    'Política de Privacidade': 'Política de Privacidade',
    'Política de privacidade atualizada com sucesso!': 'Política de privacidade atualizada com sucesso!',
    'Erro: Não foi possível salvar a nova política de privacidade.': 'Erro: Não foi possível salvar a nova política de privacidade.',
    'Mensagem de confirmação da política de privacidade 1': 'Tem certeza que deseja atualizar a política de privacidade?',
    'Mensagem de confirmação da política de privacidade 2': 'Todos os usuários deverão aceitar a nova política no próximo login.',

    //Permission Delete LGPD
    'Dados removidos com sucesso!':'Dados removidos com sucesso!',
    'Dados Copiados!':'Dados Copiados!',
    'Copiar AccessCodes':'Copiar AccessCodes',
    'Remover dados usuários':'Remover dados usuários',
    'Dados não encontrados':'Dados não encontrados',
    'Procurando Todos os vínculos do usuário':'Procurando Todos os vínculos do usuário',
    'Direito a Exclusão':'Direito a Exclusão',
    'Email de Cadastro é obrigatório':'Email de Cadastro é obrigatório',
    'Email Cadastro':'Email Cadastro',
    'Apagar todos os dados do usuário':'Apagar todos os dados do usuário',
    'Confirma a exclusão de todos os dados deste usuário?':'Confirma a exclusão de todos os dados deste usuário?',
    'Lembre de copiar todos os accessCode para enviar ao time do PACS afim de remover as imagens também.':'Lembre de copiar todos os accessCode para enviar ao time do PACS afim de remover as imagens também.',
    'Removendo todos os dados do usuário...':'Removendo todos os dados do usuário...'
}
