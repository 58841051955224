import { Institution } from './institution.model';
import { Physician } from './physician.model';
import { UserModel } from './userModel';
import { StatusInvite } from './statusInvite';

export class Invite {
    physician:Physician;
    status:StatusInvite;
    institution:Institution;
    systemUser:UserModel;
    language:string;

    constructor() {
        this.physician = new Physician();
        this.institution = new Institution();
        this.status = StatusInvite.NOT_SENT;
        this.systemUser= new UserModel();
        this.language='pt-br';
    }
}