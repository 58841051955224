import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { UploadFileService } from 'src/services/uploadfile.service';
import { TranslateService } from 'src/app/fe/common/locale';
import { ToastrManager } from 'ng6-toastr-notifications';


@Component({
  selector: 'uploadfile',
  templateUrl: './s3-upload-file.component.html',
  styleUrls: ['./s3-upload-file.component.css']
})
export class S3UploadFileComponent implements OnInit {

  selectedFile: File = null
  img: any;
  fileName: string;
  code: string;
  message: string;
  urlValue: string;


  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig, private uploadFileService: UploadFileService,
    private translate: TranslateService, private toastr: ToastrManager) {

    if (config.data.url) {
      this.img = config.data.url;
    }
    this.code = config.data.code;
  }


  ngOnInit() {
    if (this.img) {
      this.fileName = "Selecione um novo Arquivo";
    } else {
      this.fileName = "Selecione o Arquivo";
    }
  }

  upload() {
    if (this.selectedFile === null) {
      return;
    }
    const formData = new FormData()
    formData.append('image', this.selectedFile, this.selectedFile.name)
    this.uploadFileService.postFile(formData, this.code).subscribe(
      data => {
        this.toastr.successToastr(this.translate.instant("A imagem foi salva com sucesso"), this.translate.instant("Sucesso!"));
        this.emitURL(data);
      },
      error => {
        this.toastr.warningToastr(this.translate.instant('Erro ao tentar salvar a imagem'), this.translate.instant("Erro"));

      });

  }

  emitURL(data: any) {
    this.urlValue = data.url;
    this.ref.close(this.urlValue);
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.preview(this.selectedFile);
    console.log(event);
  }


  preview(file: any) {
    this.message = null;
    if (file.length === 0)
      return;
    this.fileName = file.name;
    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = this.translate.instant('Somente imagens são suportadas');
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.img = reader.result;
    }
  }
}
