import { Component, OnInit, ViewEncapsulation, Input, ViewChild, ElementRef } from '@angular/core';
import { Institution } from 'src/app/model/institution.model';
import { AddressService } from 'src/services/address.service';
import { Country } from 'src/app/model/country.model';
import { SelectItem, DialogService } from 'primeng/api';
import { State } from 'src/app/model/state.model';
import { City } from 'src/app/model/city.model';
import { InstitutionService, AdminBeService } from 'src/services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/model/Role';
import { TranslateService } from 'src/app/fe/common/locale';
import { CnpjValidator } from 'src/app/directive/cnpj-validator';
import { CustomLoginPage } from 'src/app/model/customLoginPage.model';
import { InstitutionSetting } from 'src/app/model/institutionSetting.model';
import { Setting } from 'src/app/model/setting.model';
import { SettingService } from 'src/services/setting.service';
import { ValidatorsService } from 'src/services/validators.service';
import { S3UploadFileComponent } from './s3-upload-file/s3-upload-file.component';
import { InstitutionUnit } from 'src/app/model/institutionUnit.model';
import { Address } from 'src/app/model/address.model';
import { TypeFormatsPipe } from 'src/app/fe/common/locale/typeFormats.pipe';


//New setting to create the default value for data CV
const DEFAULT_DESCRIPTION_NAME:string = 'DEFAULT';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.css'],
  providers: [DialogService],
  encapsulation:ViewEncapsulation.None
})
export class RegistrationFormComponent implements OnInit {

  @ViewChild('supportPhone',{static:false}) supportPhone: ElementRef;
  @ViewChild('whatsApp',{static:false}) whatsApp: ElementRef;

  editing: boolean;
  ctx = {editing: ''}

  institution: Institution;
  customLoginPage: CustomLoginPage;
  institutionSetting: InstitutionSetting;
  institutionSettingsItems: InstitutionSetting[];
  institutionUnits:InstitutionUnit[];
  unit:InstitutionUnit;
  settings: Setting[];
  countries: SelectItem[];
  selectedCountry: Country;
  states: SelectItem[];
  selectedState: State;
  cities: SelectItem[];
  selectedCity: City;
  id: any;
  isFieldReadOnly: boolean;
  
  rowIndex:number;
  isEditingRow: boolean;
  cloneInstitutionSetting: { [s: string]: InstitutionSetting; } = {};
  cloneInstitutionUnit: { [s: string]: InstitutionUnit; } = {};
  displayDialogRemove: boolean;
  remSetting: boolean;
  selectedSetting: number;
  selectedPeriodShare :number;
  dropDownSetting: SelectItem[]=[];

  colsSetting: any[];
  pg:number=0;

  invalidEmail:boolean = false;
  validSetting:boolean = false;

  typeDataBoolean: SelectItem[]=[
    {label: this._translate.instant('Sim'), value: 'true'},
    {label: this._translate.instant('Não'), value: 'false'}
  ];

  typeDataNumber: SelectItem[]=[
    {label: '1', value: '1'},
    {label: '2', value: '2'}
  ];

  typeDataExternalSystem: SelectItem[]=[
    {label: 'KORUS', value: 'KORUS'},
    {label: 'IGEN', value: 'IGEN'}
  ];

  typeDataPeriod: SelectItem[]=[
    {label: this._translate.instant('Expira em 1 dia'), value: '1'},
    {label: this._translate.instant('Expira em 3 dias'), value: '3'},
    {label: this._translate.instant('Expira em 7 dias'), value: '7'},
    {label: this._translate.instant('Expira em 10 dias'), value: '10'},
    {label: this._translate.instant('Expira em 15 dias'), value: '15'},
    {label: this._translate.instant('Expira em 20 dias'), value: '20'},
    {label: this._translate.instant('Expira em 25 dias'), value: '25'},
    {label: this._translate.instant('Expira em 30 dias'), value: '30'}
  ];

  typeDataNumberForShowKeyImage: SelectItem[];

  constructor(private addressService: AddressService, private institutionService: InstitutionService, private toastr: ToastrManager,
    private route: ActivatedRoute, private adminBeService: AdminBeService, private _translate: TranslateService, private router: Router,
    private settingsService: SettingService, private validator:ValidatorsService, public dialogService: DialogService) { }

  ngOnInit() {
    this.loadCountry();
    this.institution     = new Institution();
    this.customLoginPage = new CustomLoginPage();
    this.institutionSetting = new InstitutionSetting();
    this.institutionSettingsItems = [];
    this.institutionUnits = [];
    this.unit = new InstitutionUnit();
    this.settings = [];
    this.selectedSetting = 0;
    this.selectedPeriodShare = 0;
    this.validSetting = false;

    this.states = new Array();
    this.cities = new Array();  
    
    this.route.params.subscribe(
    params => {
      this.id = params['id']; 
      if(params['pg']){
        this.pg=params['pg'];
      }
    });
    
    
    this._translate.onLangChanged.subscribe((lang) => {
      this.executeTranslations();
    });

    this.executeTranslations();

    if (this.id !== undefined && this.id !== null) {
      this.institutionService.getInstitutionData(this.id).subscribe(data=> {
        this.loadData(data);
      })        
    } 
    
    this.setFieldTokenReadOnlyForCompanyRole();
    
  }
  executeTranslations() {
    this.colsSetting = [
      { field: 'id', header: 'ID', style:'width:10%' },
      { field: 'name', header: this._translate.instant('Descrição') },
      { field: 'alias', header: this._translate.instant('Nome') },
      { field: 'active', header: this._translate.instant('Valor')},
      { header: '' }
    ];

    this.typeDataNumberForShowKeyImage = [
      {label: this._translate.instant('Desativado'), value: '0'},
      {label: this._translate.instant('Ativado'), value: '1'}
    ];
  }

  setFieldTokenReadOnlyForCompanyRole() {
    if (this.adminBeService.getUser().credencial.role === Role.company) {
      this.isFieldReadOnly = true;
    } else {
      this.isFieldReadOnly = false;
    }
  }
  
  checkSupportRoleUser(){
    let role:string = this.adminBeService.getUser().credencial.role;
    if ( role === Role.support || role === Role.manager) {
      return true;
    }

    return false;
  }

  checkCompanyRoleUser(){
    let role:string = this.adminBeService.getUser().credencial.role;
    if ( role === Role.company) {
      return true;
    }

    return false;
  }

  isRoleFinancial(){
    let role:string = this.adminBeService.getUser().credencial.role;
    if ( role === Role.financial) {
      return true;
    }

    return false;
  }

  loadData(data: Institution) {
    this.institution = data;
    this.loadCountry();
    this.loadSettings();
    this.loadUnits(this.institution.id);
    this.getStates(this.institution.address.city.state.country.id);
    this.getCities(this.institution.address.city.state.id);
    this.getCustomLoginPage(this.institution.id);
    this.getInstitutionSettings(this.institution.id);        
  }

  loadSettings(){
    this.settingsService.getAllSetting().subscribe(data => {
      if(data){
        this.settings = data;
        this.settings.sort(this.sortSettings)

        this.settings.map(item=>{
          if(!item.showOnConfigPage){
            if(this.checkCompanyRoleUser()){
              if(this.isDefaultPeriodName(item.name)){
                this.dropDownSetting.push({label: item.shortDescription, value: item.id});
              }
            }else{
              this.dropDownSetting.push({label: item.shortDescription, value: item.id});
            }
          }
        });
      }
    });
  }

  sortSettings(sta:Setting,stb:Setting){
    if(sta.shortDescription > stb.shortDescription) return 1;
    return -1;
  }

  loadUnits(id:any){
    this.institutionService.getUnits(id).subscribe(data => {
      if(data){

        data.forEach(item=>{
          if(item.address.id==0){
              item.address = new Address();
          }
        });
        
        this.institutionUnits = data;
      }
    });
  }

  loadCountry() {
    this.countries = new Array();   
    this.getCountries();
  }

  getCountries() {
    this.addressService.getAllCountries().subscribe(data => {
      for (let i=0; i < data.length; i++) {
        this.countries.push({label: data[i].name, value: data[i]});
      }
      if (this.institution.address.city.state.country.id) {
        this.selectedCountry = this.institution.address.city.state.country;
      }
    });
  }

  getCustomLoginPage(id:number){
    this.institutionService.getCustomLoginPageData(id).subscribe(data => {
      if(data){
        this.customLoginPage = data;
      }
    });
  }

  getInstitutionSettings(id:number){
    this.institutionService.getSettings(id).subscribe(data => {
      if(data){
        this.institutionSettingsItems = data;        
        this.institutionSettingsItems = this.institutionSettingsItems.filter(item=>item.setting.showOnConfigPage!=true);        
        this.institutionSettingsItems.sort(this.sortSettingsInstitution);

        if(this.checkCompanyRoleUser()){ //Case company can see the configs
          this.institutionSettingsItems = this.institutionSettingsItems.filter(item=>this.isDefaultPeriodName(item.setting.name));        
        }
      }
    });
  }

  isDefaultPeriodName(name:string){

    if(name.toUpperCase().indexOf(DEFAULT_DESCRIPTION_NAME)>0){
      return true;
    }

    return false;
  }

  sortSettingsInstitution(insta:InstitutionSetting,instb:InstitutionSetting){
    if(insta.id>instb.id) return -1;
    if(insta.id<instb.id) return 1;

    return 0;
  }

  emailValidator(event) {
    
    this.invalidEmail = false;
    if(event.target.value.trim().length>0){
      if (!this.validator.isEmailValid(event.target.value)) {
        this.invalidEmail = true;
      }
    }
  }

  backListInstitution(){
    let page = this.pg;
    if(page==0) {page=1;} //In case there is page 0 by parameter
    this.router.navigate(['home', {pg:page}]);
  }

  onSelectCountry(event: any) {
    this.getStates(event.value.id);   
  }

  getStates(countryId: any) {
    this.states = new Array();
    this.addressService.getAllCountryState(countryId).subscribe(data => {
      for (let i=0; i < data.length; i++) {
        this.states.push({label: data[i].name, value: data[i]});
      }
      if (this.institution.address.city.state.id) {
        this.selectedState = this.institution.address.city.state;
      }
    });
  }

  onSelectState(event: any) {
      this.getCities(event.value.id);
  }

  getCities(stateId: any) {
    this.cities = new Array();
    this.addressService.getAllStateCity(stateId).subscribe(data => {
      for (let i=0; i < data.length; i++) {
        this.cities.push({label: data[i].name, value: data[i]});
      }
      if (this.institution.address.city.id) {
        this.selectedCity = this.institution.address.city
      }
    });
  }

  onSubmit() {
    let result = CnpjValidator.validarCNPJ(this.institution.legalIdentifier);
    if (result === true) {
      if (this.validateAddressCity() === false) {
        return;
      }
      this.institution.address.city = this.selectedCity;
      this.institution.address.city.state = this.selectedState;
      this.institutionService.postInstitution(this.institution).subscribe(
        data => {
          this.institution.id = data.id;
          this.toastr.successToastr(this._translate.instant("A instituição foi gravada"), this._translate.instant("Sucesso!"));

          this.loadSettings();
          this.loadUnits(this.institution.id);
        },
        error => {
          this.toastr.warningToastr(this._translate.instant("Erro: A instituição não foi gravada"), this._translate.instant("Erro"));
        });
      
    } else {
      this.toastr.warningToastr(this._translate.instant('Erro: CNPJ inválido'), this._translate.instant("Erro"));
    }
  }

  onSubmitCustomLoginPage(){
   
    if(this.institution.id>0){

      this.customLoginPage.supportPhoneNumber = this.supportPhone.nativeElement.value;
      this.customLoginPage.supportWhatsAppNumber = this.whatsApp.nativeElement.value;

      this.customLoginPage.institution = this.institution;

      this.institutionService.postCustomLoginPage(this.customLoginPage).subscribe(
        data => {
          this.customLoginPage.id = data.id;
          this.toastr.successToastr(this._translate.instant("A página personalizada foi gravada"), this._translate.instant("Sucesso!"));
        },
        error => {
          this.toastr.warningToastr(this._translate.instant("Erro: A página personalizada não foi gravada"), this._translate.instant("Erro"));
        });
    }
  }

  //Functions of InstitutionSetting List
  checkIfContainsSetting(id){
    //Check if that settings contains in the list
    this.selectedSetting=id;
    let filterSetting = this.institutionSettingsItems.find(x => x.setting.id == id);
    
    if(filterSetting && filterSetting.setting!=undefined){
      let isSpecificConfig = (filterSetting.setting.name!='imageServiceResolver' &&  filterSetting.setting.name!='examsStatusVisualizer');
      if(isSpecificConfig){ //imageServiceResolver could be more than one to institution
        this.toastr.warningToastr(this._translate.instant("Erro: Essa configuração já está vinculada na instituição"), this._translate.instant("Erro"));
        this.validSetting = false;
        this.selectedSetting=0;
        return false;
      }
    }

    this.validSetting = true;
  }

  onRowEditInit(instService: InstitutionSetting,ri) {
    this.rowIndex=ri;
    this.cloneInstitutionSetting[instService.id] = {...instService};    
    this.isEditingRow = true;
  }

  onRowEditSave(institutionSetting: InstitutionSetting) {
    institutionSetting.institution = this.institution;
    this.saveSettingInstitution(institutionSetting);
  }
  
  onRowEditCancel(instService: InstitutionSetting, index: number) {
      let id = instService.id;
      
      this.isEditingRow=false;
      this.institutionSettingsItems[index] = this.cloneInstitutionSetting[instService.id];
      delete this.cloneInstitutionSetting[instService.id];    
      
      if(id==0){ //new setting
        this.getInstitutionSettings(this.institution.id);
      }
  }

  showDialogToRemove(setting:InstitutionSetting) {
    this.institutionSetting = setting;
    this.displayDialogRemove = true;
  }

  cancelDialog(){
    this.displayDialogRemove=false;
  }

  removeSettingInstitution(settingId:number){
    this.institutionService.removeSetting(settingId).subscribe(data=>{
         if(data){
           this.toastr.successToastr(this._translate.instant('Configuração removida da instituição!'),this._translate.instant('Remover Configuração'));
           this.displayDialogRemove = false;
           this.getInstitutionSettings(this.institution.id);
         }
     });
  }

  AddSetting(){

    if(this.selectedSetting>0){
      
        let setting:Setting = this.settings.find(st => st.id==this.selectedSetting);
        let instSetting:InstitutionSetting = new InstitutionSetting();
        instSetting.id=0;
        instSetting.institution=this.institution;
        instSetting.active=true;
        instSetting.value="";
        instSetting.setting = setting; 

        return instSetting;
    }
  }

  saveSettingInstitution(instSetting:InstitutionSetting){
    
    if(instSetting.setting.name=='imageServiceResolver' || instSetting.setting.name=='examsStatusVisualizer'){//Check if contains on list more elements with same UnitID institution
      let imageServiceR = this.institutionSettingsItems.filter(x => (x.setting.id == instSetting.setting.id && x.institutionUnitId==instSetting.institutionUnitId && x.id!=instSetting.id));
      
      if(imageServiceR.length>0){
        this.toastr.warningToastr(this._translate.instant('Erro: Unit Id já cadastrado para essa setting'), this._translate.instant("Erro"));
        this.onRowEditCancel(instSetting,this.rowIndex);
        this.onRowEditInit(instSetting,this.rowIndex);
        return false;
      }
    }
    
    if(instSetting.value.trim().length<=0){
      this.toastr.warningToastr(this._translate.instant('Erro: Necessário informar o valor da configuração'), this._translate.instant("Erro"));
      this.onRowEditCancel(instSetting,this.rowIndex);
      this.onRowEditInit(instSetting,this.rowIndex);
    }else{
      this.institutionService.postSettings(instSetting).subscribe(
        data => {
          this.institutionSetting = new InstitutionSetting();
          this.selectedSetting = 0;
          this.toastr.successToastr(this._translate.instant("A configuração foi salva"), this._translate.instant("Sucesso!"));
          this.getInstitutionSettings(this.institution.id);            
        },
        error => {
          this.toastr.warningToastr(this._translate.instant("Erro: Configuração não foi salva, verifique os campos"), this._translate.instant("Erro"));
        }); 
    }
  }

  //Make sure when user trying to put blank space then keep empty field
  checkFieldSpace(event){
    if(event.target.value.trim().length<=0){
      event.target.value = '';
      event.target.classList.remove('ng-valid');
      event.target.classList.add('ng-invalid');
      event.target.classList.add('is-invalid');
    }else{
      event.target.classList.remove('is-invalid');
    }
  }

  validateAddressCity() {
    if (this.selectedCity && this.selectedState && this.selectedCountry) {
      return true;
    } else {
      this.toastr.warningToastr(this._translate.instant('Erro: País, Estado e Cidade são obrigatórios'), this._translate.instant("Erro"));
      return false;
    }
  }

  markAsTouched(a : any) {
    a.control.touched = true;
  }

  show() {

    if (this.customLoginPage.code === "") {
      this.toastr.warningToastr("O Código é obrigatório para realizar o upload", this._translate.instant("Erro"));
      return;
    }

    const ref = this.dialogService.open(S3UploadFileComponent, {
      data: {
        url: this.customLoginPage.logoPath,
        code: this.customLoginPage.code
      },
        header: this._translate.instant('Selecione uma logo'),
        width: '30%',
      });
    
    ref.onClose.subscribe(result => {
      this.loadPublicUrl(result);
    });
  }

  loadPublicUrl(url: string) {
    if (url !== undefined)
      this.customLoginPage.logoPath = url;
  }

  //Units
  onEditUnit(intUnit: InstitutionUnit,ri) {
    this.rowIndex=ri;
    this.cloneInstitutionUnit[intUnit.id] = {...intUnit};    
    this.isEditingRow = true;
  }

  onEditUnitSave(institutionUnit: InstitutionUnit) {
    institutionUnit.institution = this.institution;
    this.saveUnitInstitution(institutionUnit);
  }
  
  onEditUnitCancel(instUnit: InstitutionUnit, index: number) {
      let id = instUnit.id;
      
      this.isEditingRow=false;
      this.institutionUnits[index] = this.cloneInstitutionUnit[instUnit.id];
      delete this.cloneInstitutionUnit[instUnit.id];          
  }

  saveUnitInstitution(institutionUnit:InstitutionUnit){
    this.institutionService.postUnit(institutionUnit).subscribe(
      data => {
        this.toastr.successToastr(this._translate.instant("Os dados da unidade foram salvos!"), this._translate.instant("Sucesso!"));
        this.loadUnits(this.institution.id);
      },
      error => {
        this.toastr.warningToastr(this._translate.instant("Erro: Unidade não foi salva, verifique os campos"), this._translate.instant("Erro"));
      }); 
  }

  checkTypeDataSettings(setting: Setting, typeData:string){

    if(setting!==undefined){
      if(setting.typeData.toString()===typeData){
        return true;
      }
    }

    return false;
  }

  isExternalSystem(setting: Setting){

    if(setting!==undefined){
      if(setting.name==='EXTERNAL_SYSTEM_NAME'){
        return true;
      }
    }

    return false;
  }
}
