import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Frequency } from 'src/app/model/frequency.model';

@Injectable({
  providedIn: 'root'
})
export class FrequencyService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }
  
  getAllFrequency():Observable<any> {
    return this.http.get<Frequency[]>(this.urlAdminBe + "/frequency")
    .pipe(map(data => data));
  } 
}