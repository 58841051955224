import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule }   from '@angular/forms';

import { PanelMenuModule } from 'primeng/panelmenu';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ng6-toastr-notifications';


import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService }  from './fe/common/locale';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './fe/auth/login/login.component';
import { HeaderComponent } from './fe/common/header/header.component';
import { FooterComponent } from './fe/common/footer/footer.component';
import { AdminBeService, AuthenticationService, InstitutionService, AuthInterceptor } from 'src/services/index'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './fe/common/menu/menu.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InstitutionListComponent } from './fe/institution/institution-list/institution-list.component';
import { TableModule } from 'primeng/table';
import { HasRoleDirective } from './directive/has-role.directive';
import { RegistrationFormComponent } from './fe/institution/form/registration-form/registration-form.component';
import { OnlynumberDirective } from './directive/only-number-directive';
import { GroupListComponent } from './fe/group/group-list/group-list.component';
import { DialogModule } from 'primeng/dialog';
import { MagicMaskDirective } from './directive/mask-fields-directive';
import { UsersGroupListComponent } from './fe/group/users-group-list/users-group-list.component';
import { RegistrationUserFormComponent } from './fe/user/form/registration-user-form/registration-user-form.component';
import { UserService } from 'src/services/user.service';
import { PanelModule } from 'primeng/panel';
import { S3UploadFileComponent } from './fe/institution/form/registration-form/s3-upload-file/s3-upload-file.component';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';
import { UploadFileService } from 'src/services/uploadfile.service';
import { AddRowDirective } from './directive/addRowDirective';
import { BnNgIdleService } from 'bn-ng-idle';
import { LoginHomeComponent } from './fe/auth/login-home/login-home.component';
import { SessionCheckerComponent } from './fe/common/session-checker/session-checker.component';

import { InviteComponent } from './fe/physician/invite/invite.component';
import { InviteService } from 'src/services/invite.service';
import { UserListComponent } from './fe/user/user-list/user-list.component';
import { TypeFormatsPipe } from './fe/common/locale/typeFormats.pipe';


import { InviteListComponent } from './fe/physician/invite-list/invite-list.component';
import { DatePipe } from '@angular/common';
import { ExamTypesComponent } from './fe/institution/exam-types/exam-types.component';
import { ExamTypesService } from 'src/services/exam-types.service';

import { NgxSpinnerModule } from "ngx-spinner";
import { NotifyEmailComponent } from './fe/notifyemail/notifyemail.component';
import { MonitoringComponent } from './fe/monitoring/monitoring.component';
import { JsonFormatPipe } from './fe/common/locale/jsonFormatPipe';

import { HighlightModule } from 'ngx-highlightjs';

import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import { ProtocolsComponent } from './fe/protocols/protocols.component';
import { ReportsComponent } from './fe/reports/reports.component';
import { AccountComponent } from './fe/account/account.component';
import { TosComponent } from './terms/tos/tos.component';
import { PrivacyPolicyComponent } from './terms/privacy-policy/privacy-policy.component';
import { EditorModule } from 'primeng/editor';
import { LgpdDeleteComponent } from './fe/lgpd/delete-data/lgpd-delete.component';
import { LgpdService } from 'src/services/lgpd.service';
import { LgpdPortabilityComponent } from './fe/lgpd/portability-data/lgpd-portability.component';
import { ExcelExportService } from 'src/services/excelexport.service';

export function hljsLanguages() {
  return [
    {name: 'json', func: json},    
    {name: 'xml', func: xml}
  ];
}

@NgModule({
  declarations: [
    AppComponent,
    TranslatePipe,
    TypeFormatsPipe,
    LoginComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    HomeComponent,
    InstitutionListComponent,
    HasRoleDirective,
    OnlynumberDirective,
    MagicMaskDirective,
    RegistrationFormComponent,
    GroupListComponent,
    UsersGroupListComponent,
    RegistrationUserFormComponent,
    S3UploadFileComponent,
    AddRowDirective,
    LoginHomeComponent,
    SessionCheckerComponent,
    InviteComponent,
    UserListComponent,
    InviteListComponent,
    ExamTypesComponent,
    NotifyEmailComponent,
    MonitoringComponent,
    JsonFormatPipe,
    ProtocolsComponent,
    ReportsComponent,
    AccountComponent,
    TosComponent,
    PrivacyPolicyComponent,
    LgpdDeleteComponent,
    LgpdPortabilityComponent
  ],
  entryComponents: [S3UploadFileComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    PanelMenuModule,
    BrowserAnimationsModule,
    DropdownModule,
    FontAwesomeModule,
    NgbModule,
    FormsModule,
    TableModule,
    DialogModule,
    DynamicDialogModule,
    AutoCompleteModule,
    PaginatorModule,
    PanelModule,
    InputSwitchModule,
    CalendarModule,
    NgxSpinnerModule,
    EditorModule,
    MessagesModule,
    MessageModule,
    ToastrModule.forRoot(),
    HighlightModule.forRoot({ languages: hljsLanguages })
  ],
  providers: [
    TRANSLATION_PROVIDERS, 
    TranslateService,
    AdminBeService,
    InstitutionService,
    UploadFileService,
    UserService,
    DynamicDialogRef,
    DynamicDialogConfig,
    AuthenticationService,
    InviteService,
    LgpdService,
    ExamTypesService,
    ExcelExportService,
    DatePipe,
    BnNgIdleService,
    {
      provide: HTTP_INTERCEPTORS,
      
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
