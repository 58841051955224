import { SelectItem } from 'primeng/api';

export class EnumEx {
    private static getNamesAndValues<T extends string>(e: any) {
        return this.getNames(e).map(n => ({ name: n, value: e[n] as T }));
    }

    private static getNames(e: any) {
        return Object.keys(e).filter(k => 
            typeof e[k] === "number"
            || e[k] === k
            || k.toString()
        );
    }

    static getEnumToSelectItem(type:any):SelectItem[] {
        let prodTypes:SelectItem[]=[];
    
        //Get name-value pairs
        let prodTypeEnumList = this.getNamesAndValues(type);
    
        //Convert name-value pairs to SelectItem[]
        prodTypes.push({label:"TODOS",value:""});
        prodTypeEnumList.forEach(pair => {
            prodTypes.push({label:pair.name,value:pair.value});
        });
    
        return prodTypes;
      }
}