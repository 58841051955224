import { Directive, ViewContainerRef, TemplateRef, Input, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/services';
import { Subject } from 'rxjs';
import { AutorizationUser } from 'src/model/autorization.model';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  @Input() appHasRole: string;
  stop$ = new Subject();
  isVisible = false;

  /**
   * @param {ViewContainerRef} viewContainerRef 
   * 	-- the location where we need to render the templateRef
   * @param {TemplateRef<any>} templateRef 
   *   -- the templateRef to be potentially rendered
   * @param {RolesService} rolesService 
   *   -- will give us access to the roles a user has
   */
  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {

    let user:AutorizationUser = this.authenticationService.getUser();

    if (!user.credencial) {
      this.viewContainerRef.clear();
    }else{
      if(this.appHasRole.includes(user.credencial.role)){
        if (!this.isVisible) {
          this.isVisible = true;
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      } else {
        this.isVisible = false;
        this.viewContainerRef.clear();
      }    
    }
  }
  
  ngOnDestroy() {
    this.stop$.next();
  }
}