// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   urlBaseFeV2:"https://adminv2-clickvita-hml.clickvita.com.br/",
//   urlBasePixeon:"http://pixeon-dev.clickvita.com.br/api",  
//   urlBaseBe: "https://pixeon-hml.clickvita.com.br/api",
//   urlServiceAuth: "/auth",

//   production: false
// };
export const environment = {
  urlBaseFeV2:"https://admin-v2.clickvita.com.br/",
  urlBasePixeon:"http://pixeon.clickvita.com.br/api",
  urlBaseBe: "/api",
  urlServiceAuth: "/auth",
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.