import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutorizationUser } from 'src/model/autorization.model';
import { Router } from '@angular/router'

import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router:Router){}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const autorizationUser:AutorizationUser = JSON.parse(localStorage.getItem("authorizationUser"));
        
        if(autorizationUser){
            const token = autorizationUser.token;

            if (token) {
                const cloned = req.clone({headers: req.headers.set('Authorization', token)});

                return next.handle(cloned).pipe(tap(
                    (event: HttpEvent<any>) => {
                      if (event instanceof HttpResponse) {
                          return event;
                      }
                      else if (event instanceof HttpErrorResponse){
                        console.log("event");
                      }
                  },
                  (err) => {
                    if (err instanceof HttpErrorResponse) { //Token has expired
                        if (err.status === 403) {             
                         return this.router.navigate(['login']);
                        }
                    }
                  }
                ));
            } 
        }
        
        return next.handle(req);
    }
}