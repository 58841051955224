import { Address } from './address.model';
import { Institution } from './institution.model';

export class InstitutionUnit {
    id:number;
    name:string;
    institutionUnitKey:string;
    tradingName:string;
    legalIdentifier:string;
    address:Address;
    institution:Institution;
    version:number;

    constructor() {
        this.name="";
        this.tradingName="";
        this.institutionUnitKey="";
        this.address = new Address();
        this.institution=new Institution();
    }
}