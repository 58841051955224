import { TranslateService } from 'src/app/fe/common/locale';

export class TypeUserItem {
  
   EXTERNAL: any
   COMPANY: any;
   FINANCIAL: any;
   SUPPORT: any;
   MANAGER: any;
   PHYSICIAN: any;
   CUSTOMER: any;
   CONTRACT: any;

   constructor(private _translate: TranslateService) {
      this.EXTERNAL = {
        label: this._translate.instant('Usuário Externo'), 
        value: 'EXTERNAL'
      };

      this.COMPANY = {
        label: this._translate.instant('Clínica'), 
        value: 'COMPANY'
      };

      this.FINANCIAL = {
        label: this._translate.instant('Financeiro'), 
        value: 'FINANCIAL'
      };

      this.SUPPORT = {
        label: this._translate.instant('Suporte'), 
        value: 'SUPPORT'
      };

      this.MANAGER = {
        label: this._translate.instant('Administrador'), 
        value: 'MANAGER'
      }

      this.PHYSICIAN = {
        label: this._translate.instant('Médico'), 
        value: 'PHYSICIAN'
      }

      this.CUSTOMER = {
        label: this._translate.instant('Paciente'), 
        value: 'CUSTOMER'
      }

      this.CONTRACT = {
        label: this._translate.instant('Protocolo'), 
        value: 'CONTRACT'
      }

   }

  
  
  
  
  
  
  
  
  

}