import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExamType } from 'src/app/model/examType.model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamTypesService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  getAllExamType():Observable<ExamType[]> {
    return this.http.get<ExamType[]>(this.urlAdminBe + '/examtype')
    .pipe(map(data => data));
  }
}
