import { Component, OnInit } from '@angular/core';
import { UserModel } from 'src/app/model/userModel';
import { ToastrManager } from 'ng6-toastr-notifications';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminBeService, InstitutionService } from 'src/services';
import { TranslateService } from 'src/app/fe/common/locale';
import { UserService } from 'src/services/user.service';
import { SelectItem } from 'primeng/api';
import { Role } from 'src/app/model/Role';
import { TypeUserItem } from 'src/app/model/type.user.item';
import { Institution } from 'src/app/model/institution.model';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-registration-user-form',
  templateUrl: './registration-user-form.component.html',
  styleUrls: ['./registration-user-form.component.css'],
})
export class RegistrationUserFormComponent implements OnInit {

  changeNewPass:boolean = false;
  systemUser:UserModel = new UserModel();
  selectedUserType:string;
  institutionId:number;
  institutions: Array<any>;
  institutionSelected: any;
  showFieldNameInstitution: boolean;
  showFieldNameExternalCompany: boolean;

  profilesUserType: SelectItem[];
  pageFromList: any;
  fieldFilterList: any;
  valueFilterList:any;

  credencial: any;
  isUpdate: boolean;
  checked: boolean;
  checkedStringValue: string;
  submitAllowed: boolean;
  showBackButton: boolean;

  createUserConsentValue: boolean;

  constructor(private toastr: ToastrManager, private route: ActivatedRoute, 
      private adminBeService: AdminBeService, private _translate: TranslateService, 
      private userService:UserService, private router: Router, private institutionService: InstitutionService) {
      this.institutions = [];
      this.submitAllowed = true;
      
  }

  ngOnInit() {
    this.credencial= this.adminBeService.getUser().credencial;
    this.institutionId=this.credencial.inst;
   
    this.configureFieldsInstitutionNameAndExternalCompanyName();

    this.route.params.subscribe(
      params => {
          this.loadSystemUser(params['adminInstitutionId'], params['id'], params['name'], params['userType'], params['username'], params['isEnable']); 
        if(params['pg']){
          this.pageFromList=params['pg'];
          this.valueFilterList=params['value'];
          this.fieldFilterList=params['fieldSearch'];
        }
    });


    this._translate.onLangChanged.subscribe(() => {
      this.loadProfilesUserType();
    });

    this.loadProfilesUserType();
  }

  loadSystemUser(adminId: number, id: number, name: string, userType: string, userName: string, isEnable: string) {
    if (id === undefined) {
      return;
    }
    this.systemUser = new UserModel();
    this.systemUser.adminInstitutionId = adminId;
    this.systemUser.id = id;
    this.systemUser.name = name;
    this.systemUser.userType = userType;
    this.systemUser.username = userName;
    this.systemUser.password = "********************";
    this.isUpdate = true;
    this.systemUser.isEnable = (isEnable === 'true' ? true : false);
    
    if (this.systemUser.isEnable === true) {
      this.checkedStringValue = this._translate.instant("Ativo");
    } else {
      this.checkedStringValue = this._translate.instant("Desativado");
    }
    this.onSelectUserType({value: userType})

    let typeUser = new TypeUserItem(this._translate);
    if (this.systemUser.userType === typeUser.EXTERNAL.value || this.systemUser.userType === typeUser.COMPANY.value) {
      this.institutionService.getInstitutionData(this.systemUser.adminInstitutionId).subscribe(data => {
        this.prepareSingleDataForAutoComplete(data);
        
      });
    }

    if (this.credencial.role === typeUser.SUPPORT.value || this.credencial.role === typeUser.MANAGER.value) {
      this.showBackButton = true;
      this.submitAllowed = true;
    }else{
      this.submitAllowed = false;
    }
  }

  changePassword(value:boolean){
    this.changeNewPass = value;
    this.systemUser.password = "";
  }

  isPasswordActive() {
    return (this.systemUser.password === "********************");
  }

  configureFieldsInstitutionNameAndExternalCompanyName () {
    if (this.credencial.role === Role.company) {
      this.showFieldNameExternalCompany = true;
      this.showFieldNameInstitution = false;
    } else if (this.credencial.role === Role.manager || this.credencial.role === Role.support) {
      this.showFieldNameExternalCompany = false; 
      this.showFieldNameInstitution = false;
    } else {
      this.showFieldNameExternalCompany = false; 
      this.showFieldNameInstitution = true;
    }
  }

  search(event: any) {
    this.institutionService.getInstitutionsByName(event.query).subscribe(data => {
        this.prepareDataForAutoComplete(data);

    });
  }

  prepareDataForAutoComplete (data: Array<Institution>) {
    this.institutions = [];
    for(let i=0; i < data.length; i++) {
      this.institutions.push({name: data[i].name, code: data[i].id})
    }
  }

  prepareSingleDataForAutoComplete (data: Institution) {
      this.institutions.push({name: data.name, code: data.id})
      this.institutionSelected = {name: data.name, code: data.id};
  }


  loadProfilesUserType() {
    let user = this.adminBeService.getUser();
    if (user && user.credencial) {
        if (user.credencial.role === Role.manager) {
            this.profilesUserType = this.fillProfileUserTypeManager();
        }
        if (user.credencial.role === Role.company) {
            this.profilesUserType = this.fillProfileUserTypeCompany(user.credencial);
        }
        if (user.credencial.role === Role.support) {
            this.profilesUserType = this.fillProfileUserTypeSupport();
        }
    }
  }

  fillProfileUserTypeCompany(credencia:any) {
    let typeUser = new TypeUserItem(this._translate);
    let item = [];
    item.push(typeUser.EXTERNAL);
    return item;
  }

  fillProfileUserTypeManager() {
    let typeUser = new TypeUserItem(this._translate);
    let item = [];
    item.push(typeUser.COMPANY, typeUser.EXTERNAL, typeUser.FINANCIAL, typeUser.SUPPORT, typeUser.MANAGER);
    if (this.isUpdate) {
      item.push(typeUser.PHYSICIAN, typeUser.CUSTOMER);
    }
    return item;
  }

  fillProfileUserTypeSupport() {
    let typeUser = new TypeUserItem(this._translate);
    let item = [];
    item.push(typeUser.EXTERNAL, typeUser.COMPANY, typeUser.FINANCIAL);
    if (this.isUpdate) {
      item.push(typeUser.PHYSICIAN, typeUser.CUSTOMER);
    }
    return item;
  }

  onSelectUserType(event: any) {
    this.selectedUserType = event.value;
  
    if (event.value === 'EXTERNAL') {

      this.showFieldNameExternalCompany = true;
      if (this.credencial.role === Role.company) {
        this.showFieldNameInstitution = false;  
      } else {
        this.showFieldNameInstitution = true;
      }

    } else if (event.value === 'COMPANY') { 
      this.showFieldNameInstitution = true;
      this.showFieldNameExternalCompany = false;
    } else {
      this.showFieldNameExternalCompany = false;
      this.showFieldNameInstitution = false;
    }
    
  }

  getTranslation(item: string): string {
    return this._translate.instant(item);
  }

  onSubmit(form: NgForm){
    if (this.checkIfNameIntitutionIsRequired()) {
      return false;
    }

    let usr:UserModel = this.systemUser;
    usr.adminInstitutionId=this.institutionId;

    if (this.credencial.role !== Role.company && (this.selectedUserType === 'EXTERNAL' || this.selectedUserType === 'COMPANY')) {
      usr.adminInstitutionId = this.institutionSelected.code;
      if (this.selectedUserType === 'COMPANY') {
        usr.name = this.institutionSelected.name;
      }
    }

    if(!this.selectedUserType){
      this.toastr.warningToastr(this._translate.instant("Tipo do usuário necessário!"), this._translate.instant("Erro"));
      return false;
    }

    usr.userType = this.selectedUserType;

    if (this.isUpdate === true) {
      
      this.userService.updateUser(usr).subscribe(data => {
        this.toastr.successToastr(this._translate.instant("Usuário alterado com sucesso!"), this._translate.instant("Sucesso!"));
        form.resetForm()
        this.goToUserList();
      },
      error => {
        if (error.status==424) {
          this.showMessageEmailAlreadySaved();
        } else {
          this.showWarningMessageThatUserCanNotBeSaved();
        }
      });

    } else {
      this.userService.saveUser(usr).subscribe(data => {
        this.toastr.successToastr(this._translate.instant("Usuário criado com sucesso!"), this._translate.instant("Sucesso!"));
        form.resetForm()
        this.goToUserList();
      }, error => {
        if (error.status==424) {
          this.showMessageEmailAlreadySaved();
        } else {
          this.showWarningMessageThatUserCanNotBeSaved();
        }
      });
    }
  }

  showMessageEmailAlreadySaved() {
    this.toastr.warningToastr(this.getTranslation("Erro: Email do usuário já está cadastrado."), this.getTranslation("Error"));
  }

  showWarningMessageThatUserCanNotBeSaved() {
    this.toastr.warningToastr(this._translate.instant("Erro: Usuário não pode ser salvo, verifique os campos!"), this._translate.instant("Erro"));
  }

  checkIfNameIntitutionIsRequired() {
    if (this.showFieldNameInstitution === true && (this.institutionSelected === undefined || this.institutionSelected === '')) {
      this.toastr.warningToastr(this._translate.instant("Informar a Instituição é obrigatório!"), this._translate.instant("Erro"));
      return true;
    }
    return false;
  }

  deactiveUser(event) {
    this.userService.saveStatusUser(this.systemUser.id, event.checked).subscribe(data => {
      this.toastr.successToastr(this._translate.instant("Situação do usuário alterado!"), this._translate.instant("Sucesso!"));
      if (event.checked === true) {
        this.checkedStringValue = this._translate.instant("Ativo");
        if (this.systemUser.userType === 'EXTERNAL') {
          this.submitAllowed = true; 
        }
      } else {
        this.checkedStringValue = this._translate.instant("Desativado");
      }
    },
    error => {
      this.toastr.warningToastr(this._translate.instant("A alteração da situação do usuário falhou!"), this._translate.instant("Erro!"));
      
    });
  }

  isSubmitAllowed() {
    return this.submitAllowed; 
  }

  backListInstitution(){
    this.router.navigate(['searchUsers', {pg:this.pageFromList, field: this.fieldFilterList, value: this.valueFilterList}]);
  }

  goToUserList(){
    if (this.credencial.role == Role.support || this.credencial.role == Role.manager) {
      setTimeout(()=>{
        this.router.navigate(['searchUsers', {pg:this.pageFromList, field: this.fieldFilterList, value: this.valueFilterList}]);
      },1000);    
    } 
  }
}
