import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { Institution } from 'src/app/model/institution.model';
import { AdminBeService, InstitutionService } from 'src/services';
import { AddressService } from 'src/services/address.service';
import { ExcelExportService } from 'src/services/excelexport.service';
import { UserService } from 'src/services/user.service';
import { __spreadArrays } from 'tslib';
import { TranslateService } from '../../common/locale';

@Component({
  selector: 'app-portability-data',
  templateUrl: './lgpd-portability.component.html',
  styleUrls: ['./lgpd-portability.component.css']
})
export class LgpdPortabilityComponent implements OnInit {

  msgSpinnerProcess:string = '';
  emailUser:string;
  responseData:any=null;
  disabledInstitutionField:boolean;

  institutions: Array<any>;
  institutionSelected: any;

  dataInstitution: any[];
  dataInstitutionGroup: any[]; 

  cols: any[];

  fileType: String;
  exportType: SelectItem[]=[];
  
  constructor(private _translate: TranslateService, private route: ActivatedRoute, private toastr: ToastrManager, 
    private institutionService: InstitutionService, private spinner: NgxSpinnerService, private excelExport: ExcelExportService) { }

  ngOnInit() {
    this.cols = [
      { field: 'protocol', header: 'Protocol' },
      { field: 'patientName', header: 'Nome Paciente' },
      { field: 'examDescription', header: 'Descrição Exame' },
      { field: 'examType', header: 'Tipo Exame' },
      { field: 'physicianName', header: 'Nome Médico' }
    ];

    this.exportType.push({label:"EXCEL",value:"EXCEL"},{label:"JSON",value:"JSON"});
    this.dataInstitution=[];
  }

  onSelectFileType(event: any) {
    this.fileType =event.value;    
  }

  search(event) {
    this.institutionService.getInstitutionsByName(event.query).subscribe(data => {
        this.prepareDataForAutoComplete(data);
    });
  }

  onSelect(event){
    let id=event.code;
    this.institutionService.getInstitutionData(id).subscribe(inst=>{
      this.institutionSelected = inst;      

      this.getDataInstitutionPortability(this.institutionSelected.id);
    })
  }

  prepareDataForAutoComplete (data: Array<Institution>) {
    this.institutions = [];
    for(let i=0; i < data.length; i++) {
      this.institutions.push({name: data[i].name, code: data[i].id})
    }
  }

  getDataInstitutionPortability(id:number){

    this.msgSpinnerProcess = 'Procurando todos os dados da instituição';
    this.spinner.show();

    this.institutionService.getLgpdPortabilityDataUser(id).subscribe(data => {      

      this.dataInstitution=data;

      this.spinner.hide();
    },error =>{

      if(error.status==404){
        this.toastr.warningToastr(this._translate.instant("Dados não encontrados"), this._translate.instant("Erro"));
      }else{
        this.toastr.errorToastr(error.message, this._translate.instant("Erro"));
      }

      this.spinner.hide();

    });

  }

  getMsgSpinner(){
    return this.msgSpinnerProcess;
  }

  exportFilePortability(){    

    if(this.fileType=='JSON'){
      this.dyanmicDownloadByHtmlTag({
        fileName: 'ExportJSONDataClickvita.json',
        text: JSON.stringify(this.dataInstitution)
      });
    }else{
      //Excel type
      this.excelExport.exportAsExcelFile(this.dataInstitution, 'PortabilityDataInstitution');
    } 
  }

  dyanmicDownloadByHtmlTag(arg: {
      fileName: string,
      text: string
    }) {
   
    const element = document.createElement('a');
    const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
    element.setAttribute('download', arg.fileName);

    var event = new MouseEvent("click");
    element.dispatchEvent(event);
  }
}
