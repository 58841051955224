import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Group } from 'src/app/model/group.model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GroupUser } from 'src/app/model/groupUser.model';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  
  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  getAllGroups(id: any):Observable<any> {
    return this.http.get<Group[]>(this.urlAdminBe + "/group/institution/" + id)
    .pipe(map(data => data));
  }

  getAllUsersByGroup(id: any):Observable<any> {
    return this.http.get<GroupUser[]>(this.urlAdminBe + "/group/"+id+"/users")
    .pipe(map(data => data));
  }

  getGroupData(id: any):Observable<Group> {
    return this.http.get<Group>(this.urlAdminBe + "/group/" + id)
    .pipe(map(data => data));
  }

  isGroupRelatedInExams(id: any, instId:any):Observable<boolean> {
    return this.http.get<boolean>(this.urlAdminBe + "/group/isRelatedInExams?id=" + id + "&instId=" + instId)
    .pipe(map(data => data));
  }

  saveGroup(group: Group):Observable<any> {
    return this.http.post<Group>(this.urlAdminBe + "/group", group)
    .pipe(map(data => data));
  }

  saveUserGroup(groupUser: GroupUser):Observable<any> {
    return this.http.post<GroupUser>(this.urlAdminBe + "/group/user", groupUser)
    .pipe(map(data => data));
  }

  removeUserGroup(groupUserId:number){
    return this.http.delete<boolean>(this.urlAdminBe + "/group/user/" + groupUserId)
    .pipe(map(data => data));
  }
}
