import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Setting } from 'src/app/model/setting.model';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }
  
  getAllSetting():Observable<any> {
    return this.http.get<Setting[]>(this.urlAdminBe + "/settings")
    .pipe(map(data => data));
  } 
}