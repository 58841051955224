import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SelectItem } from 'primeng/api';
import { EditorModule } from 'primeng/editor';
import { TranslateService } from 'src/app/fe/common/locale';
import { Term } from 'src/app/model/term.model';
import { TermSearch } from 'src/app/model/termSearch.model';
import { TypeUserItem } from 'src/app/model/type.user.item';
import { TermsService } from 'src/services/terms.service';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css']
})
export class TosComponent implements OnInit {
  
  termType = "TOS";
  userType: string;

  term: Term = new Term();
  termSearch: TermSearch;

  displayDialogCancel: boolean;
  profilesUserType: SelectItem[];
  selectedUserType: string;

  constructor(private termsService: TermsService, private _translate: TranslateService, private toastr: ToastrManager) {
  }

  ngOnInit() {
    this._translate.onLangChanged.subscribe(() => {
      this.loadProfilesUserType();
    });

    this.loadProfilesUserType();
  }

  loadProfilesUserType() {
    let typeUser = new TypeUserItem(this._translate);
    this.profilesUserType = [];
    this.profilesUserType.push(typeUser.EXTERNAL, typeUser.PHYSICIAN, typeUser.CUSTOMER, typeUser.CONTRACT);
  }

  onSelectUserType(event: any) {
    this.userType = event.value;
    this.termSearch = new TermSearch(this.termType, this.userType)

    this.getLatestTermsOfService();
  }

  showDialogCancel(){
    this.displayDialogCancel = true;
  }

  cancelDialog(){
    this.displayDialogCancel = false;
  }

  getLatestTermsOfService() {
    this.termsService.getLatestTerm(this.termSearch).subscribe(data => {
      if (data) {
        this.term = data;
      } else {
        this.term = new Term();
      }
    });
  }

  saveTermsOfService() {
    if (!this.term.id) {
      this.term.termType = this.termType;
      this.term.userType = this.userType;
    }

    this.termsService.saveTerm(this.term).subscribe(data => {
      this.toastr.successToastr(this._translate.instant("Termos de serviço atualizados com sucesso!"), this._translate.instant("Sucesso!"));
      this.cancelDialog();
    },
    error => { 
      this.toastr.warningToastr(this._translate.instant("Erro: Não foi possível salvar os novos termos de serviço."), this._translate.instant("Erro"));
      this.cancelDialog();
    });
  }

}
