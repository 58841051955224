import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Term } from 'src/app/model/term.model';
import { TermSearch } from 'src/app/model/termSearch.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http: HttpClient) { }

  getLatestTerm(termSearch: TermSearch): Observable<Term> {
    return this
      .http
      .post<Term>(this.urlAdminBe + "/terms/getTerm/", termSearch)
      .pipe(map(data => data));
  }

  saveTerm(term: Term): Observable<boolean> {
    return this
      .http
      .post<boolean>(this.urlAdminBe + "/terms/saveTerm/", term)
      .pipe(map(data => data));
  }


}
