import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AdminBeService } from 'src/services/admin-be.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authentication: AdminBeService, private router: Router) { }
  private isAuthenticated: boolean = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authorizarion = this.authentication.getUser();
    if (authorizarion !== undefined && authorizarion !== null) {
      if (authorizarion.credencial.exp < (Date.now() / 1000)) {
        this.isAuthenticated = false;
        this.authentication.logout();
        this.router.navigateByUrl("/login");
      } else {
        this.isAuthenticated = true;
      }
      if (route.data.roles && route.data.roles.indexOf(authorizarion.credencial.role) === -1) {
        // role not authorised so redirect to home page
        this.isAuthenticated = false;
        this.authentication.logout();
        this.router.navigateByUrl("/login");
        return false;
      }

    } else {
      this.router.navigateByUrl("/login");
    }
    return this.isAuthenticated;
  }
}
