import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  private urlAdminBe = environment.urlBaseBe;

  constructor(private http:HttpClient) { }

  postFile(formData: FormData, code: string):Observable<string> {
    return this.http.post<string>(this.urlAdminBe + "/upload?code="+code, formData)
    .pipe(map(data => data));
  }
  
}
